import dayjs from "dayjs";
import { Line, ComposedChart, XAxis, YAxis, Area } from "recharts";
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { Company } from "@/lib/api/companies/companies";
import { useMemo } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {useQuery} from "react-query";
import useApi from "@/hooks/use-api.tsx";
import {Sale} from "@/lib/api/sales/sales";

type CargurusHistoryChartProps = {
  companies: Company[];
  companyId: number | undefined;
  api: ReturnType<typeof useApi>
  saleVehicleId: number | undefined;
    sale: Sale | undefined;
};

export default function CargurusHistoryChart({
  companies,
  companyId,
    api,
    saleVehicleId,
    sale
}: CargurusHistoryChartProps) {
  const cargurusReference = ["Great", "Good", "Fair", "High", "Over"];

  const { data: cargurusHistory } = useQuery(
      {
        queryKey: ["cargurusHistory", saleVehicleId, companyId, sale],
        queryFn: async () => {
          const data = await api.cargurusRecords(companyId!).find(saleVehicleId!);
          data.sort((a, b) => {
            return new Date(a.Date).getTime() - new Date(b.Date).getTime();
          });
          return data.map((record) => ({...record, ActualCost: sale?.FinalACV}));
        },
        enabled: !!saleVehicleId && !!companyId && !!sale,
      }
  );

  const cargurusPreference = useMemo(() => {
    const currentCompany = companies.find(
      (company) => company.CompanyId === companyId
    );
    return cargurusReference[currentCompany?.DefaultCarGurusPreference ?? 2];
  }, [companies, companyId]);

  const chartConfig = {
    desktop: {
      label: "Desktop",
      color: "hsl(var(--primary-action))",
    },
    mobile: {
      label: "Mobile",
      color: "hsl(var(--primary-action))",
    },
  } satisfies ChartConfig;

  return (
    <ChartContainer config={chartConfig} className="w-5/6 h-[600px] my-auto">
      <ComposedChart data={cargurusHistory}>
        <XAxis
          dataKey="Date"
          tickFormatter={(date: string) => dayjs(date).format("MM/DD/YYYY")}
        />
        <YAxis
          domain={["dataMin - 1000", "dataMax + 1000"]}
          tickFormatter={(value) => "$" + value.toLocaleString()}
        />
        <ChartTooltip
          content={<ChartTooltipContent />}
          formatter={(value: number, name: string) => {
            return (
              <Card className="w-full mb-2">
                <CardHeader className="py-2">
                  <CardTitle>
                    <div className="flex items-center gap-2">
                      <div
                        className="w-2 h-2 rounded-full"
                        style={{
                          backgroundColor:
                            name === "Cost of acquisition"
                              ? "hsla(221.2, 83.2%, 53.3%, 30%)"
                              : name === "Your price"
                              ? "hsl(var(--primary-action))"
                              : "hsl(var(--primary))",
                        }}
                      ></div>
                      <p className="font-semibold opacity-80 text-sm">{name}</p>
                    </div>
                  </CardTitle>
                </CardHeader>
                <CardContent className="pt-0 pb-2">
                  <p className="font-bold text-base">
                    ${value.toLocaleString()}
                  </p>
                </CardContent>
              </Card>
            );
          }}
          labelFormatter={(label: string) => (
            <div className="font-bold text-lg">
              {dayjs(label).format("ddd, MMM D, YYYY")}
            </div>
          )}
        />
        <Area
            type='monotone'
          dataKey="ActualCost"
          name="Cost of acquisition"
          dot={false}
          strokeWidth={2}
          stroke="hsla(221.2, 83.2%, 53.3%, 30%)"
          fill="hsla(221.2, 83.2%, 53.3%, 10%)"
          color="hsla(221.2, 83.2%, 53.3%, 30%)"
          animationDuration={0}
        />
        <Line
            type='monotone'
          dataKey="VehiclePrice"
          name="Your price"
          dot={false}
          strokeWidth={2}
          stroke="hsl(var(--primary-action))"
          color="hsl(var(--primary-action))"
          animationDuration={0}
        />
        <Line
            type='monotone'
          dataKey={cargurusPreference ?? "Fair"}
          name={
            "Market price (at " + (cargurusPreference ?? "Fair") + " Price)"
          }
          dot={false}
          stroke="hsl(var(--primary))"
          color="hsl(var(--primary))"
          strokeWidth={2}
          animationDuration={0}
        />
        <ChartLegend className="text-lg" />
      </ComposedChart>
    </ChartContainer>
  );
}
