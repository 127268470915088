import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { CreateAutomationRuleDto } from "@/lib/api/automation-preferences/schemas/create-automation-rule.schema";
import { Plus } from "lucide-react";
import { COLUMN_LETTER_OPTIONS } from "../../lib/constants";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { cn, proper } from "@/lib/utils";
import { Label } from "@/components/ui/label";
import { useState } from "react";
import { getValidationRuleColors } from "../../lib/validation-rule-colors";
import { Input } from "@/components/ui/input";
import { AutomationRule } from "@/lib/api/automation-preferences/automation-preferences";

type AddRuleDialogProps = {
  addRule: ({
    preferenceId,
    data,
  }: {
    preferenceId: number;
    data: CreateAutomationRuleDto;
  }) => void;
  loading: boolean;
  preferenceId: number;
};

export default function AddRuleDialog({
  addRule,
  loading,
  preferenceId,
}: AddRuleDialogProps) {
  const [checkMethod, setCheckMethod] = useState<string | undefined>();
  const [checkColor, setCheckColor] = useState<
    | {
        name: string;
        color: {
          red?: number;
          green?: number;
          blue?: number;
        };
      }
    | undefined
  >();

  const validate = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    const form = ev.currentTarget as HTMLFormElement;
    let hasError = false;
    const errors: Record<string, string> = {};
    if (!form.FromColumn.value) {
      errors.FromColumn = "You must choose a column to source from";
      hasError = true;
    }
    form.CheckMethod = { value: checkMethod };
    if (!checkMethod) return;
    if (!checkMethod) {
      errors.CheckMethod = "You must choose a check method";
      hasError = true;
    } else {
      if (checkMethod === "ValueIsNotEmpty") {
        form.CheckValue.value = 1;
      }
      if (checkMethod === "ValueIsEmpty") {
        form.CheckValue.value = 1;
      }
      if (checkMethod === "BackgroundColor") {
        if (!checkColor) {
          errors.CheckValue = "You must choose a color";
          hasError = true;
        } else {
          if (checkColor.color.red === 0) {
            delete checkColor.color.red;
          }
          if (checkColor.color.green === 0) {
            delete checkColor.color.green;
          }
          if (checkColor.color.blue === 0) {
            delete checkColor.color.blue;
          }
          if (Object.keys(checkColor.color).length === 0) {
            errors.CheckValue = "You must choose a color";
            hasError = true;
          }
          if (checkColor.color.red) {
            form.BackgroundColorRed = { value: checkColor.color.red };
          }
          if (checkColor.color.green) {
            form.BackgroundColorGreen = { value: checkColor.color.green };
          }
          if (checkColor.color.blue) {
            form.BackgroundColorBlue = { value: checkColor.color.blue };
          }
          if (
            form.BackgroundColorRed?.value ||
            form.BackgroundColorGreen?.value ||
            form.BackgroundColorBlue?.value
          ) {
            form.CheckValue = { value: true };
          }
        }
      }
    }
    if (!form.CheckValue?.value) {
      errors.CheckValue = "You must choose a value to check against";
      hasError = true;
    }
    form[checkMethod] = { value: form.CheckValue.value };
    if (!hasError) {
      const newRule: AutomationRule = {} as AutomationRule;
      if (form.CheckMethod?.value === "BackgroundColor") {
        const BackgroundColorRed = form.BackgroundColorRed;
        if (BackgroundColorRed) {
          newRule.BackgroundColorRed = BackgroundColorRed.value / 255;
        }
        const BackgroundColorGreen = form.BackgroundColorGreen;
        if (BackgroundColorGreen) {
          newRule.BackgroundColorGreen = BackgroundColorGreen.value / 255;
        }
        const BackgroundColorBlue = form.BackgroundColorBlue;
        if (BackgroundColorBlue) {
          newRule.BackgroundColorBlue = BackgroundColorBlue.value / 255;
        }
      }
      const newAutomation: Omit<
        AutomationRule,
        "AutomationPreference" | "AutomationRuleId" | "AutomationPreferenceId"
      > = {
        FromColumn: form.FromColumn?.value || null,
        BackgroundColorRed: newRule.BackgroundColorRed || null,
        BackgroundColorGreen: newRule.BackgroundColorGreen || null,
        BackgroundColorBlue: newRule.BackgroundColorBlue || null,
        ValueIsNotEmpty: form.ValueIsNotEmpty?.value || null,
        ValueEqualTo: form.ValueEqualTo?.value || null,
        ValueNotEqualTo: form.ValueNotEqualTo?.value || null,
        ValueGreaterThan: form.ValueGreaterThan?.value || null,
        ValueLessThan: form.ValueLessThan?.value || null,
        ValueIsEmpty: form.ValueIsEmpty?.value || null,
      };
      addRule({ preferenceId, data: newAutomation });
    }
  };

  const colors = getValidationRuleColors();
  return (
    <Dialog>
      <DialogTrigger className="ml-auto w-full" asChild>
        <Button variant="outline" className="mt-4 w-full">
          <Plus className="inline-block -mt-1" /> Add New Mapping
        </Button>
      </DialogTrigger>
      <DialogContent className="w-screen max-w-5xl">
        <DialogHeader>
          <DialogTitle>Add New Mapping</DialogTitle>
        </DialogHeader>
        <form onSubmit={validate}>
          <div className="w-full flex gap-2 items-center mb-4">
            <div className={cn("w-full")}>
              <Label htmlFor="CheckType">Column</Label>
              <Select name="FromColumn">
                <SelectTrigger>
                  <SelectValue placeholder="Select an option" />
                </SelectTrigger>
                <SelectContent>
                  {COLUMN_LETTER_OPTIONS.map((option) => (
                    <SelectItem key={option} value={String(option)}>
                      {option}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className={cn("w-full")}>
              <Label htmlFor="CheckType">Check type</Label>
              <Select
                value={checkMethod}
                onValueChange={(e) => setCheckMethod(e)}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select an option" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="BackgroundColor">
                    Background color is
                  </SelectItem>
                  <SelectItem value="ValueIsNotEmpty">
                    Value is not empty
                  </SelectItem>
                  <SelectItem value="ValueIsEmpty">Value is empty</SelectItem>
                  <SelectItem value="ValueEqualTo">
                    Value is equal to
                  </SelectItem>
                  <SelectItem value="ValueNotEqualTo">
                    Value is not equal to
                  </SelectItem>
                  <SelectItem value="ValueGreaterThan">
                    Value is greater than
                  </SelectItem>
                  <SelectItem value="ValueLessThan">
                    Value is less than
                  </SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className={cn("w-full")}>
              <Label htmlFor="CheckType">Check type</Label>
              {checkMethod &&
                (checkMethod === "BackgroundColor" ? (
                  <div className="flex flex-col items-center gap-2">
                    <Label>
                      {checkColor ? proper(checkColor.name) : "Select a color"}
                    </Label>
                    <div className="grid grid-cols-10 gap-2">
                      {colors.map((color) => (
                        <div
                          key={color.name}
                          className={cn(
                            "h-6 w-6 rounded-full",
                            checkColor?.name === color.name &&
                              "border-2 border-primary"
                          )}
                          style={{ backgroundColor: color.color }}
                          onClick={() => {
                            const colorValues = color.color
                              .replace(/[^\d,]/g, "")
                              .split(",")
                              .map((v) => parseInt(v));
                            setCheckColor({
                              name: color.name,
                              color: {
                                red: colorValues[0],
                                green: colorValues[1],
                                blue: colorValues[2],
                              },
                            });
                          }}
                        ></div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <Input
                    name="CheckValue"
                    type={
                      ["ValueIsNotEmpty", "ValueIsEmpty"].includes(checkMethod)
                        ? "hidden"
                        : "text"
                    }
                    placeholder={`Enter a value to check for ${checkMethod}`}
                  />
                ))}
            </div>
          </div>
          <Button type="submit" className="w-full" loading={loading}>
            Add Mapping
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
}
