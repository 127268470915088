import { z } from "zod";

export const UpdateUserSchema = z.object({
  FirstName: z.string().max(64),
  LastName: z.string().max(64),
  MiddleInitial: z.string().max(1),
  PhoneNumber: z.string().max(16),
  TwoFactorMethod: z.union([z.literal("email"), z.literal("phone")]),
  AllowEmailTwoFactor: z.union([z.literal(0), z.literal(1)]),
  AllowPhoneTwoFactor: z.union([z.literal(0), z.literal(1)]),
  ImageBuffer: z.string().max(512).optional(),
});
