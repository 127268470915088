import {ChartContainer, } from "@/components/ui/chart.tsx";
import {Bar, BarChart, CartesianGrid, XAxis, YAxis} from "recharts";
import {AnimatePresence, motion} from "framer-motion";
import {InventoryOverview} from "@/lib/api/analytics/inventory-analytics";

type InventoryByAgeChartProps = {
    data: InventoryOverview[] | undefined
    loading: boolean
}

export default function InventoryByAgeChart({ data, loading }: InventoryByAgeChartProps){
    return (
        <AnimatePresence mode={'wait'}>
            {loading ? (
                <motion.div
                    key="loading"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{ duration: 0.2, damping: 0, bounce: 0, stiffness: 100 }}
                    className="w-full h-96 bg-gray-200 rounded-md animate-pulse"
                />
            ) : (
                <motion.div
                    key="stable"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="w-1/2 h-96"
                >
                    <ChartContainer config={{}} className="w-full h-full">
                        <BarChart
                            layout='vertical'
                            data={data?.map((item) => {
                                const formattedObj: {
                                    name: string
                                    [key: string]: number | string
                                } = {
                                    name: item.companyName
                                }
                                item.inventoryByAgeData?.forEach((item) => {
                                    formattedObj[item.ageBucket] = item.bucketPercentOfTotal
                                })
                                return formattedObj
                            }) ?? []}
                        >
                            <CartesianGrid strokeDasharray="3 3" vertical={false} />
                            <XAxis type='number' domain={[0,100]} hide />
                            <YAxis type='category' dataKey='name' allowDuplicatedCategory={false} axisLine={false} />
                            {data?.[0]?.inventoryByAgeData?.map((entry, index) => {
                                const radius = [0,0,0,0] as [number, number, number, number]
                                if(index === 0){
                                    radius[0] = 4
                                    radius[3] = 4
                                }
                                if(index === data[0]!.inventoryByAgeData!.length - 1){
                                    radius[1] = 4
                                    radius[2] = 4
                                }
                                let color = ''
                                if(index === 0) color = 'var(--cargurus-1)'
                                if(index === 1) color = 'var(--cargurus-2)'
                                if(index === 2) color = 'var(--cargurus-3)'
                                if(index === 3) color = 'var(--cargurus-35)'
                                if(index === 4) color = 'var(--cargurus-4)'
                                if(index === 5) color = 'var(--cargurus-45)'
                                if(index === 6) color = 'var(--cargurus-5)'
                                return <Bar barSize={28} key={index} dataKey={entry.ageBucket} stackId='a' fill={color} radius={radius}/>
                            })}
                        </BarChart>
                    </ChartContainer>
                </motion.div>
            )}
        </AnimatePresence>
    )
}