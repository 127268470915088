import { AxiosInstance } from "axios";

import {
  CreateAutomationPreferenceSchema,
  CreateAutomationPreferenceDto,
} from "./schemas/create-automation-preference.schema";
import {
  CreateAutomationMappingSchema,
  CreateAutomationMappingDto,
} from "./schemas/create-automation-mapping.schema";
import {
  CreateAutomationRuleSchema,
  CreateAutomationRuleDto,
} from "./schemas/create-automation-rule.schema";
import { UpdateAutomationPreferenceDto } from "./schemas/update-automation-preference.schema";

export class AutomationPreferences {
  private readonly instance: AxiosInstance;
  private companyId: number;

  /**
   * Creates an instance of the AutomationPreferences class.
   * @param {AxiosInstance} instance - The Axios instance to use for HTTP requests.
   * @param {number} companyId - The ID of the company.
   */
  constructor(instance: AxiosInstance, companyId: number) {
    this.instance = instance;
    this.companyId = companyId;
  }

  /**
   * Get all automation preferences
   * @param {GetAllAutomationsOptions} options - The options to filter the automations.
   * @returns {Promise<any[]>} The list of automation preferences.
   */
  public async getAll(options?: GetAllAutomationsOptions) {
    const { data } = await this.instance.get(
      `/companies/${this.companyId}/automation-preferences`,
      {
        params: options,
      }
    );
    return data;
  }

  /**
   * Create a new automation preference
   * @param {CreateAutomationPreferenceDto} automation - The automation preference object to create.
   * @returns {Promise<any>} The created automation preference.
   */
  public async create(automation: CreateAutomationPreferenceDto) {
    const { data } = await this.instance.post(
      `/companies/${this.companyId}/automation-preferences`,
      automation
    );
    return data;
  }

  /**
   * Update an automation preference
   * @param {number} automationPreferenceId - The ID of the automation preference to update.
   * @param {UpdateAutomationPreferenceDto} automation - The updated automation preference object.
   * @returns {Promise<any>} The updated automation preference.
   */
  public async update(
    automationPreferenceId: number,
    automation: UpdateAutomationPreferenceDto
  ) {
    const { data } = await this.instance.patch(
      `/companies/${this.companyId}/automation-preferences/${automationPreferenceId}`,
      automation
    );
    return data;
  }

  /**
   * Delete an automation preference
   * @param {number} automationPreferenceId - The ID of the automation preference to delete.
   */
  public async delete(automationPreferenceId: number) {
    await this.instance.delete(
      `/companies/${this.companyId}/automation-preferences/${automationPreferenceId}`
    );
  }

  /**
   * Create a new mapping for an automation preference
   * @param {number} automationPreferenceId - The ID of the automation preference to add the mapping to.
   * @param {CreateAutomationMappingDto} mapping - The mapping object to create.
   * @returns {Promise<any>} The created mapping response.
   */
  public async createMapping(
    automationPreferenceId: number,
    mapping: CreateAutomationMappingDto
  ) {
    const { data } = await this.instance.post(
      `/companies/${this.companyId}/automation-preferences/${automationPreferenceId}/mappings`,
      mapping
    );
    return data;
  }

  /**
   * Create a new rule for an automation preference
   * @param {number} automationPreferenceId - The ID of the automation preference to add the rule to.
   * @param {CreateAutomationRuleDto} rule - The rule object to create.
   * @returns {Promise<any>} The created rule response.
   */
  public async createRule(
    automationPreferenceId: number,
    rule: CreateAutomationRuleDto
  ) {
    const { data } = await this.instance.post(
      `/companies/${this.companyId}/automation-preferences/${automationPreferenceId}/rules`,
      rule
    );
    return data;
  }

  /**
   * Delete a mapping for an automation preference
   * @param {number} automationPreferenceId - The ID of the automation preference to delete the mapping from.
   * @param {number} automationMappingId - The ID of the mapping to delete.
   */
  public async deleteMapping(
    automationPreferenceId: number,
    automationMappingId: number
  ) {
    await this.instance.delete(
      `/companies/${this.companyId}/automation-preferences/${automationPreferenceId}/mappings/${automationMappingId}`
    );
  }

  /**
   * Delete a rule for an automation preference
   * @param {number} automationPreferenceId - The ID of the automation preference to delete the rule from.
   * @param {number} automationRuleId - The ID of the rule to delete.
   */
  public async deleteRule(
    automationPreferenceId: number,
    automationRuleId: number
  ) {
    await this.instance.delete(
      `/companies/${this.companyId}/automation-preferences/${automationPreferenceId}/rules/${automationRuleId}`
    );
  }

  public getSchemas() {
    return {
      create: CreateAutomationPreferenceSchema,
      createMapping: CreateAutomationMappingSchema,
      createRule: CreateAutomationRuleSchema,
    };
  }
}

export type GetAllAutomationsOptions = {
  isActive?: boolean;
  canRun?: boolean;
};
