import { AxiosInstance } from "axios";

import { VehicleSource } from "./vehicle-sources";
import { CreateVehicleSourceDto } from "./dto/create-vehicle-source.dto";
import { UpdateVehicleSourceSchema } from "./schemas/update-vehicle-source.schema";
import { CreateVehicleSourceSchema } from "./schemas/create-vehicle-source.schema";
import { UpdateVehicleSourceDto } from "./dto/update-vehicle-source.dto";

export class VehicleSources {
  private readonly instance: AxiosInstance;
  private companyId: number;

  /**
   * Creates an instance of the Auth class.
   * @param {AxiosInstance} instance - The Axios instance to use for HTTP requests.
   */
  constructor(instance: AxiosInstance, companyId: number) {
    this.instance = instance;
    this.companyId = companyId;
  }

  /**
   * Create a new vehicle source
   * @param {CreateSourceDto} sourceObject - The source object to create.
   * @returns {Promise<VehicleSource>} The created source.
   */

  public async create(sourceObject: CreateVehicleSourceDto) {
    const { data } = await this.instance.post(
      `/companies/${this.companyId}/sources`,
      sourceObject
    );
    return data as VehicleSource;
  }

  /**
   * Get all vehicle sources for a company
   * @returns {Promise<VehicleSource[]>} A promise that returns an array of vehicle sources.
   */

  public async findAll(options?: GetAllSourcesOptions) {
    const { data } = await this.instance.get(
      `/companies/${this.companyId}/sources`,
      {
        params: options,
      }
    );
    return data as VehicleSource[];
  }

  /**
   * Get a vehicle source by id
   * @param {number} sourceId - The id of the source to get.
   * @returns {Promise<VehicleSource>} A promise that returns the source.
   */

  public async findOne(sourceId: number) {
    const { data } = await this.instance.get(
      `/companies/${this.companyId}/sources/${sourceId}`
    );
    return data as VehicleSource;
  }

  /**
   * Update a vehicle source
   * @param {number} sourceId - The id of the source to update.
   * @param {UpdateVehicleSourceDto} sourceObject - The source object to update.
   * @returns {Promise<VehicleSource>} The updated source.
   */

  public async update(sourceId: number, sourceObject: UpdateVehicleSourceDto) {
    const { data } = await this.instance.patch(
      `/companies/${this.companyId}/sources/${sourceId}`,
      sourceObject
    );
    return data as VehicleSource;
  }

  /**
   * Delete a vehicle source
   * @param {number} sourceId - The id of the source to delete.
   * @param {number} newSourceId - The id of the new source to assign to vehicles that used the deleted source.
   */

  public async delete(sourceId: number, newSourceId: number) {
    await this.instance.delete(
      `/companies/${this.companyId}/sources/${sourceId}`,
      {
        params: {
          newSourceId,
        },
      }
    );
  }

  public getSchemas() {
    return {
      create: CreateVehicleSourceSchema,
      update: UpdateVehicleSourceSchema,
    };
  }
}

export type GetAllSourcesOptions = {
  hideFromSuggestions?: boolean | undefined;
  isZeroGrossSource?: boolean | undefined;
};
