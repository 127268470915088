import { AxiosInstance } from "axios";
import { CreateInventoryDto } from "./dto/create-inventory.dto";
import { UpdateInventoryDto } from "./dto/update-inventory.dto";
import { type Inventory as InventoryItem } from "./inventory";

import { CreateInventorySchema } from "./schemas/create-inventory.schema";
import { UpdateInventorySchema } from "./schemas/update-inventory.schema";

export class Inventory {
  private readonly instance: AxiosInstance;
  private companyId: number;

  /**
   * Creates an instance of the Auth class.
   * @param {AxiosInstance} instance - The Axios instance to use for HTTP requests.
   */
  constructor(instance: AxiosInstance, companyId: number) {
    this.instance = instance;
    this.companyId = companyId;
  }

  /**
   * Create a new inventory
   * @param {CreateInventoryDto} inventoryObject - The inventory object to create.
   * @returns {Promise<Inventory>} The created inventory.
   */

  public async create(inventoryObject: CreateInventoryDto) {
    const { data } = await this.instance.post(
      `/companies/${this.companyId}/inventory`,
      inventoryObject
    );
    return data as InventoryItem;
  }

  /**
   * Get all inventory
   * @param {GetAllInventoriesOptions} options - The options to filter the inventory.
   * @returns {Promise<Inventory[]>} The list of inventory.
   */

  public async getAll(options?: GetAllInventoriesOptions) {
    const { data } = await this.instance.get(
      `/companies/${this.companyId}/inventory`,
      {
        params: options,
      }
    );
    return data as InventoryItem[];
  }

  /**
   * Get a inventory by ID
   * @param {number} inventoryId - The ID of the inventory to retrieve.
   * @returns {Promise<Inventory>} The inventory.
   */

  public async getById(inventoryId: number) {
    const { data } = await this.instance.get(
      `/companies/${this.companyId}/inventory/${inventoryId}`
    );
    return data as InventoryItem;
  }

  /**
   * Get a inventory by vin
   * @param {string} vin - The vin number of the inventory to retrieve.
   * @returns {Promise<Inventory>} The inventory.
   */

  public async getByVin(vin: string) {
    const { data } = await this.instance.get(
      `/companies/${this.companyId}/inventory/by-vin/${vin}`
    );
    return data as InventoryItem;
  }

  /**
   * Update a inventory
   * @param {number} inventoryId - The ID of the inventory to update.
   * @param {UpdateInventoryDto} inventoryObject - The inventory object to update.
   * @returns {Promise<Inventory>} The updated inventory.
   */

  public async update(
    inventoryId: number,
    inventoryObject: UpdateInventoryDto
  ) {
    const { data } = await this.instance.patch(
      `/companies/${this.companyId}/inventory/${inventoryId}`,
      inventoryObject
    );
    return data as InventoryItem;
  }

  /**
   * Delete a inventory
   * @param {number} inventoryId - The ID of the inventory to delete.
   */

  public async delete(inventoryId: number) {
    await this.instance.delete(
      `/companies/${this.companyId}/inventory/${inventoryId}`
    );
  }

  public getSchemas() {
    return {
      create: CreateInventorySchema,
      update: UpdateInventorySchema,
    };
  }
}

export type GetAllInventoriesOptions = {
  startDate?: string;
  endDate?: string;
  vehicleSourceId?: number;
  model?: string;
  make?: string;
  year?: number;
  includeSourceNames?: string[] | undefined;
};
