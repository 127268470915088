import * as React from "react";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarInset,
  SidebarProvider,
  SidebarRail,
} from "@/components/ui/sidebar";
import { User } from "@/lib/api/users/users";
import { Company } from "@/lib/api/companies/companies";
import { type RouteDefinition } from "@/config/routing/route-definitions.d";
import { NavMain } from "./nav-main";
import routeDefinitions from "@/config/routing/route-definitions";
import useUser from "@/hooks/use-user";
import { NavUser } from "./nav-user";
import { useLocation } from "react-router";
import useCompanies from "@/hooks/use-companies";
import { CompanySwitcher } from "./company-switcher";
import useLocalAppPreferences from "@/hooks/use-local-app-preferences.tsx";

export type AppSidebarData = {
  user: User | null;
  companies: Company[] | null;
  navMain: RouteDefinition[];
};

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  const user = useUser();
  const preferences = useLocalAppPreferences()
  const companies = useCompanies();
  const location = useLocation();
  const currentRoute = routeDefinitions.find(
    (route) => route.path === location.pathname
  );
  const showSidebar = currentRoute?.properties.layout.sidebar === "full";
  if (!showSidebar) {
    return <>{props.children}</>;
  }
  const routesInSidebar = routeDefinitions.filter(
    (route) => route.properties.showInSidebar
  );
  return (
    <SidebarProvider className="bg-sidebar-background">
      <Sidebar collapsible="icon" variant="inset" {...props}>
        <SidebarHeader>
          <CompanySwitcher companies={companies.data} />
        </SidebarHeader>
        <SidebarContent>
          <NavMain items={routesInSidebar} location={location} preferences={preferences} />
        </SidebarContent>
        <SidebarFooter>
          <NavUser user={user.data} />
        </SidebarFooter>
        <SidebarRail />
      </Sidebar>
      <SidebarInset>{props.children}</SidebarInset>
    </SidebarProvider>
  );
}
