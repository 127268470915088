import useApi from "@/hooks/use-api.tsx";
import useLocalAppPreferences from "@/hooks/use-local-app-preferences.tsx";
import {DateRangePresets} from "@/lib/date-range-presets.ts";
import {useQuery} from "react-query";
import {SalesOverview} from "@/lib/api/analytics/analytics";

export default function useSalesDashboardData() {
    const api = useApi()
    const preferences = useLocalAppPreferences()
    const dateRange = preferences.preferences.global.dateRange
    let startDate,endDate: string = ''
    if(typeof dateRange !== 'string') {
        startDate = dateRange.startDate
        endDate = dateRange.endDate
    } else {
        const dateRangePreset = DateRangePresets[dateRange as keyof typeof DateRangePresets]()
        startDate = dateRangePreset.startDate.format('MM/DD/YYYY')
        endDate = dateRangePreset.endDate.format('MM/DD/YYYY')
    }

    const includeAdjustments = preferences.preferences.dashboard.useAdjustments

    const companyIds = preferences.preferences.global.activeCompanyIds

    const getSalesDashboardData = async (id: number) => {
        const data = await api.analytics(id).getAll(
            `
            salesOverview {
              companyId
              companyName
              totalSales
              averageSalesPerDay
              salesPace
              totalGross
              averageGrossPerSale
              grossPace
              comparators {
                totalSales
                averageSalesPerDay
                salesPace
                totalGross
                averageGrossPerSale
                grossPace
              }
              salesPerDayData {
                date
                sales
                dayPercentOfTotal
                totalGross
                averageGrossPerSale
                rollbacksCount
                rollbacksValue
                adjustmentsCount
                adjustmentsValue
              }
              cargurusGroupingData {
                rating
                totalUnits
                ratingPercentOfTotal
                averageDaysToSell
                averageGrossPerSale
              }
              cargurusIndexData {
                greatPrice
                goodPrice
                fairPrice
                highPrice
                overPriced
                targetPriceValue
                targetPrice
              }
              salesByAgeData {
                ageBucket
                totalSales
                bucketPercentOfTotal
                averageGrossPerSale
              }
              salesBySourceData {
                sourceName
                totalSales
                sourcePercentOfTotal
                averageGrossPerSale
                averageDaysToSell
                volumeIndicator
                grossIndicator
                daysToSellIndicator
              }
            }
           `, {
                startDate: startDate,
                endDate: endDate,
                includeAdjustments
            }
        )
        return data.salesOverview
    }

    const { data, isLoading: loading } = useQuery({
        queryKey: ['sales-dashboard-data', dateRange, includeAdjustments, companyIds],
        queryFn: async () => {
            if (companyIds.length === 0) return [] as SalesOverview[]
            const data: Promise<SalesOverview | null | undefined>[] = []
            for(const id of companyIds){
                data.push(getSalesDashboardData(id))
            }
            const items: (SalesOverview | null | undefined)[] = await Promise.all(data)
            return items.filter(item => item !== null && item !== undefined) as (SalesOverview)[]
        },
        enabled: !!companyIds.length || companyIds.length > 0
    })

    return {
        data,
        loading
    }
}