import { z, ZodSchema } from "zod";

const stringOrNumber = (schema: ZodSchema) =>
  z.union([
    schema,
    z
      .string()
      .transform((val, ctx) => {
        const num = Number(val);
        if (isNaN(num)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Invalid number",
          });
          return z.NEVER;
        }
        return num as number;
      })
      .nullable()
      .optional(),
  ]);

export const UpdateSaleSchema = z
  .object({
    IsDraft: z.literal(1).or(z.literal(0)),
    IsRollback: z.literal(1).or(z.literal(0)),
    Notes: z.string().max(128).nullable().optional(),
    InitialACV: stringOrNumber(z.number().int().positive()).optional(),
    DaysInInventory: stringOrNumber(z.number().int().nonnegative()).optional(),
    FinalACV: stringOrNumber(z.number().int().positive()).optional(),
    FinalManheimWholesale: stringOrNumber(
      z.number().int().positive()
    ).optional(),
    IsCertified: z.literal(1).or(z.literal(0)),
    Gross: stringOrNumber(z.number().int()).optional(),
    PercentOfMarket: stringOrNumber(
      z.number().int().min(0).max(200)
    ).optional(),
    Odometer: stringOrNumber(z.number().int().nonnegative()).optional(),
    MSRP: stringOrNumber(z.number().int().positive()).nullable().optional(),
    SellPrice: stringOrNumber(z.number().int().positive()).optional(),
    VehicleSourceId: z.number().int().min(0),
    InitialAskingPrice: stringOrNumber(z.number().int().positive()).optional(),
    StockNumber: z.string().min(1).max(16).optional(),
    Make: z.string().min(1).max(32).optional(),
    Model: z.string().min(1).max(32).optional(),
    ModelYear: stringOrNumber(
      z.number().int().min(1960).max(new Date().getFullYear())
    ).optional(),
    Vin: z.string().length(17).optional(),
    VehicleTrimLevel: z.string().min(0).max(32).nullable(),
    InitialManheimWholesale: stringOrNumber(
      z.number().int().positive()
    ).optional(),
    SoldAt: z.string().datetime(),
    TookATrade: z.literal(1).or(z.literal(0)),
    RollbackDate: z.string().datetime().nullable().optional(),
    CostToMarket: stringOrNumber(z.number().int().nonnegative()).optional(),
    AppraisedValue: stringOrNumber(z.number().int().nonnegative())
      .optional()
      .nullable(),
  })
  .refine(
    (data) =>
      (data.IsRollback === 1 && data.RollbackDate) ||
      (data.IsRollback === 0 && !data.RollbackDate),
    {
      message: "RollbackDate is required when the sale is rolled back",
      path: ["RollbackDate"],
    }
  );
