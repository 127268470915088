import { z } from "zod";

export const UpdateAutomationPreferenceSchema = z.object({
  ExistingCompareValue: z.enum(["Vin", "StockNumber"]),
  CompanyId: z.number().nonnegative().int(),
  TripLogGoogleSheetId: z.string().nullable(),
  EmailAddressesOnFailed: z.string(),
  Name: z.string().nullable(),
  IsActive: z.union([z.literal(0), z.literal(1)]),
  AllowOverrideRequiredFields: z.union([z.literal(0), z.literal(1)]),
});

export type UpdateAutomationPreferenceDto = z.infer<
  typeof UpdateAutomationPreferenceSchema
>;
