import {
  DialogContent, DialogDescription, DialogFooter,
  DialogTitle,
} from "@/components/ui/dialog";
import useApi from "@/hooks/use-api";
import {useMutation, useQuery, useQueryClient} from "react-query";
import useCompanies from "@/hooks/use-companies";
import CargurusHistoryChart from "./cargurus-history-chart";
import EditSaleForm from "./edit-sale-form";
import dayjs from "dayjs";
import { Separator } from "@/components/ui/separator";
import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarMenu, SidebarMenuButton, SidebarMenuItem,
  SidebarProvider
} from "@/components/ui/sidebar.tsx";
import {useState} from "react";
import {CarIcon, TrendingUp} from "lucide-react";
import {Button} from "@/components/ui/button.tsx";
import {
  AlertDialog, AlertDialogAction, AlertDialogCancel,
  AlertDialogContent, AlertDialogDescription, AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from "@/components/ui/alert-dialog.tsx";
import {toast} from "sonner";

type ActiveSaleModalProps = {
  saleVehicleId: number | undefined;
  companyId: number | undefined;
  onClose: () => void;
};

export default function ActiveSaleModal({
  saleVehicleId,
  companyId,
}: ActiveSaleModalProps) {
  const companies = useCompanies();
  const api = useApi();
  const [activeTab, setActiveTab] = useState("details");
  const queryClient = useQueryClient();

  const { data: sale } = useQuery({
    queryKey: ["sale", saleVehicleId, companyId],
    queryFn: async () => {
      return await api.sales(companyId!).getById(saleVehicleId!);
    },
    enabled: !!saleVehicleId && !!companyId,
    refetchOnWindowFocus: false,
  });

  const { mutateAsync: deleteSale } = useMutation({
    mutationFn: async () => {
      return await api.sales(companyId!).delete(saleVehicleId!);
    },
    onSuccess: () => {
      queryClient.invalidateQueries("sales");
      queryClient.invalidateQueries("sale");
      toast.success("Sale deleted successfully");
    },
  })

  return (
    <>
      <DialogContent className="overflow-hidden p-0 max-h-[780px] w-full max-w-7xl">
        <DialogTitle className='sr-only'>
          {sale?.FullVehicleName ?? "We can't find this sale. Try again later"}
        </DialogTitle>
        <DialogDescription className='sr-only'>
          {sale?.Vin}
        </DialogDescription>
        <SidebarProvider className=' items-start'>
          <Sidebar collapsible='none' className='bg-sidebar-background'>
            <SidebarContent>
              <SidebarGroup>
                <SidebarGroupContent>
                  <SidebarMenu>
                    <SidebarMenuItem>
                      <SidebarMenuButton onClick={() => setActiveTab('details')} isActive={activeTab === 'details'} className='flex items-center gap-2'>
                        <CarIcon /> Details
                      </SidebarMenuButton>
                    </SidebarMenuItem>
                    <SidebarMenuItem>
                      <SidebarMenuButton onClick={() => setActiveTab('market-history')} isActive={activeTab === 'market-history'} className='flex items-center gap-2'>
                        <TrendingUp /> Market History
                      </SidebarMenuButton>
                    </SidebarMenuItem>
                  </SidebarMenu>
                </SidebarGroupContent>
              </SidebarGroup>
            </SidebarContent>
          </Sidebar>
          <main className="flex h-[780px] flex-1 flex-col overflow-y-auto p-4">
            <header
                className="flex h-16 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12">
              <div className="flex items-center gap-2 p-2">
                <p className="text-sm text-gray-500">
                  Entered inventory {dayjs(sale?.CreatedAt).format("MMM D, YYYY")}
                </p>
                <Separator orientation="vertical"/>
                <p className="text-sm text-gray-500">
                  Sold {dayjs(sale?.SoldAt).format("MMM D, YYYY")}
                </p>
              </div>
            </header>
            {activeTab === 'details' && (<>
                <EditSaleForm sale={sale}/>
                  <DialogFooter>
                    <AlertDialog>
                      <AlertDialogTrigger asChild>
                        <Button variant='destructive'>
                            Delete sale
                        </Button>
                      </AlertDialogTrigger>
                      <AlertDialogContent>
                        <AlertDialogHeader>
                          <AlertDialogTitle>
                            Are you sure you want to delete this sale?
                          </AlertDialogTitle>
                          <AlertDialogDescription>
                            This action cannot be undone. The data for this sale will still remain in the system, but will no longer be accessible. Continue?
                          </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                          <AlertDialogCancel>
                            Cancel
                          </AlertDialogCancel>
                          <AlertDialogAction asChild onClick={() => deleteSale()}>
                            <Button variant='destructive'>
                              Delete sale
                            </Button>
                          </AlertDialogAction>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialog>
                  </DialogFooter>
            </>
            )}
            {activeTab === 'market-history' && (
                <CargurusHistoryChart
                    companies={companies.data ?? []}
                    companyId={companyId}
                    api={api}
                    saleVehicleId={saleVehicleId}
                    sale={sale}
                />
            )}
          </main>
        </SidebarProvider>
      </DialogContent>
    </>
  );
}
