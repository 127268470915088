import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "@/components/ui/table.tsx";
import {cn} from "@/lib/utils.ts";
import {AnimatePresence, motion} from "framer-motion";
import {InventoryOverview} from "@/lib/api/analytics/inventory-analytics";

type CargurusAtAGlanceTableProps = {
    data: InventoryOverview[] | undefined
    loading: boolean
    storeCount: number
}

export default function CargurusAtAGlanceTable({ data, loading, storeCount }: CargurusAtAGlanceTableProps){
    const colors = ['bg-cargurus-1', 'bg-cargurus-2', 'bg-cargurus-3', 'bg-cargurus-4', 'bg-cargurus-5', 'bg-gray-500']
    return (
        <AnimatePresence mode={'wait'}>
            {loading ? (
                <motion.div
                    key="loading"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{ duration: 0.2, damping: 0, bounce: 0, stiffness: 100 }}
                    className="w-full bg-gray-200 rounded-md animate-pulse"
                    style={{
                        height: `${8 + 14 * storeCount}rem`
                    }}
                />
            ) : (
                <motion.div
                    key="stable"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{duration: 0.2}}
                    className="w-full"
                >
                    <Table className="w-full whitespace-nowrap">
                        <TableHeader className={'border-b'}>
                            <TableHead>Rating</TableHead>
                            <TableHead>Store</TableHead>
                            <TableHead>Total units</TableHead>
                            <TableHead>Avg age</TableHead>
                            <TableHead>Avg PVR</TableHead>
                        </TableHeader>
                        <TableBody>
                            {data?.[0]?.cargurusGroupingData?.map((item, parentIndex) => {
                                return (
                                    <TableRow key={parentIndex}>
                                        <TableCell>
                                            <div
                                                className={cn(
                                                    "w-2 h-2 inline-block rounded-full mr-1 -translate-y-0.5",
                                                    colors[parentIndex]
                                                )}
                                            ></div>
                                            {item.rating}
                                        </TableCell>
                                        <TableCell>
                                            <Table>
                                                <TableBody>
                                                    {data?.map((storeData, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell><div className='w-full max-w-24 whitespace-nowrap overflow-hidden text-ellipsis'> {storeData.companyName}</div></TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableCell>
                                        <TableCell>
                                            <Table>
                                                <TableBody>
                                                    {data?.map((storeData, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell>
                                                                {storeData?.cargurusGroupingData?.[parentIndex]?.totalUnits} (
                                                                {storeData?.cargurusGroupingData?.[parentIndex]?.ratingPercentOfTotal}%)
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableCell>
                                        <TableCell>
                                            <Table>
                                                <TableBody>
                                                    {data?.map((storeData, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell>
                                                                {storeData?.cargurusGroupingData?.[parentIndex]?.averageDaysToSell} days
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableCell>
                                        <TableCell>
                                            <Table>
                                                <TableBody>
                                                    {data?.map((storeData, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell>
                                                                $
                                                                {storeData?.cargurusGroupingData?.[parentIndex]?.averageGrossPerSale.toLocaleString()}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </motion.div>
            )}
        </AnimatePresence>
    )
}