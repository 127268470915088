import { AxiosInstance } from "axios";
import { CreateAdjustmentDto } from "./dto/create-adjustment.dto";
import * as dayjs from "dayjs";
import { UpdateAdjustmentDto } from "./dto/update-adjustment.dto";
import { Adjustment } from "./adjustments";

import { CreateAdjustmentSchema } from "./schemas/create-adjustment.schema";
import { UpdateAdjustmentSchema } from "./schemas/update-adjustment.schema";

export class Adjustments {
  private readonly instance: AxiosInstance;
  private companyId: number;

  /**
   * Creates an instance of the Auth class.
   * @param {AxiosInstance} instance - The Axios instance to use for HTTP requests.
   */
  constructor(instance: AxiosInstance, companyId: number) {
    this.instance = instance;
    this.companyId = companyId;
  }

  /**
   * Create a new adjustment
   * @param {CreateAdjustmentDto} adjustmentObject - The adjustment object to create.
   * @returns {Promise<Adjustment>} The created adjustment.
   */

  public async create(adjustmentObject: CreateAdjustmentDto) {
    const { data } = await this.instance.post(
      `/companies/${this.companyId}/adjustments`,
      adjustmentObject
    );
    return data as Adjustment;
  }

  /**
   * Get all adjustments for a store
   * @param {GetAllAdjustmentsOptions} options - The options for getting adjustments
   * @returns {Promise<Adjustment[]>} The adjustments for the store.
   */

  public async getAll(options?: GetAllAdjustmentsOptions) {
    const startDateString = options?.startDate
      ? dayjs(options.startDate).format("YYYY-MM-DD HH:mm:ss")
      : undefined;
    const endDateString = options?.endDate
      ? dayjs(options.endDate).format("YYYY-MM-DD HH:mm:ss")
      : undefined;
    const { data } = await this.instance.get(
      `/companies/${this.companyId}/adjustments`,
      {
        params: {
          startDate: startDateString,
          endDate: endDateString,
        },
      }
    );
    return data as Adjustment[];
  }

  /**
   * Get an adjustment by ID
   * @param {number} adjustmentId - The ID of the adjustment to get.
   * @returns {Promise<Adjustment>} The adjustment.
   */

  public async getById(adjustmentId: number) {
    const { data } = await this.instance.get(
      `/companies/${this.companyId}/adjustments/${adjustmentId}`
    );
    return data as Adjustment;
  }

  /**
   * Update an adjustment by ID
   * @param {number} adjustmentId - The ID of the adjustment to update.
   * @param {UpdateAdjustmentDto} adjustmentObject - The adjustment object to update.
   * @returns {Promise<Adjustment>} The updated adjustment.
   */

  public async update(
    adjustmentId: number,
    adjustmentObject: UpdateAdjustmentDto
  ) {
    const { data } = await this.instance.patch(
      `/companies/${this.companyId}/adjustments/${adjustmentId}`,
      adjustmentObject
    );
    return data as Adjustment;
  }

  /**
   * Delete an adjustment by ID
   * @param {number} adjustmentId - The ID of the adjustment to delete.
   */

  public async delete(adjustmentId: number) {
    await this.instance.delete(
      `/companies/${this.companyId}/adjustments/${adjustmentId}`
    );
  }

  public getSchemas() {
    return {
      create: CreateAdjustmentSchema,
      update: UpdateAdjustmentSchema,
    };
  }
}

type GetAllAdjustmentsOptions = {
  startDate?: string | Date | dayjs.Dayjs;
  endDate?: string | Date | dayjs.Dayjs;
};
