import { TwoFactorOption } from "@/lib/api/auth/auth";
import { motion } from "framer-motion";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from "@/components/ui/input-otp";
import { useState } from "react";
import useApi from "@/hooks/use-api";
import { Button } from "@/components/ui/button";
import { Alert } from "@/components/ui/alert";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";

type LoginWithOtpProps = {
  selectedOption: TwoFactorOption;
  onSuccess: (token: string) => void;
  onCancel: () => void;
};

export default function LoginWithOtp({
  selectedOption,
  onSuccess,
  onCancel,
}: LoginWithOtpProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [otp, setOtp] = useState("");
  const api = useApi();

  const onLogin = async (otp: string) => {
    try {
      setLoading(true);
      setError("");
      if (selectedOption.name === "Email") {
        const token = await api
          .auth()
          .loginWithEmailOtp(selectedOption.authKey, otp);
        if (!token)
          throw new Error(
            "The code you entered is invalid or expired. \nPlease go back and try again."
          );
        onSuccess(token);
      }
      if (selectedOption.name === "Phone") {
        const token = await api
          .auth()
          .loginWithPhoneOtp(selectedOption.authKey, otp);
        if (!token)
          throw new Error(
            "The code you entered is invalid or expired. Please go back and try again."
          );
        onSuccess(token);
      }
    } catch (e: any) {
      setError(e.response?.data?.message ?? e.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <motion.form
      key="login-otp"
      initial={{
        opacity: 0,
        translateY: 100,
      }}
      animate={{
        opacity: 100,
        translateY: 0,
      }}
      exit={{
        opacity: 0,
        translateY: -100,
      }}
      onSubmit={(e) => {
        e.preventDefault();
        onLogin(otp);
      }}
    >
      <Card>
        <CardHeader className="text-center">
          <h1>Enter the code we sent you here</h1>
          {error && (
            <Alert variant="destructive" className="text-start">
              {error}
            </Alert>
          )}
        </CardHeader>
        <CardContent className="flex justify-center">
          <InputOTP
            maxLength={6}
            value={otp}
            onChange={(code) => setOtp(code.toUpperCase())}
          >
            <InputOTPGroup>
              <InputOTPSlot index={0} />
              <InputOTPSlot index={1} />
              <InputOTPSlot index={2} />
            </InputOTPGroup>
            <InputOTPSeparator />
            <InputOTPGroup>
              <InputOTPSlot index={3} />
              <InputOTPSlot index={4} />
              <InputOTPSlot index={5} />
            </InputOTPGroup>
          </InputOTP>
        </CardContent>
        <CardFooter className="flex flex-col gap-4">
          <Button
            loading={loading}
            className="w-full"
            disabled={otp.length !== 6}
          >
            Login
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              onCancel();
            }}
            className="w-full"
            variant="outline"
          >
            Go back
          </Button>
        </CardFooter>
      </Card>
    </motion.form>
  );
}
