import { z } from "zod";

export const CreateVehicleSourceSchema = z.object({
  CompanyId: z
    .number()
    .int()
    .nonnegative()
    .refine((val) => val !== undefined, {
      message: "CompanyId is required",
    }),

  SourceName: z
    .string()
    .min(1, { message: "SourceName is required" })
    .transform((value) => value.trim().toUpperCase()),

  ExcludeDefault: z
    .boolean()
    .optional()
    .transform((value) => Boolean(value)),

  IsZeroGrossSource: z
    .boolean()
    .optional()
    .transform((value) => Boolean(value)),
});
