import { AxiosInstance } from "axios";
import { CreateSaleDto } from "./dto/create-sale.dto";
import { UpdateSaleDto } from "./dto/update-sale.dto";
import { Sale } from "./sales";

import { CreateSaleSchema } from "./schemas/create-sale.schema";
import { UpdateSaleSchema } from "./schemas/update-sale.schema";

export class Sales {
  private readonly instance: AxiosInstance;
  private companyId: number;

  /**
   * Creates an instance of the Auth class.
   * @param {AxiosInstance} instance - The Axios instance to use for HTTP requests.
   */
  constructor(instance: AxiosInstance, companyId: number) {
    this.instance = instance;
    this.companyId = companyId;
  }

  /**
   * Create a new sale
   * @param {CreateSaleDto} saleObject - The sale object to create.
   * @returns {Promise<Sale>} The created sale.
   */

  public async create(saleObject: CreateSaleDto) {
    const { data } = await this.instance.post(
      `/companies/${this.companyId}/sales`,
      saleObject
    );
    return data as Sale;
  }

  /**
   * Get all sales
   * @param {GetAllSalesOptions} options - The options to filter the sales.
   * @returns {Promise<Sale[]>} The list of sales.
   */

  public async getAll(options?: GetAllSalesOptions) {
    const { data } = await this.instance.get(
      `/companies/${this.companyId}/sales`,
      {
        params: options,
      }
    );
    return data as Sale[];
  }

  /**
   * Get a sale by ID
   * @param {number} saleId - The ID of the sale to retrieve.
   * @returns {Promise<Sale>} The sale.
   */

  public async getById(saleId: number) {
    const { data } = await this.instance.get(
      `/companies/${this.companyId}/sales/${saleId}`
    );
    return data as Sale;
  }

  /**
   * Get a sale by vin
   * @param {string} vin - The vin number of the sale to retrieve.
   * @returns {Promise<Sale>} The sale.
   */

  public async getByVin(vin: string) {
    const { data } = await this.instance.get(
      `/companies/${this.companyId}/sales/by-vin/${vin}`
    );
    return data as Sale;
  }

  /**
   * Update a sale
   * @param {number} saleId - The ID of the sale to update.
   * @param {UpdateSaleDto} saleObject - The sale object to update.
   * @returns {Promise<Sale>} The updated sale.
   */

  public async update(saleId: number, saleObject: UpdateSaleDto) {
    const { data } = await this.instance.patch(
      `/companies/${this.companyId}/sales/${saleId}`,
      saleObject
    );
    return data as Sale;
  }

  /**
   * Delete a sale
   * @param {number} saleId - The ID of the sale to delete.
   */

  public async delete(saleId: number) {
    await this.instance.delete(`/companies/${this.companyId}/sales/${saleId}`);
  }

  public getSchemas() {
    return {
      create: CreateSaleSchema,
      update: UpdateSaleSchema,
    };
  }
}

export type GetAllSalesOptions = {
  startDate?: string;
  endDate?: string;
  vehicleSourceId?: number;
  model?: string;
  make?: string;
  year?: number;
  minAge?: number;
  maxAge?: number;
  includeSourceNames?: string[] | undefined;
};
