import { Badge } from "@/components/ui/badge";
import { XIcon } from "lucide-react";

type FilterBadgeProps = {
  label: string;
  onClear: () => void;
};

export default function FilterBadge({ label, onClear }: FilterBadgeProps) {
  return (
    <Badge variant="secondary" className="cursor-pointer" onClick={onClear}>
      {label}
      <XIcon className="inline ml-2 w-4" />
    </Badge>
  );
}
