// components/AutomationItem.tsx
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { AutomationPreference } from "@/lib/api/automation-preferences/automation-preferences";
import {
  UpdateAutomationPreferenceDto,
  UpdateAutomationPreferenceSchema,
} from "@/lib/api/automation-preferences/schemas/update-automation-preference.schema";
import useAutomationCrud from "../../hooks/automation-settings/use-automation-crud";
import AutomationDialogTrigger from "./automation-dialog-trigger";
import EditAutomationPreferenceForm from "./edit-automation-preference-form";
import EditAutomationMappings from "./edit-automation-mappings";
import EditAutomationRules from "./edit-automation-rules";

type AutomationItemProps = {
  automation: AutomationPreference;
  companyId: number;
};

export default function AutomationItem({
  automation,
  companyId,
}: AutomationItemProps) {
  const form = useForm<UpdateAutomationPreferenceDto>({
    resolver: zodResolver(UpdateAutomationPreferenceSchema),
    defaultValues: automation as UpdateAutomationPreferenceDto,
  });

  const { updateAutomation } = useAutomationCrud(companyId);

  const handleOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      form.reset();
    } else {
      form.reset(automation as UpdateAutomationPreferenceDto);
    }
  };

  return (
    <Dialog
      key={automation.AutomationPreferenceId}
      onOpenChange={handleOpenChange}
    >
      <DialogTrigger className="w-full hover:underline">
        <AutomationDialogTrigger automation={automation} />
      </DialogTrigger>
      <DialogContent className="w-screen max-w-4xl max-h-[90%] overflow-y-auto">
        <EditAutomationPreferenceForm
          automation={automation}
          formProps={form}
          updateAutomationCallback={updateAutomation.mutate}
          loading={updateAutomation.loading}
        />
        <EditAutomationMappings
          mappings={automation.AutomationMappings}
          companyId={companyId}
          preferenceId={automation.AutomationPreferenceId}
        />
        <EditAutomationRules
          rules={automation.AutomationRules}
          companyId={companyId}
          preferenceId={automation.AutomationPreferenceId}
        />
      </DialogContent>
    </Dialog>
  );
}
