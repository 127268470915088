import { useContext } from "react";
import { HookFormContext } from ".";
import { Input } from "@/components/ui/input";
import { FieldValues, Path } from "react-hook-form";
import { cn } from "@/lib/utils";
import { Badge } from "@/components/ui/badge";
import { Label } from "@/components/ui/label";

type HookFormInputProps<T> = {
  label: string;
  name: Path<T extends FieldValues ? T : FieldValues>;
  type?: string;
};

export default function HookFormInput<T>({
  label,
  name,
  type = "text",
}: HookFormInputProps<T>) {
  const ctx = useContext(HookFormContext);
  if (!ctx) {
    throw new Error("HookFormInput must be used within a HookForm");
  }
  const error = ctx.errors[name];
  return (
    <div>
      <Label htmlFor={name} className="ml-1">
        {label}
      </Label>
      <Input
        type={type}
        {...ctx.register(name)}
        className={cn(error ? "border-red-300 bg-red-50" : "mb-6")}
      />
      {error && <Badge variant="destructive">{String(error.message)}</Badge>}
    </div>
  );
}
