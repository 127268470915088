// components/TripLogIdInstructions.tsx
import ExampleImage from "@/assets/images/google_sheet_id_example.png";
import { Card, CardContent } from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { QuestionMarkCircledIcon } from "@radix-ui/react-icons";

export default function TripLogIdInstructions() {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <span className="opacity-70 hover:opacity-90 hover:underline cursor-pointer transition-opacity flex items-center">
          <QuestionMarkCircledIcon />
          Where to find this
        </span>
      </DialogTrigger>
      <DialogContent className="max-w-[48rem]">
        <DialogTitle>Where to find Trip Log Google Sheet ID</DialogTitle>
        <p>This can be found in the URL of the Google Sheet</p>
        <p>It is the long combination of characters found:</p>
        <ul className="flex flex-col gap-2 ml-8 list-disc">
          <li>
            Immediately after the{" "}
            <span className="bg-black/10 rounded-md p-1">.../d/</span>
          </li>
          <li>
            Immediately before the{" "}
            <span className="bg-black/10 rounded-md p-1">/edit...</span>
          </li>
        </ul>
        <Card>
          <CardContent>
            <img
              src={ExampleImage}
              alt="Google Sheet URL"
              className="mt-6 w-full"
            />
            <p className="w-full text-center text-sm mt-4">
              Be sure <span className="underline">NOT</span> to include ANY
              forward-slashes{" "}
              <span className="bg-black/10 rounded-md p-1">/</span>
            </p>
          </CardContent>
        </Card>
        <p>
          After pasting the ID here, share the Google Sheet with the following
          email address:
        </p>
        <span className="p-1 bg-secondary rounded-md text-center block">
          master@max-autolytics.iam.gserviceaccount.com
        </span>
      </DialogContent>
    </Dialog>
  );
}
