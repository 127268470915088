import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { AutomationRule } from "@/lib/api/automation-preferences/automation-preferences";
import useAutomationCrud from "../../hooks/automation-settings/use-automation-crud";
import RuleTable from "./automation-rule-table";
import AddRuleDialog from "./add-rule-dialog";

type EditAutomationRulesProps = {
  companyId: number;
  preferenceId: number;
  rules: AutomationRule[];
};

export default function EditAutomationRules({
  companyId,
  preferenceId,
  rules,
}: EditAutomationRulesProps) {
  const { rule } = useAutomationCrud(companyId);

  return (
    <Card>
      <CardHeader>
        <CardTitle>Edit validation rules</CardTitle>
      </CardHeader>
      <CardContent>
        <RuleTable
          rules={rules}
          deleteRule={rule.delete.mutate}
          preferenceId={preferenceId}
          loading={rule.delete.loading}
        />
        <AddRuleDialog
          addRule={rule.add.mutate}
          loading={rule.add.loading}
          preferenceId={preferenceId}
        />
      </CardContent>
    </Card>
  );
}
