import { AxiosInstance } from "axios";

import { CreateMakerModelPreferenceDto } from "./dto/create-maker-model-preference.dto";
import { MakerModelPreference } from "./maker-model-preference";
import { UpdateMakerModelPreferenceDto } from "./dto/update-maker-model-preference.dto";
import { CreateMakerModelPreferenceSchema } from "./schemas/create-maker-model-preference.schema";
import { UpdateMakerModelPreferenceSchema } from "./schemas/update-maker-model-preference.schema";

export class MakerModelPreferences {
  private readonly instance: AxiosInstance;
  private companyId: number;

  /**
   * Creates an instance of the Auth class.
   * @param {AxiosInstance} instance - The Axios instance to use for HTTP requests.
   */
  constructor(instance: AxiosInstance, companyId: number) {
    this.instance = instance;
    this.companyId = companyId;
  }

  /**
   * Create a new preference
   * @param {CreateMakerModelPreferenceDto} preferenceObject - The preference object to create.
   * @returns {Promise<MakerModelPreference>} The created preference.
   */

  public async create(preferenceObject: CreateMakerModelPreferenceDto) {
    const { data } = await this.instance.post(
      `/companies/${this.companyId}/maker-model-preferences`,
      preferenceObject
    );
    return data as MakerModelPreference;
  }

  /**
   * get all preferences for a store
   * @returns {Promise<MakerModelPreference[]>} The preferences.
   */

  public async getAll() {
    const { data } = await this.instance.get(
      `/companies/${this.companyId}/maker-model-preferences`
    );
    return data as MakerModelPreference[];
  }

  /**
   * get a specific preference
   * @param {number} preferenceId - The preference ID.
   * @returns {Promise<MakerModelPreference>} The preference.
   */

  public async getOne(preferenceId: number) {
    const { data } = await this.instance.get(
      `/companies/${this.companyId}/maker-model-preferences/${preferenceId}`
    );
    return data as MakerModelPreference;
  }

  /**
   * Update a preference
   * @param {number} preferenceId - The preference ID.
   * @param {UpdateMakerModelPreferenceDto} preferenceObject - The preference object to update.
   * @returns {Promise<MakerModelPreference>} The updated preference.
   */

  public async update(
    preferenceId: number,
    preferenceObject: UpdateMakerModelPreferenceDto
  ) {
    const { data } = await this.instance.patch(
      `/companies/${this.companyId}/maker-model-preferences/${preferenceId}`,
      preferenceObject
    );
    return data as MakerModelPreference;
  }

  /**
   * Remove a preference
   * @param {number} preferenceId - The preference ID.
   * @returns {Promise<void>} An empty promise.
   */

  public async remove(preferenceId: number) {
    await this.instance.delete(
      `/companies/${this.companyId}/maker-model-preferences/${preferenceId}`
    );
  }

  public getSchemas() {
    return {
      create: CreateMakerModelPreferenceSchema,
      update: UpdateMakerModelPreferenceSchema,
    };
  }
}
