import dayjs from "dayjs";

export const DateRangePresets = {
  today: () => {
    return {
      startDate: dayjs().startOf("day"),
      endDate: dayjs().endOf("day"),
    };
  },
  yesterday: () => {
    return {
      startDate: dayjs().subtract(1, "day").startOf("day"),
      endDate: dayjs().subtract(1, "day").endOf("day"),
    };
  },
  last7Days: () => {
    return {
      startDate: dayjs().subtract(8, "day").startOf("day"),
      endDate: dayjs().subtract(1, "day").endOf("day"),
    };
  },
  last30Days: () => {
    return {
      startDate: dayjs().subtract(31, "day").startOf("day"),
      endDate: dayjs().subtract(1, "day").endOf("day"),
    };
  },
  last90Days: () => {
    return {
      startDate: dayjs().subtract(91, "day").startOf("day"),
      endDate: dayjs().subtract(1, "day").endOf("day"),
    };
  },
  lastSixMonths: () => {
    return {
      startDate: dayjs().subtract(6, "month").startOf("month"),
      endDate: dayjs().subtract(1, "day").endOf("day"),
    };
  },
  monthToDate: () => {
    return {
      startDate: dayjs().startOf("month"),
      endDate: dayjs().subtract(1, "day").endOf("day"),
    };
  },
  previousMonth: () => {
    return {
      startDate: dayjs().subtract(1, "month").startOf("month"),
      endDate: dayjs().subtract(1, "month").endOf("month"),
    };
  },
  previousMonthMonthToDate: () => {
    return {
      startDate: dayjs().subtract(1, "month").startOf("month"),
      endDate: dayjs().subtract(1, "day").endOf("day").subtract(1, "month"),
    };
  },
  yearToDate: () => {
    return {
      startDate: dayjs().startOf("year"),
      endDate: dayjs().subtract(1, "day").endOf("day"),
    };
  },
  previousYear: () => {
    return {
      startDate: dayjs().subtract(1, "year").startOf("year"),
      endDate: dayjs().subtract(1, "year").endOf("year"),
    };
  },
  previousYearYearToDate: () => {
    return {
      startDate: dayjs().subtract(1, "year").startOf("year"),
      endDate: dayjs().subtract(1, "day").endOf("day").subtract(1, "year"),
    };
  },
  previousYearMonthToDate: () => {
    return {
      startDate: dayjs().subtract(1, "year").startOf("year"),
      endDate: dayjs().subtract(1, "day").endOf("day").subtract(1, "year"),
    };
  },
};
