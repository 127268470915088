import { useEffect, useState, useCallback } from "react";

interface UseDebounceProps {
  value: string;
  debouncedValue: string;
  setValue: (newValue: string) => void;
}

export default function useDebounce(
  initialValue: string,
  delay: number,
  debounceFn?: (value: string) => void
): UseDebounceProps {
  const [value, setValueState] = useState(initialValue);
  const [debouncedValue, setDebouncedValue] = useState(initialValue);

  const setValue = useCallback((newValue: string) => {
    setValueState(newValue);
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  useEffect(() => {
    if (debounceFn) {
      debounceFn(debouncedValue);
    }
  }, [debouncedValue, debounceFn]);

  return { value, debouncedValue, setValue };
}
