import { z } from "zod";

export const CreateMakerModelPreferenceSchema = z.object({
  Model: z.string().transform((val) => val.toLowerCase()),
  TargetDaysSupply: z
    .number()
    .int()
    .nonnegative()
    .transform((val) => Number(val)),
  InventoryFootprintPreferenceId: z
    .number()
    .int()
    .nonnegative()
    .transform((val) => Number(val)),
});
