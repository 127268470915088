import { Link, useSearchParams } from "react-router-dom";

type LinkWithQueryProps = {
  to: string;
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
  query?: Record<string, string>;
};

export default function LinkWithQuery({
  to,
  children,
  className,
  onClick,
  query,
  ...props
}: LinkWithQueryProps) {
  const queryParams = useSearchParams();
  if (query) {
    for (const key in query) {
      queryParams[0].set(key, query[key]);
    }
  }
  return (
    <Link
      to={{
        pathname: to,
        search: queryParams[0].toString(),
      }}
      className={className}
      onClick={onClick}
      {...props}
    >
      {children}
    </Link>
  );
}
