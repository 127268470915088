import { LocalAppPreferencesContext } from "@/lib/providers/local-app-preferences-provider";
import { useContext } from "react";

export default function useLocalAppPreferences() {
  const preferences = useContext(LocalAppPreferencesContext);
  if (!preferences)
    throw new Error(
      "useLocalAppPreferences must be used within a LocalAppPreferencesContextProvider"
    );
  return preferences;
}
