import { createContext, useMemo } from "react";
import MaxAutolyticsApiClass from "@/lib/api";

export const MaxAutolyticsApiContext =
  createContext<MaxAutolyticsApiClass | null>(null);

type MaxAutolyticsApiProviderProps = {
  children: React.ReactNode | React.ReactNode[];
};
export const MaxAutolyticsApiProvider = ({
  children,
}: MaxAutolyticsApiProviderProps) => {
  const api = useMemo(() => {
    const apiInstance = new MaxAutolyticsApiClass();
    const apiToken = localStorage.getItem("Authorization");
    if (apiToken) {
      apiInstance.setAuthToken(apiToken);
    }
    return apiInstance;
  }, []);

  return (
    <MaxAutolyticsApiContext.Provider value={api}>
      {children}
    </MaxAutolyticsApiContext.Provider>
  );
};
