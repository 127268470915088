import { Alert } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import useApi from "@/hooks/use-api";
import { TwoFactorOption } from "@/lib/api/auth/auth";
import { motion } from "framer-motion";
import { useState } from "react";

type TwoFactorOptionPickerProps = {
  options: TwoFactorOption[];
  onSuccess: (option: TwoFactorOption) => void;
  onCancel: () => void;
};

export default function TwoFactorOptionPicker({
  options,
  onSuccess,
  onCancel,
}: TwoFactorOptionPickerProps) {
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onChooseOption = async (option: TwoFactorOption) => {
    setLoading(true);
    setError(null);
    try {
      if (option.name === "Email") {
        await api.auth().sendEmailOtp(option.authKey);
      }
      if (option.name === "Phone") {
        await api.auth().sendSmsOtp(option.authKey);
      }
      onSuccess(option);
    } catch (e: any) {
      setError(e.response.data?.message ?? e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <motion.div
      key="login-get-otp"
      className="grid gap-4"
      initial={{
        opacity: 0,
        translateY: 100,
      }}
      animate={{
        opacity: 100,
        translateY: 0,
      }}
      exit={{
        opacity: 0,
        translateY: -100,
      }}
    >
      {error && <Alert variant="destructive">{error}</Alert>}
      {options?.map((option) => (
        <Card>
          <CardHeader>{option.name}</CardHeader>
          <CardContent className="flex justify-between items-center">
            <p>{option.value}</p>
            <Button loading={loading} onClick={() => onChooseOption(option)}>
              Use this
            </Button>
          </CardContent>
        </Card>
      ))}

      <Button
        onClick={() => onCancel()}
        className="mt-4 w-full"
        variant="outline"
      >
        Go back
      </Button>
    </motion.div>
  );
}
