import { AxiosInstance } from "axios";
import * as dayjs from "dayjs";
import { CreateClosureDto } from "./dto/create-closure.dto";
import { UpdateClosureDto } from "./dto/update-closure.dto";
import { ClosureDate } from "./closure-dates";

import { CreateClosureDateSchema } from "./schemas/create-closure-date.schema";
import { UpdateCarGurusPricingRecordSchema } from "../cargurus-pricing-record/schemas/update-cargurus-pricing-record.schema";

export class ClosureDates {
  private readonly instance: AxiosInstance;
  private companyId: number;

  /**
   * Creates an instance of the ClosureDates class.
   * @param {AxiosInstance} instance - The Axios instance to use for HTTP requests.
   * @param {number} companyId - The ID of the company to get closure dates for.
   */
  constructor(instance: AxiosInstance, companyId: number) {
    this.instance = instance;
    this.companyId = companyId;
  }

  /**
   * Create a new closure date
   * @param {CreateClosureDto} closureObject - The closure date object to create.
   * @returns {Promise<ClosureDate>} The created closure date.
   */

  public async create(closureObject: CreateClosureDto) {
    const { data } = await this.instance.post(
      `/companies/${this.companyId}/closure-dates`,
      closureObject
    );
    return data as ClosureDate;
  }

  /**
   * Get all closure dates for a company
   * @param {GetAllClosureDatesOptions} options - The options for getting closure dates
   * @returns {Promise<ClosureDate[]>} The closure dates for the company.
   */

  public async getAll(options?: GetAllClosureDatesOptions) {
    const startDateString = options?.startDate
      ? dayjs(options.startDate).format("YYYY-MM-DD HH:mm:ss")
      : undefined;
    const endDateString = options?.endDate
      ? dayjs(options.endDate).format("YYYY-MM-DD HH:mm:ss")
      : undefined;
    const { data } = await this.instance.get(
      `/companies/${this.companyId}/closure-dates`,
      {
        params: {
          startDate: startDateString,
          endDate: endDateString,
        },
      }
    );
    return data as ClosureDate[];
  }

  /**
   * Get a closure date by ID
   * @param {number} closureDateId - The ID of the closure date to get.
   * @returns {Promise<ClosureDate>} The closure date.
   */

  public async getById(closureDateId: number) {
    const { data } = await this.instance.get(
      `/companies/${this.companyId}/closure-dates/${closureDateId}`
    );
    return data as ClosureDate;
  }

  /**
   * Update a closure date by ID
   * @param {number} closureDateId - The ID of the closure date to update.
   * @param {UpdateClosureDto} closureObject - The closure date object to update.
   * @returns {Promise<ClosureDate>} The updated closure date.
   */

  public async update(closureDateId: number, closureObject: UpdateClosureDto) {
    const { data } = await this.instance.patch(
      `/companies/${this.companyId}/closure-dates/${closureDateId}`,
      closureObject
    );
    return data as ClosureDate;
  }

  /**
   * Delete a closure date by ID
   * @param {number} closureDateId - The ID of the closure date to delete.
   */

  public async delete(closureDateId: number) {
    await this.instance.delete(
      `/companies/${this.companyId}/closure-dates/${closureDateId}`
    );
  }

  public getSchemas() {
    return {
      create: CreateClosureDateSchema,
      update: UpdateCarGurusPricingRecordSchema,
    };
  }
}

type GetAllClosureDatesOptions = {
  startDate?: string | Date | dayjs.Dayjs;
  endDate?: string | Date | dayjs.Dayjs;
};
