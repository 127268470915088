import { AxiosInstance } from "axios";
import { StateLocation } from "./states";

export class States {
  private readonly instance: AxiosInstance;

  /**
   * Creates an instance of the Auth class.
   * @param {AxiosInstance} instance - The Axios instance to use for HTTP requests.
   */
  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  /**
   *
   * @returns {Promise<StateLocation[]>} A promise that returns an array of State objects.
   */

  public async findAll() {
    const { data } = await this.instance.get("/states");
    return data as StateLocation[];
  }

  public async findOne(stateId: number) {
    const { data } = await this.instance.get(`/states/${stateId}`);
    return data as StateLocation;
  }

  public getSchemas() {
    return {};
  }
}
