// components/constants.ts

import { AutomationRule } from "@/lib/api/automation-preferences/automation-preferences";
import { getValidationRuleColors } from "./validation-rule-colors";
import { proper } from "@/lib/utils";

export const COLUMN_LETTER_OPTIONS = Array.from({ length: 52 }, (_, i) =>
  i < 26 ? String.fromCharCode(65 + i) : `A${String.fromCharCode(65 + i - 26)}`
);

export const SALE_VEHICLE_TO_KEYS: Record<string, string> = {
  StockNumber: "Stock number",
  Vin: "Vin",
  IsCertified: "Is certified? (true/false)",
  TookATrade: "Took a trade? (true/false)",
  ModelYear: "Model Year",
  Make: "Make",
  Model: "Model",
  VehicleTrimLevel: "Trim level",
  Gross: "*Gross",
  SoldAt: "*Sold date (proper date time)",
  Odometer: "Odometer",
  InitialAskingPrice: "Initial asking price",
  DaysInInventory: "Days to sell",
  SellPrice: "Sell price",
  PercentOfMarket: "Percent of market",
  MSRP: "MSRP",
  InitialManheimWholesale: "Initial Manheim price",
  FinalManheimWholesale: "Final Manheim price",
  InitialACV: "Estimated cost",
  FinalACV: "Real cost",
  AppraisedValue: "Appraised value",
  CostToMarket: "Cost to market",
  Notes: "Notes",
  IsRollback: "Is rolled back? (true/false)",
  RollbackDate: "Rolled back on date (proper date time)",
};

export const CONVERSION_FN_MAP: Record<string, string> = {
  "to-date": "Convert to proper date time format",
  "to-boolean": "Convert to true/false",
  "to-number": "Convert to pure number",
  none: "Leave data as-is",
};

export const getAutomationRuleExplanation = (rule: AutomationRule) => {
  const explanations = [];
  let backgroundColor = null;
  if (
    rule.BackgroundColorRed !== null ||
    rule.BackgroundColorGreen !== null ||
    rule.BackgroundColorBlue !== null
  ) {
    const colors = getValidationRuleColors();
    const rgbString = `rgb(${Math.round(
      Number(rule.BackgroundColorRed) * 255
    )}, ${Math.round(Number(rule.BackgroundColorGreen) * 255)}, ${Math.round(
      Number(rule.BackgroundColorBlue) * 255
    )})`;
    const color = colors.find((color) => color.color === rgbString);
    backgroundColor = rgbString;
    explanations.push(`Background color: ${proper(color?.name)}`);
  }
  if (rule.ValueIsNotEmpty !== null) {
    explanations.push(`Value is not empty`);
  }
  if (rule.ValueEqualTo !== null) {
    explanations.push(`Value equal to ${rule.ValueEqualTo}`);
  }
  if (rule.ValueNotEqualTo !== null) {
    explanations.push(`Value not equal to ${rule.ValueNotEqualTo}`);
  }
  if (rule.ValueGreaterThan !== null) {
    explanations.push(`Value greater than ${rule.ValueGreaterThan}`);
  }
  if (rule.ValueLessThan !== null) {
    explanations.push(`Value less than ${rule.ValueLessThan}`);
  }
  if (rule.ValueIsEmpty !== null) {
    explanations.push(`Value is empty`);
  }
  return {
    string: explanations.join(" AND "),
    backgroundColor,
  };
};
