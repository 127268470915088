import { MaxAutolyticsApiContext } from "@/lib/providers/api-provider";
import { useContext } from "react";

export default function useApi() {
  const api = useContext(MaxAutolyticsApiContext);
  if (!api) {
    throw new Error("useApi must be used within a MaxAutolyticsApiProvider");
  }
  return api;
}
