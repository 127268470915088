import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {Badge} from "@/components/ui/badge.tsx";
import {Lock, TrendingDown, TrendingUp} from "lucide-react";
import {cn} from "@/lib/utils.ts";
import {Tooltip, TooltipContent, TooltipTrigger} from "@/components/ui/tooltip.tsx";
import {AnimatePresence, motion} from "framer-motion";

type ContentCardProps<T> = {
  title: string;
  data: undefined | (T extends { comparators: Record<keyof T, number>, companyName: string } ? T[] & { comparators: Record<keyof T, number>, companyName: string } : T[]);
  dataKey: keyof T;
  isLocked?: boolean;
  loading?: boolean;
  loadingSkeletonCount?: number;
  format?: 'currency' | 'none' | 'percent';
};

export default function DataCard<T>({ title, data, dataKey, isLocked, loading, loadingSkeletonCount = 1, format = 'none' }: ContentCardProps<T>) {
    return (
        <Card className="w-full md:min-w-32 relative">
            <CardHeader className="text-sm p-2 md:p-4 md:pb-2 space-y-0">
                <CardTitle className="font-normal whitespace-nowrap flex items-center gap-1">
                    {title}
                </CardTitle>
            </CardHeader>
            <CardContent className="text-base font-bold p-2 md:p-4 md:pb-2 pt-0 md:pt-0">
                {isLocked && (
                    <Tooltip>
                        <TooltipTrigger className='absolute top-2 right-2'>
                            <Lock className='w-3 h-3 opacity-60'  />
                        </TooltipTrigger>
                        <TooltipContent side='bottom'>
                            This metric is locked to the current month
                        </TooltipContent>
                    </Tooltip>
                )}
                <AnimatePresence mode='popLayout'>
                    {loading ? (
                        Array.from({ length: loadingSkeletonCount }).map((_, index) => (
                            <motion.div
                                className="py-1"
                                key={'loading-' + index}
                                initial={{ opacity: 0, width: '0%' }}
                                animate={{ opacity: 1, width: '100%' }}
                                exit={{ opacity: 0, width: '0%', }}
                            >
                                <div className="opacity-60 text-xs font-normal mt-2">
                                    <div className="w-16 h-3 bg-gray-200 rounded-md animate-pulse"></div>
                                </div>
                                <div className='flex items-center gap-2 mt-1'>
                                    <div className="w-12 h-4 bg-gray-200 rounded-md animate-pulse"></div>
                                    <div className="w-8 h-4 bg-gray-200 rounded-md animate-pulse"></div>
                                </div>
                            </motion.div>
                        ))
                    ) : (
                    data?.map((item, index) => {
                        const metric = item[dataKey] as number
                        const formattedMetric = format === 'currency' ? `$${metric.toLocaleString()}` : format === 'percent' ? `${metric.toLocaleString()}%` : metric
                        const comparator: number | undefined = item.comparators && item.comparators[dataKey] as number
                        return (
                            <motion.div
                                className="py-1"
                                key={'stable-' + index}
                                initial={{opacity: 0}}
                                animate={{opacity: 1}}
                                exit={{opacity: 0}}
                            >
                                <div className="opacity-60 text-xs font-normal">
                                    {item.companyName}
                                </div>
                                <div className='flex items-center gap-2'>
                                    <div className="text-base font-bold">{formattedMetric}</div>
                                    {!isLocked && comparator !== null && comparator !== undefined && <Badge
                                        className={cn('text-xs py-0 px-1 opacity-70', comparator > 0 ? 'bg-green-200 text-green-800' : comparator < 0 ? 'bg-red-50 text-red-600' : 'bg-yellow-50 text-yellow-600')}>
                                        {comparator > 0 ? <TrendingUp className='w-3 h-3 mr-1'/> :
                                            comparator < 0 ? <TrendingDown className='w-3 h-3 mr-1'/> : <div></div>}
                                        {Math.abs(comparator)}%
                                    </Badge>}
                                </div>
                            </motion.div>
                        )
                    }))}
                </AnimatePresence>
            </CardContent>
        </Card>
    );
}
