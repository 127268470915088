// components/EditAutomationPreferenceForm.tsx
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import HookForm from "@/features/hook-form";
import HookFormInput from "@/features/hook-form/hook-form-input";
import HookFormSwitch from "@/features/hook-form/hook-form-switch";
import HookFormSelect from "@/features/hook-form/hook-form-select";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils";
import { AutomationPreference } from "@/lib/api/automation-preferences/automation-preferences";
import { UpdateAutomationPreferenceDto } from "@/lib/api/automation-preferences/schemas/update-automation-preference.schema";
import TripLogIdInstructions from "./trip-log-id-instructions";
import { UseFormReturn } from "react-hook-form";
import { UseMutateAsyncFunction } from "react-query";

type EditAutomationPreferenceFormProps = {
  automation: AutomationPreference;
  formProps: UseFormReturn<UpdateAutomationPreferenceDto>;
  updateAutomationCallback: UseMutateAsyncFunction<
    any,
    unknown,
    {
      values: UpdateAutomationPreferenceDto;
      id: number;
    },
    unknown
  >;
  loading: boolean;
};

export default function EditAutomationPreferenceForm({
  automation,
  formProps,
  updateAutomationCallback,
  loading,
}: EditAutomationPreferenceFormProps) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = formProps;

  return (
    <Card>
      <CardHeader>
        <CardTitle>Edit "{automation.Name}" automation</CardTitle>
      </CardHeader>
      <CardContent>
        <HookForm<UpdateAutomationPreferenceDto>
          errors={errors}
          register={register}
          onSubmit={handleSubmit((values) =>
            updateAutomationCallback({
              values,
              id: automation.AutomationPreferenceId,
            })
          )}
        >
          <HookFormInput name="Name" label="Automation Name" />
          <div className="grid grid-cols-5 gap-4">
            <HookFormSwitch
              isChecked={!!watch("IsActive")}
              label="Is Active?"
              name="IsActive"
            />
            <div className="col-span-2">
              <HookFormSwitch
                isChecked={!!watch("AllowOverrideRequiredFields")}
                label="Override Required Fields?"
                name="AllowOverrideRequiredFields"
              />
            </div>
            <div className="col-span-2">
              <HookFormSelect
                label="Compare Value"
                name="ExistingCompareValue"
                currentValue={watch("ExistingCompareValue")}
                options={["Vin", "StockNumber"]}
                onValueChangeCallback={(newValue) =>
                  setValue("ExistingCompareValue", newValue)
                }
              />
            </div>
          </div>
          <HookFormInput
            name="EmailAddressesOnFailed"
            label="Alert Email Addresses (Comma Separated)"
          />
          <div>
            <div className="flex items-center gap-2">
              <Label htmlFor="TripLogGoogleSheetId" className="ml-1">
                Trip Log Google Sheet ID
              </Label>
              <TripLogIdInstructions />
            </div>
            <div
              className={cn(
                "flex items-center gap-1 border rounded-lg px-2",
                errors.TripLogGoogleSheetId
                  ? "border-red-300 bg-red-50"
                  : "mb-6"
              )}
            >
              <p className="opacity-30">
                https://docs.google.com/spreadsheets/d/
              </p>
              <Input
                id="TripLogGoogleSheetId"
                {...register("TripLogGoogleSheetId")}
              />
              <p className="opacity-30">/edit</p>
            </div>
            {errors.TripLogGoogleSheetId && (
              <Badge variant="destructive">
                {errors.TripLogGoogleSheetId.message}
              </Badge>
            )}
          </div>
          <Button type="submit" className="w-full" loading={loading}>
            Save Changes
          </Button>
        </HookForm>
      </CardContent>
    </Card>
  );
}
