import { useContext } from "react";
import { HookFormContext } from ".";
import { FieldValues, Path } from "react-hook-form";
import { Badge } from "@/components/ui/badge";
import { Switch } from "@/components/ui/switch";

type HookFormSwitchProps<T> = {
  label: string;
  name: Path<T extends FieldValues ? T : FieldValues>;
  isChecked: boolean;
};

export default function HookFormSwitch<T>({
  label,
  name,
  isChecked,
}: HookFormSwitchProps<T>) {
  const ctx = useContext(HookFormContext);
  if (!ctx) {
    throw new Error("HookFormSwitch must be used within a HookForm");
  }
  const error = ctx.errors[name];
  const switchProps = ctx.register(name);
  return (
    <div>
      <label htmlFor={name}>{label}</label>
      <br />
      <Switch
        {...switchProps}
        checked={isChecked}
        onCheckedChange={(newChecked) => {
          const syntheticEvent = {
            target: { name, value: Number(newChecked) },
          };
          switchProps.onChange(syntheticEvent);
        }}
      />
      {error && <Badge variant="destructive">{String(error.message)}</Badge>}
    </div>
  );
}
