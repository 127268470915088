import { AxiosInstance } from "axios";
import { InviteDto } from "./dto/invite.dto";
import { UpdateUserDto } from "./dto/update-user.dto";
import { CreateUserDto } from "./dto/create-user.dto";
import { ResetPasswordDto } from "./dto/reset-password.dto";
import { User } from "./users";

import { CreateUserSchema } from "./schemas/create-user.schema";
import { UpdateUserSchema } from "./schemas/update-user.schema";

export class Users {
  private readonly instance: AxiosInstance;

  /**
   * Creates an instance of the Auth class.
   * @param {AxiosInstance} instance - The Axios instance to use for HTTP requests.
   */
  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  /**
   * Create a new user
   * @param {CreateUserDto} userObject - The user object to create.
   * @returns {Promise<string>} The JWT auth token.
   */

  public async create(userObject: CreateUserDto) {
    const response = await this.instance.post("/users", userObject);
    let token = null;
    if (response.headers.authorization) {
      token = response.headers.authorization;
    }
    return token;
  }

  /**
   * Update a user
   * @param {number} userId - The ID of the user to update.
   * @param {UpdateUserDto} userObject - The user object to update.
   * @returns {Promise<boolean>}
   */

  public async update(userId: number, userObject: UpdateUserDto) {
    await this.instance.patch(`/users/${userId}`, userObject);
    return true;
  }

  /**
   * Get a user by email
   * @param {string} email - The email of the user to get.
   * @returns {Promise<User | null>} The user object.
   */

  public async getByEmail(email: string) {
    const { data } = await this.instance.get(`users/email/${email}`);
    return data as User | null;
  }

  /**
   * Get a user by ID
   * @param {number} userId - The ID of the user to get.
   * @returns {Promise<User | null>} The user object.
   */

  public async getById(userId: number) {
    const { data } = await this.instance.get(`users/${userId}`);
    return data as User | null;
  }

  /**
   * Invite a user
   * @param {InviteDto} inviteDto - The invite object.
   * @returns {Promise<boolean>}
   */

  public async invite(inviteDto: InviteDto) {
    await this.instance.post("/users/invite", inviteDto);
    return true;
  }

  /**
   * Request a password reset
   * @param {string} email - The email of the user to reset the password for.
   * @returns {Promise<boolean>}
   */

  public async requestPasswordReset(email: string) {
    await this.instance.get(`/users/password-reset?EmailAddress=${email}`);
    return true;
  }

  /**
   * Reset a password
   * @param {ResetPasswordDto} resetPasswordDto - The reset password object.
   * @returns {Promise<boolean>}
   */

  public async resetPassword(resetPasswordDto: ResetPasswordDto) {
    await this.instance.post("/users/password-reset", resetPasswordDto);
    return true;
  }

  /**
   * Get the email from a token
   * @param {string} token - The token to get the email from.
   * @returns {Promise<string>} The email.
   */

  public async getEmailFromToken(token: string) {
    const { data } = await this.instance.get(
      `/users/email-from-token?token=${token}`
    );
    return data as string;
  }

  public getSchemas() {
    return {
      create: CreateUserSchema,
      update: UpdateUserSchema,
    };
  }
}
