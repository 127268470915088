import { AxiosInstance } from "axios";

import { CarGurusPricingRecord } from "../cargurus-pricing-record";
import { NHTSAVehicleDef } from "./states";

export class VehicleDetails {
  private readonly instance: AxiosInstance;

  /**
   * Creates an instance of the Auth class.
   * @param {AxiosInstance} instance - The Axios instance to use for HTTP requests.
   */
  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  /**
   * Get the newest CarGurus pricing index for a vehicle, based on the zip code of a store
   * @param {string} vin - The VIN of the vehicle.
   * @param {number} odometer - The odometer reading of the vehicle.
   * @param {number} companyId - The ID of
   * @returns {Promise<CarGurusPricingRecord[]>} A promise that returns an array of State objects.
   */

  public async getNewestCargurusPricingIndex(
    vin: string,
    odometer: number,
    companyId: number
  ) {
    const { data } = await this.instance.get("/details/cargurus", {
      params: {
        vin,
        odometer,
        companyId,
      },
    });
    return data as CarGurusPricingRecord;
  }

  /**
   * Get the NHTSA vehicle definition for a VIN
   * @param {string} vin - The VIN of the vehicle.
   * @returns {Promise<NHTSAVehicleDef>} A promise that returns an array of State objects.
   */

  public async getNHTSAVehicleDef(vin: string) {
    const { data } = await this.instance.get(`/details/nhtsa`, {
      params: {
        vin,
      },
    });
    return data as NHTSAVehicleDef;
  }

  public getSchemas() {
    return {};
  }
}
