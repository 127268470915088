import { z } from "zod";
import * as dayjs from "dayjs";

export const CreateAdjustmentSchema = z.object({
  Notes: z.string().max(512),
  Value: z
    .number()
    .refine((v) => !isNaN(v), { message: "Value must include only numbers" }),
  IsGAdjustment: z.boolean(),
  CreatedAt: z
    .string()
    .refine((v) => !dayjs(v).isValid, { message: "Invalid date" }),
});
