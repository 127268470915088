import { z } from "zod";
import * as dayjs from "dayjs";

export const CreateClosureDateSchema = z
  .object({
    Date: z.string(),
    IsDayName: z.boolean(),
  })
  .refine(
    (data) => {
      const isDayName = data.IsDayName;
      const date = data.Date;
      if (isDayName) {
        return [
          "sunday",
          "monday",
          "tuesday",
          "wednesday",
          "thursday",
          "friday",
          "saturday",
        ].includes(date);
      } else {
        return dayjs(date).isValid();
      }
    },
    {
      message: "Invalid date",
      path: ["Date"],
    }
  );
