// components/AutomationSettings.tsx
import { AnimatePresence, motion } from "framer-motion";
import { CircularProgress } from "@mui/material";
import useAutomationCrud from "../../hooks/automation-settings/use-automation-crud";
import AutomationItem from "./automation-item";
import { AutomationPreference } from "@/lib/api/automation-preferences/automation-preferences";

type AutomationSettingsProps = {
  companyId: number;
};

export default function Automations({ companyId }: AutomationSettingsProps) {
  const { automations } = useAutomationCrud(companyId);

  return (
    <AnimatePresence mode="popLayout">
      {automations.loading ? (
        <LoadingIndicator />
      ) : (
        <motion.div
          key="stable"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {automations.data?.map((automation: AutomationPreference) => (
            <AutomationItem
              key={automation.AutomationPreferenceId}
              automation={automation}
              companyId={companyId}
            />
          ))}
        </motion.div>
      )}
    </AnimatePresence>
  );
}

function LoadingIndicator() {
  return (
    <motion.div
      key="loading"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex justify-center items-center h-32"
    >
      <CircularProgress />
    </motion.div>
  );
}
