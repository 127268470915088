import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import useLocalAppPreferences from "@/hooks/use-local-app-preferences";
import { DateRangePresets } from "@/lib/date-range-presets";
import { proper } from "@/lib/utils";
import { Calendar, CalendarIcon, StarIcon } from "lucide-react";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

export default function DateRangeSelector() {
  const preferences = useLocalAppPreferences();

  const setDateRangePreference = (presetName: string) => {
    preferences.setPreference("global.dateRange", presetName);
  };

  const splitAfterIndeces = [1, 4, 8];

  const startDateDisplayString =
    typeof preferences.preferences.global.dateRange === "string"
      ? DateRangePresets[
          preferences.preferences.global
            .dateRange as keyof typeof DateRangePresets
        ]().startDate.format("MM/DD/YYYY")
      : preferences.preferences.global.dateRange.startDate;

  const endDateDisplayString =
    typeof preferences.preferences.global.dateRange === "string"
      ? DateRangePresets[
          preferences.preferences.global
            .dateRange as keyof typeof DateRangePresets
        ]().endDate.format("MM/DD/YYYY")
      : preferences.preferences.global.dateRange.endDate;

  const dateRangeDisplayIndicator =
    typeof preferences.preferences.global.dateRange === "string"
      ? proper(
          preferences.preferences.global.dateRange,
          /(?<=[a-z])(?=[A-Z])|(?<=[A-Za-z])(?=\d)|(?<=\d)(?=[A-Za-z])/g
        )
      : "Custom...";

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className="font-normal text-sm">
          <Calendar className="mr-2 inline-block -mt-1" />
          {startDateDisplayString} - {endDateDisplayString}
          <span className="opacity-50 ml-2">{dateRangeDisplayIndicator}</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>Pick a date range</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuSub>
          <DropdownMenuSubTrigger>
            <div className="flex flex-col gap-2">
              <div className="flex gap-2">
                <CalendarIcon />
                Set custom date range...
              </div>
              {typeof preferences.preferences.global.dateRange === "object" && (
                <div className="w-full opacity-70 text-xs text-center">
                  {startDateDisplayString}-{endDateDisplayString}
                </div>
              )}
            </div>
          </DropdownMenuSubTrigger>
          <DropdownMenuSubContent>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StaticDateRangePicker
                calendars={2}
                defaultValue={[
                  dayjs(startDateDisplayString),
                  dayjs(endDateDisplayString),
                ]}
                onAccept={(date) => {
                  preferences.setPreference("global.dateRange", {
                    startDate: date[0]?.format("MM/DD/YYYY"),
                    endDate: date[1]?.format("MM/DD/YYYY"),
                  });
                }}
              />
            </LocalizationProvider>
          </DropdownMenuSubContent>
        </DropdownMenuSub>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => setDateRangePreference("monthToDate")}>
          Month to date
          <StarIcon className="fill-yellow-300 stroke-yellow-300 w-4 h-4" />{" "}
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        {Object.keys(DateRangePresets).map((presetName: string, index) => (
          <div key={presetName}>
            <DropdownMenuItem
              key={presetName}
              onClick={() => setDateRangePreference(presetName)}
            >
              {proper(
                presetName,
                /(?<=[a-z])(?=[A-Z])|(?<=[A-Za-z])(?=\d)|(?<=\d)(?=[A-Za-z])/g
              )}
            </DropdownMenuItem>
            {splitAfterIndeces.includes(index) && <DropdownMenuSeparator />}
          </div>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
