import { DataGridPro } from "@mui/x-data-grid-pro";
import { getDataGridColumns } from "./lib/columnDefinition";
import { useCallback, useMemo, useState } from "react";
import Header from "./components/table-header";
import InventoryTableFooter from "./components/table-footer";
import { Dialog } from "@/components/ui/dialog";
// import ActiveInventoryModal from "./components/active-inventory-modal";
import useInventoryListContext from "./hooks/use-inventory-list-context";
import useCellClassnameCallback from "./hooks/use-cell-classname-callback";
import useRowClassnameCallback from "./hooks/use-row-classname-callback";
import useFetchInventoryData from "./hooks/use-fetch-inventory-data";

export default function Inventory() {
  const listContext = useInventoryListContext();
  const { inventory, loadingInventory } = useFetchInventoryData(listContext);

  const getCellClassName = useCellClassnameCallback(listContext);
  const getRowClassName = useRowClassnameCallback();

  const [detailsModalInventoryInfo, setDetailsModalInventoryInfo] = useState<{
    companyId: number;
    saleVehicleId: number;
  } | null>(null);

  const openModalForInventory = useCallback(
    (InventoryVehicleId: number, CompanyId: number) => {
      setDetailsModalInventoryInfo({
        companyId: CompanyId,
        saleVehicleId: InventoryVehicleId,
      });
    },
    []
  );

  const columns = useMemo(
    () => getDataGridColumns(listContext.targetMarginsMap, openModalForInventory),
    [listContext.targetMarginsMap, openModalForInventory]
  );

  return (
    <div className="absolute inset-0 rounded-xl overflow-hidden">
      <DataGridPro
        loading={loadingInventory}
        rows={inventory ?? []}
        getRowId={(row) => row.SaleVehicleId}
        columns={columns}
        style={{
          borderRadius: "0.75rem",
        }}
        slots={{
          toolbar: () => <Header inventory={inventory ?? []} />,
          footer: InventoryTableFooter,
        }}
        disableRowSelectionOnClick
        getCellClassName={getCellClassName}
        getRowClassName={(params ) => getRowClassName(params, listContext.activeCompanyIds)}
        density="compact"
      />
      <Dialog
        open={detailsModalInventoryInfo !== null}
        onOpenChange={(isOpen) => !isOpen && setDetailsModalInventoryInfo(null)}
      >
        {/*<ActiveInventoryModal*/}
        {/*  companyId={detailsModalInventoryInfo?.companyId}*/}
        {/*  saleVehicleId={detailsModalInventoryInfo?.saleVehicleId}*/}
        {/*  onClose={() => setDetailsModalInventoryInfo(null)}*/}
        {/*/>*/}
      </Dialog>
    </div>
  );
}
