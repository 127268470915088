import { useQuery } from "react-query";
import useSalesListContext from "./use-sales-list-context";

export default function useFetchSalesData(
  listContext: ReturnType<typeof useSalesListContext>
) {
  const { data: sales, isLoading: loadingSales } = useQuery({
    queryKey: [
      "sales",
      listContext.activeCompanies.map((company) => company.CompanyId),
      listContext.startDate,
      listContext.endDate,
      listContext.salesFilters.make,
      listContext.salesFilters.model,
      listContext.salesFilters.year,
        listContext.salesFilters.minAge,
        listContext.salesFilters.maxAge,
      listContext.salesFilters.includeSourceNames,
    ],
    queryFn: async () => {
      const promises = listContext.activeCompanies.map((company) =>
        listContext.api.sales(company.CompanyId).getAll({
          startDate: listContext.startDate,
          endDate: listContext.endDate,
          ...listContext.salesFilters,
        })
      );
      const salesResults = await Promise.all(promises);
      return salesResults.flat().sort((a, b) => {
        return new Date(b.SoldAt).getTime() - new Date(a.SoldAt).getTime();
      });
    },
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: true,
  });
  return { sales, loadingSales };
}
