import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useNavigate } from "react-router";
import useLocalAppPreferences from "@/hooks/use-local-app-preferences";
import { proper } from "@/lib/utils";
import useSalesDashboardData from "@/pages/dashboard/hooks/use-sales-dashboard-data.tsx";
import OverviewMetricsCards from "./overview-metrics-cards.tsx";
import SalesPerDayChart from "./sales-per-day-chart.tsx";
import CargurusAtAGlanceChart from "./cargurus-at-a-glance-chart.tsx";
import CargurusAtAGlanceTable from "./cargurus-at-a-glance-table.tsx";
import SalesByAgeChart from "./sales-by-age-chart.tsx";
import SalesByAgeTable from "./sales-by-age-table.tsx";
import SalesBySourceTable from "@/pages/dashboard/components/sales-breakdown.tab/sales-by-source-table.tsx";

export default function SalesBreakdownTab() {
  const {data, loading} = useSalesDashboardData()
  const navigate = useNavigate();
  const preferences = useLocalAppPreferences();
  const dateRangeString =
    typeof preferences.preferences.global.dateRange === "string"
      ? proper(
          preferences.preferences.global.dateRange,
          /(?<=[a-z])(?=[A-Z])|(?<=[A-Za-z])(?=\d)|(?<=\d)(?=[A-Za-z])/g
        )
      : `${preferences.preferences.global.dateRange.startDate} thru ${preferences.preferences.global.dateRange.endDate}`;
  const companiesCount = preferences.preferences.global.activeCompanyIds.length

  return (
      <div className="container flex flex-col gap-4 max-w-[76rem] pb-32">
        <Card>
          <CardHeader>
            <CardTitle>Sales Overview</CardTitle>
          <h2 className="opacity-50">All sales data {dateRangeString}</h2>
        </CardHeader>
        <CardContent>
            <OverviewMetricsCards data={data} loading={loading} storeCount={companiesCount} />
            <SalesPerDayChart data={data} loading={loading} preferences={preferences} navigate={navigate} />
        </CardContent>
      </Card>
          <Card>
              <CardHeader>
                  <CardTitle>Cargurus at a glance</CardTitle>
                  <h2 className="opacity-50">How you&apos;re pricing in your market</h2>
              </CardHeader>
              <CardContent className='w-full flex items-center justify-center gap-4'>
                  <CargurusAtAGlanceChart data={data} loading={loading} />
                  <CargurusAtAGlanceTable data={data} loading={loading} storeCount={companiesCount} />
              </CardContent>
          </Card>
          <Card>
              <CardHeader>
                  <CardTitle>Sales by age</CardTitle>
                  <h2 className="opacity-50">How many days your sales have taken to sell</h2>
              </CardHeader>
              <CardContent className='w-full flex items-center gap-4 justify-center'>
                  <SalesByAgeChart data={data} loading={loading} />
                  <SalesByAgeTable data={data} loading={loading} storeCount={companiesCount} />
              </CardContent>
          </Card>
          <Card>
              <CardHeader>
                  <CardTitle>Sales by source</CardTitle>
                  <h2 className="opacity-50">Where you are sourcing your vehicles from</h2>
              </CardHeader>
              <CardContent className='w-full flex items-center gap-4'>
                  <SalesBySourceTable data={data} />
              </CardContent>
          </Card>
    </div>
  );
}
