import { z } from "zod";

export const CreateUserSchema = z.object({
  FirstName: z.string().max(64),
  LastName: z.string().max(64),
  MiddleInitial: z.string().max(1),
  EmailAddress: z.string().email().max(128),
  Password: z.string().max(64),
  inviteToken: z.string().max(512),
});
