import { AxiosInstance } from "axios";
import { CreateCompanyDto } from "./dto/create-company.dto";
import { UpdateCompanyDto } from "./dto/update-company.dto";
import { Company } from "./companies";

import { UpdateCompanySchema } from "./schemas/update-company.schema";

/**
 * CompaniesAPI class for managing company-related operations via API.
 */
export default class Companies {
  private readonly instance: AxiosInstance;

  /**
   * Creates an instance of the Auth class.
   * @param {AxiosInstance} instance - The Axios instance to use for HTTP requests.
   */
  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  /**
   * Retrieves all companies the current user has access to.
   * @returns {Promise<Company>} A promise that resolves to an array of companies.
   */

  async getAllCompanies() {
    const response = await this.instance.get("/companies");
    return response.data as Company[];
  }

  /**
   * Retrieves a company by its ID.
   * @param {number} companyId - The ID of the company to retrieve.
   * @returns {Promise<Company>} A promise that resolves to the company data.
   */

  async getCompanyById(companyId: number) {
    const response = await this.instance.get(`/companies/${companyId}`);
    return response.data as Company;
  }

  /**
   * Creates a new company.
   * @param {CreateCompanyDto} companyData - The data for the company to create.
   * @returns {Promise<any>} A promise that resolves to the created company data.
   */

  async createCompany(companyData: CreateCompanyDto) {
    const response = await this.instance.post("/companies", companyData);
    return response.data;
  }

  /**
   * Updates an existing company.
   * @param {number} companyId - The ID of the company to update.
   * @param {UpdateCompanyDto} companyData - The new data for the company.
   * @returns {Promise<Company>} A promise that resolves to the updated company data.
   */

  async updateCompany(companyId: number, companyData: UpdateCompanyDto) {
    const response = await this.instance.patch(
      `/companies/${companyId}`,
      companyData
    );
    return response.data as Company;
  }

  /**
   * Deletes a company by its ID.
   * @param {number} companyId - The ID of the company to delete.
   * @returns {Promise<string>} A promise that resolves to the deletion response.
   */

  async deleteCompany(companyId: number) {
    const response = await this.instance.delete(`/companies/${companyId}`);
    return response.data;
  }

  public getSchemas() {
    return {
      update: UpdateCompanySchema,
    };
  }
}
