import { Sale } from "@/lib/api/sales/sales";
import { UpdateSaleSchema } from "@/lib/api/sales/schemas/update-sale.schema";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useMemo } from "react";
import HookForm from "@/features/hook-form";
import HookFormInput from "@/features/hook-form/hook-form-input";
import { Button } from "@/components/ui/button";
import HookFormSelect from "@/features/hook-form/hook-form-select";
import HookFormSwitch from "@/features/hook-form/hook-form-switch";
import { CardFooter } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useApi from "@/hooks/use-api";
import { UpdateSaleDto } from "@/lib/api/sales/dto/update-sale.dto";
import {toast} from "sonner";

type EditSaleFormProps = {
  sale: Sale | undefined;
};

export default function EditSaleForm({ sale }: EditSaleFormProps) {
  const queryClient = useQueryClient();
  const schema = UpdateSaleSchema;
  type schemaType = z.infer<typeof schema>;
  const api = useApi();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
    watch,
  } = useForm<schemaType>({ resolver: zodResolver(schema) });

  useEffect(() => {
    if (sale) {
      reset(sale as schemaType);
    }
    watch();
  }, [sale, reset]);

  const yearOptions = useMemo(() => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: 30 }, (_, i) => currentYear - i);
  }, []);

  const { mutateAsync: updateSale, isLoading: updateLoading } = useMutation({
    mutationFn: async (values: schemaType) => {
      return await api
        .sales(sale!.CompanyId)
        .update(sale!.SaleVehicleId, values as UpdateSaleDto);
    },
    onSuccess: () => {
      queryClient.invalidateQueries("sales");
      queryClient.invalidateQueries("sale");
      toast.success("Sale updated successfully");
    },
  });
  const { data: sources } = useQuery({
    queryKey: ["sources", sale?.CompanyId],
    queryFn: async () => {
      const data = await api
        .vehicleSources(sale!.CompanyId)
        .findAll({ hideFromSuggestions: false });
      return data;
    },
    enabled: !!sale?.CompanyId,
  });

  return (
    <HookForm<schemaType>
      register={register}
      onSubmit={handleSubmit((values) => updateSale(values))}
      errors={errors}
    >
      <p className="text-sm text-gray-500">Vehicle information</p>
      <Separator />
      <div className="grid grid-cols-5 gap-2">
        <HookFormInput<schemaType> label="Stock" name="StockNumber" />
        <div className="col-span-2">
          <HookFormInput<schemaType> label="Vin" name="Vin" />
        </div>
        <HookFormSwitch<schemaType>
          label="Is certified?"
          name="IsCertified"
          isChecked={Boolean(getValues("IsCertified"))}
        />
        <HookFormSwitch<schemaType>
          label="Took a trade?"
          name="TookATrade"
          isChecked={Boolean(getValues("TookATrade"))}
        />
      </div>
      <div className="grid grid-cols-4 gap-2">
        <HookFormSelect<schemaType>
          label="Year"
          name="ModelYear"
          options={yearOptions}
          onValueChangeCallback={(value) =>
            setValue("ModelYear", Number(value))
          }
          currentValue={String(getValues("ModelYear"))}
        />
        <HookFormInput<schemaType> label="Make" name="Make" />
        <HookFormInput<schemaType> label="Model" name="Model" />
        <HookFormInput<schemaType> label="Trim" name="VehicleTrimLevel" />
      </div>
      <p className="text-sm text-gray-500 mt-2">General pricing</p>
      <Separator />
      <div className="grid grid-cols-3 gap-2">
        <HookFormInput<schemaType> label="Gross" name="Gross" />
        <HookFormSelect<schemaType>
          label="Source"
          name="VehicleSourceId"
          options={sources?.map((source) => source.VehicleSourceId) ?? []}
          onValueChangeCallback={(value) =>
            setValue("VehicleSourceId", Number(value))
          }
          currentValue={String(getValues("VehicleSourceId"))}
          renderValue={(value) => {
            const source = sources?.find(
              (source) => source.VehicleSourceId === Number(value)
            );
            return source?.SourceName ?? "Select a source";
          }}
        />

        <HookFormInput<schemaType> label="Odometer" name="Odometer" />
      </div>
      <div className="grid grid-cols-3 gap-2">
        <HookFormInput<schemaType>
          label="Start price"
          name="InitialAskingPrice"
        />
        <HookFormInput<schemaType> label="Sell price" name="SellPrice" />
        <HookFormInput<schemaType>
          label="Days in inventory"
          name="DaysInInventory"
        />
      </div>
      <p className="text-sm text-gray-500 mt-2">Market pricing</p>
      <Separator />
      <div className="grid grid-cols-4 gap-2">
        <HookFormInput<schemaType>
          label="Percent of market"
          name="PercentOfMarket"
        />
        <HookFormInput<schemaType> label="MSRP" name="MSRP" />
        <HookFormInput<schemaType>
          label="Initial MMR"
          name="InitialManheimWholesale"
        />
        <HookFormInput<schemaType>
          label="Final MMR"
          name="FinalManheimWholesale"
        />
      </div>
      <div className="grid grid-cols-4 gap-2">
        <HookFormInput<schemaType> label="Estimated cost" name="InitialACV" />
        <HookFormInput<schemaType> label="Actual cost" name="FinalACV" />
        <HookFormInput<schemaType>
          label="Appraised value"
          name="AppraisedValue"
        />
      </div>
      <p className="text-sm text-gray-500 mt-2">Metadata</p>
      <Separator />
      <div className="grid grid-cols-4 gap-2">
        <div className="col-span-3">
          <HookFormInput<schemaType> label="Notes" name="Notes" />
        </div>
        <HookFormInput<schemaType> label="Sold at" name="SoldAt" />
      </div>
      <div className="grid grid-cols-4 gap-2">
        <HookFormSwitch<schemaType>
          label="Is rollback?"
          name="IsRollback"
          isChecked={Boolean(getValues("IsRollback"))}
        />
        <HookFormInput<schemaType> label="Rollback date" name="RollbackDate" />
      </div>
      <CardFooter>
        <Button
          onClick={(ev) => {
            ev.preventDefault();
            reset(sale as schemaType);
          }}
          className="ml-auto mr-2"
          variant="secondary"
          loading={updateLoading}
        >
          Reset changes
        </Button>
        <Button type="submit" loading={updateLoading}>
          Save changes to this sale
        </Button>
      </CardFooter>
    </HookForm>
  );
}
