// components/AutomationDialogTrigger.tsx
import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { AutomationPreference } from "@/lib/api/automation-preferences/automation-preferences";
import { cn } from "@/lib/utils";
import dayjs from "dayjs";

type AutomationDialogTriggerProps = {
  automation: AutomationPreference;
};

export default function AutomationDialogTrigger({
  automation,
}: AutomationDialogTriggerProps) {
  const isActive = automation.IsActive;
  const lastRunDate = automation.LastRunDate
    ? dayjs(automation.LastRunDate).format("MMM D, h:mm A")
    : "Never";

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <div
            className={cn(
              "w-3 h-3 rounded-full",
              isActive ? "bg-green-500" : "bg-red-500"
            )}
          />
          <span>{automation.Name}</span>
          <span className="opacity-70 font-normal">
            Last run at {lastRunDate}
          </span>
        </CardTitle>
      </CardHeader>
    </Card>
  );
}
