import { z } from "zod";

export const CreateAutomationMappingSchema = z.object({
  ToKey: z.string().min(1),
  FromColumn: z.string().min(1),
  ConvertionFn: z.enum(["to-date", "to-boolean", "to-number", "none"]),
});

export type CreateAutomationMappingDto = z.infer<
  typeof CreateAutomationMappingSchema
>;
