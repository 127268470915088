import DataCard from "@/pages/dashboard/components/data-card.tsx";
import {SalesOverview} from "@/lib/api/analytics/analytics";

type OverviewMetricsCardsProps = {
    data: SalesOverview[] | undefined
    loading: boolean
    storeCount: number
}

export default function OverviewMetricsCards({ data, loading, storeCount }: OverviewMetricsCardsProps) {
    return (
            <div className="w-full grid grid-cols-3 xl:grid-cols-6 items-center gap-4">
                <DataCard<SalesOverview>
                    title='Total sales'
                    data={data}
                    dataKey='totalSales'
                    loading={loading}
                    loadingSkeletonCount={storeCount}
                />
                <DataCard<SalesOverview>
                    title='Avg sales'
                    data={data}
                    dataKey='averageSalesPerDay'
                    loading={loading}
                    loadingSkeletonCount={storeCount}
                />
                <DataCard<SalesOverview>
                    title='Sales pace'
                    data={data}
                    dataKey='salesPace'
                    loading={loading}
                    loadingSkeletonCount={storeCount}
                    isLocked
                />
                <DataCard<SalesOverview>
                    title='Total gross'
                    data={data}
                    dataKey='totalGross'
                    loading={loading}
                    loadingSkeletonCount={storeCount}
                    format={'currency'}
                />
                <DataCard<SalesOverview>
                    title='Avg PVR'
                    data={data}
                    dataKey='averageGrossPerSale'
                    loading={loading}
                    loadingSkeletonCount={storeCount}
                    format={'currency'}
                />
                <DataCard<SalesOverview>
                    title='Gross pace'
                    data={data}
                    dataKey='grossPace'
                    loading={loading}
                    loadingSkeletonCount={storeCount}
                    format={'currency'}
                    isLocked
                />
            </div>
    )
}