"use client";

import { ChevronRight } from "lucide-react";

import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from "@/components/ui/sidebar";
import { RouteDefinition } from "@/config/routing/route-definitions.d";
import LinkWithQuery from "@/components/link-with-query";
import {useLocation} from "react-router";
import useLocalAppPreferences from "@/hooks/use-local-app-preferences.tsx";

type NavMainProps = {
  items: RouteDefinition[];
    location: ReturnType<typeof useLocation>;
    preferences: ReturnType<typeof useLocalAppPreferences>
};
export function NavMain({ items, location, preferences }: NavMainProps) {
  return (
    <SidebarGroup>
      <SidebarGroupLabel>Platform</SidebarGroupLabel>
      <SidebarMenu>
        {items.map((item) =>
          item.properties.navigation.subRoutes ? (
            <Collapsible
              key={item.properties.navigation.title}
              asChild
              defaultOpen={true}
              className="group/collapsible"
            >
              <SidebarMenuItem>
                <LinkWithQuery
                  to={item.path}
                  className="flex items-center gap-2"
                >
                  <CollapsibleTrigger asChild>
                    <SidebarMenuButton
                      tooltip={item.properties.navigation.title}
                      isActive={item.path === location.pathname}
                    >
                      {item.properties.navigation.icon && (
                        <item.properties.navigation.icon className="w-4 h-4" />
                      )}
                      <span>{item.properties.navigation.title}</span>
                      <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                    </SidebarMenuButton>
                  </CollapsibleTrigger>
                </LinkWithQuery>
                {item.properties.navigation.subRoutes && (
                  <CollapsibleContent>
                    <SidebarMenuSub>
                      {item.properties.navigation.subRoutes?.map((subItem) => (
                        <SidebarMenuSubItem key={subItem.title}>
                          <SidebarMenuSubButton
                              asChild
                              isActive={subItem.getIsActive?.(preferences.preferences)}
                          >
                            {subItem.path ? (
                              <LinkWithQuery
                                to={subItem.path}
                                onClick={() => {
                                  subItem.onClick?.(preferences.setPreference)
                                }}
                              >
                                <span>{subItem.title}</span>
                              </LinkWithQuery>
                            ) : (
                              <div
                                className="cursor-pointer"
                                onClick={() => {
                                  subItem.onClick?.(preferences.setPreference)
                                }}
                              >
                                {subItem.title}
                              </div>
                            )}
                          </SidebarMenuSubButton>
                        </SidebarMenuSubItem>
                      ))}
                    </SidebarMenuSub>
                  </CollapsibleContent>
                )}
              </SidebarMenuItem>
            </Collapsible>
          ) : (
            <SidebarMenuItem key={item.properties.navigation.title}>
              <LinkWithQuery to={item.path} className="flex items-center gap-2">
                <SidebarMenuButton tooltip={item.properties.navigation.title}
                                   isActive={item.path === location.pathname}
                >
                  {item.properties.navigation.icon && (
                    <item.properties.navigation.icon className="w-4 h-4" />
                  )}
                  <span>{item.properties.navigation.title}</span>
                </SidebarMenuButton>
              </LinkWithQuery>
            </SidebarMenuItem>
          )
        )}
      </SidebarMenu>
    </SidebarGroup>
  );
}
