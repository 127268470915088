import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
} from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";
import useCompanies from "@/hooks/use-companies";
import { Company } from "@/lib/api/companies/companies";
import { useState } from "react";
import { cn } from "@/lib/utils";
import Automations from "./components/automations";

export default function SettingsStores() {
  const companies = useCompanies();
  const [activeCompany, setActiveCompany] = useState<Company | null>(null);

  const groupedCompanies = companies.data?.reduce((acc, company) => {
    const groupName = company.GroupName ?? "";
    acc[groupName] = acc[groupName] || [];
    acc[groupName].push(company);
    return acc;
  }, {} as Record<string, Company[]>);

  const handleCompanyChange = (newValue: string) => {
    setActiveCompany(
      companies.data?.find((company) => company.CompanyId === Number(newValue)) || null
    );
  };

  return (
    <div className="container mx-auto w-full max-w-5xl mt-8 flex flex-col gap-4">
      <Select
        value={String(activeCompany?.CompanyId)}
        onValueChange={handleCompanyChange}
        defaultOpen={!activeCompany}
      >
        <SelectTrigger>{activeCompany?.Name ?? "Select a store"}</SelectTrigger>
        <SelectContent>
          {Object.entries(groupedCompanies ?? {}).map(([groupName, companies], index) => (
            <SelectGroup key={groupName}>
              <SelectLabel>{groupName}</SelectLabel>
              {companies.map((company) => (
                <SelectItem key={company.CompanyId} value={String(company.CompanyId)}>
                  {company.Name}
                </SelectItem>
              ))}
              {index < Object.entries(groupedCompanies ?? {}).length - 1 && <SelectSeparator />}
            </SelectGroup>
          ))}
        </SelectContent>
      </Select>
      <Card className={cn((companies.loading || !activeCompany) && "opacity-20 cursor-default pointer-events-none")}>
        <CardHeader>
          <CardTitle>
            {activeCompany?.Name ? `Settings for ${activeCompany.Name}` : "Settings - Select a store"}
          </CardTitle>
        </CardHeader>
        <CardContent className="pt-4">
          <Separator />
          <Accordion type="single" collapsible>
            {["store-details", "report-settings", "source-settings", "automation-settings"].map((item) => (
              <AccordionItem key={item} value={item}>
                <AccordionTrigger>{item.replace("-", " ")}</AccordionTrigger>
                <AccordionContent>
                  {item === "automation-settings" ? <Automations companyId={activeCompany?.CompanyId ?? 0} /> : item.replace("-", " ")}
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </CardContent>
      </Card>
    </div>
  );
}