import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHead,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { AutomationRule } from "@/lib/api/automation-preferences/automation-preferences";
import { EllipsisVertical, Trash } from "lucide-react";
import { getAutomationRuleExplanation } from "../../lib/constants";
import { Badge } from "@/components/ui/badge";

type RuleTableProps = {
  rules: AutomationRule[];
  deleteRule: ({
    preferenceId,
    ruleId,
  }: {
    preferenceId: number;
    ruleId: number;
  }) => void;
  loading: boolean;
  preferenceId: number;
};

export default function RuleTable({
  rules,
  deleteRule,
  loading,
  preferenceId,
}: RuleTableProps) {
  return (
    <Table>
      <TableHeader>
        <TableHead>Column</TableHead>
        <TableHead>Rule</TableHead>
        <TableHead></TableHead>
      </TableHeader>
      <TableBody>
        {rules.map((rule) => {
          const explanation = getAutomationRuleExplanation(rule);
          return (
            <TableRow key={rule.AutomationRuleId}>
              <TableCell>{rule.FromColumn}</TableCell>
              <TableCell>
                <Badge
                  variant="secondary"
                  style={{
                    backgroundColor: explanation.backgroundColor ?? "",
                  }}
                >
                  {explanation.string}
                </Badge>
              </TableCell>
              <TableCell>
                <DropdownMenu>
                  <DropdownMenuTrigger>
                    <EllipsisVertical />
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    <DropdownMenuItem
                      className="text-red-600 cursor-pointer"
                      onClick={() =>
                        deleteRule({
                          preferenceId,
                          ruleId: rule.AutomationRuleId,
                        })
                      }
                      asChild
                    >
                      <Button variant="ghost" loading={loading}>
                        <Trash className="inline-block -mt-1" /> Delete Rule
                      </Button>
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
