import useApi from "@/hooks/use-api";
import { CreateAutomationMappingDto } from "@/lib/api/automation-preferences/schemas/create-automation-mapping.schema";
import { CreateAutomationRuleDto } from "@/lib/api/automation-preferences/schemas/create-automation-rule.schema";
import { UpdateAutomationPreferenceDto } from "@/lib/api/automation-preferences/schemas/update-automation-preference.schema";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "sonner";

export default function useAutomationCrud(companyId: number) {
  const api = useApi();
  const queryClient = useQueryClient();

  const {
    data: automations,
    isLoading: loadingAutomations,
    error,
  } = useQuery(["automations", companyId], () =>
    api.automationSettings(companyId).getAll()
  );

  const updateAutomation = useMutation(
    ({ values, id }: { values: UpdateAutomationPreferenceDto; id: number }) =>
      api.automationSettings(companyId).update(id, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["automations", companyId]);
        toast.success("Automation updated successfully");
      },
      onError: (err) => {
        toast.error("Failed to update automation");
        console.error(err);
      },
    }
  );

  const deleteMapping = useMutation({
    mutationFn: ({
      preferenceId,
      mappingId,
    }: {
      preferenceId: number;
      mappingId: number;
    }) =>
      api.automationSettings(companyId).deleteMapping(preferenceId, mappingId),
    onSuccess: () => {
      queryClient.invalidateQueries(["automations", companyId]);
      toast.success("Mapping deleted successfully");
    },
  });

  const addMapping = useMutation({
    mutationFn: ({
      preferenceId,
      data,
    }: {
      preferenceId: number;
      data: CreateAutomationMappingDto;
    }) => api.automationSettings(companyId).createMapping(preferenceId, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["automations", companyId]);
      toast.success("Mapping added successfully");
    },
  });

  const deleteRule = useMutation({
    mutationFn: ({
      preferenceId,
      ruleId,
    }: {
      preferenceId: number;
      ruleId: number;
    }) => api.automationSettings(companyId).deleteRule(preferenceId, ruleId),
    onSuccess: () => {
      queryClient.invalidateQueries(["automations", companyId]);
      toast.success("Rule deleted successfully");
    },
  });

  const addRule = useMutation({
    mutationFn: ({
      preferenceId,
      data,
    }: {
      preferenceId: number;
      data: CreateAutomationRuleDto;
    }) => api.automationSettings(companyId).createRule(preferenceId, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["automations", companyId]);
      toast.success("Rule added successfully");
    },
  });

  return {
    automations: {
      data: automations,
      loading: loadingAutomations,
      error,
    },
    updateAutomation: {
      mutate: updateAutomation.mutateAsync,
      loading: updateAutomation.isLoading,
    },
    mapping: {
      delete: {
        mutate: deleteMapping.mutateAsync,
        loading: deleteMapping.isLoading,
      },
      add: {
        mutate: addMapping.mutateAsync,
        loading: addMapping.isLoading,
      },
    },
    rule: {
      delete: {
        mutate: deleteRule.mutateAsync,
        loading: deleteRule.isLoading,
      },
      add: {
        mutate: addRule.mutateAsync,
        loading: addRule.isLoading,
      },
    },
  };
}
