import { useQuery } from "react-query";
import useInventoryListContext from "./use-inventory-list-context.tsx";

export default function useFetchInventoryData(
  listContext: ReturnType<typeof useInventoryListContext>
) {
  const { data: inventory, isLoading: loadingInventory } = useQuery({
    queryKey: [
      "inventory",
      listContext.activeCompanies.map((company) => company.CompanyId),
      listContext.inventoryFilters.make,
      listContext.inventoryFilters.model,
      listContext.inventoryFilters.year,
        listContext.inventoryFilters.minAge,
        listContext.inventoryFilters.maxAge,
      listContext.inventoryFilters.includeSourceNames,
    ],
    queryFn: async () => {
      const promises = listContext.activeCompanies.map((company) =>
        listContext.api.inventory(company.CompanyId).getAll({
          ...listContext.inventoryFilters,
        })
      );
      const inventoryResults = await Promise.all(promises);
      return inventoryResults.flat().sort((a, b) => {
        return new Date(b.CreatedAt).getTime() - new Date(a.CreatedAt).getTime();
      });
    },
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: true,
  });
  return { inventory, loadingInventory };
}
