import axios, { AxiosInstance } from "axios";
import { Auth } from "./auth";
import { Users } from "./users";
import { Adjustments } from "./adjustments";
import Companies from "./companies";
import { ClosureDates } from "./closure-dates";
import { Sales } from "./sales";
import { Inventory } from "./inventory";
import { CarGurusPricingRecord } from "./cargurus-pricing-record";
import { States } from "./states";
import { VehicleDetails } from "./vehicle-details";
import { InventoryFootprintPreferences } from "./inventory-footprint-preference";
import { MakerModelPreferences } from "./maker-model-preference";
import { VehicleSources } from "./vehicle-sources";
import { AutomationPreferences } from "./automation-preferences";
import {Analytics} from "@/lib/api/analytics";

export default class MaxAutolyticsApi {
  private readonly instance: AxiosInstance;
  public isAuthenticatedInstance: boolean = false;

  constructor(AuthToken?: string | null) {
    if (!AuthToken) {
      console.warn(
        "\x1b[31m",
        "Max Autolytics API: No Auth Token provided. Please provide an Auth Token to use the API.\x1b[0m"
      );
    } else {
      this.isAuthenticatedInstance = true;
    }
    this.instance = axios.create({
      baseURL: import.meta.env.VITE_API_URL,
      timeout: 30000,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${AuthToken}`,
      },
    });
  }

  public setAuthToken(AuthToken: string) {
    this.instance.defaults.headers.Authorization = `Bearer ${AuthToken}`;
  }

  /**
   * Auth instance
   * @returns {Auth} Auth instance
   */
  public auth() {
    return new Auth(this.instance);
  }

  /**
   * Users instance
   * @returns {Users} Users instance
   */
  public users() {
    return new Users(this.instance);
  }

  /**
   * Adjustments instance
   * @param {number} companyId - The ID of the company to get adjustments for.
   * @returns {Adjustments} Adjustments instance
   */
  public adjustments(companyId: number) {
    return new Adjustments(this.instance, companyId);
  }

  /**
   * Companies instance
   * @returns {Companies} Companies instance
   */
  public companies() {
    return new Companies(this.instance);
  }

  /**
   * ClosureDates instance
   * @param {number} companyId - The ID of the company to get closure dates for.
   * @returns {ClosureDates} ClosureDates instance
   */
  public closureDates(companyId: number) {
    return new ClosureDates(this.instance, companyId);
  }

  /**
   * Sales instance
   * @param {number} companyId - The ID of the company to get sales for.
   * @returns {Sales} Sales instance
   */
  public sales(companyId: number) {
    return new Sales(this.instance, companyId);
  }

  /**
   * Inventory instance
   * @param {number} companyId - The ID of the company to get inventory for.
   * @returns {Inventory} Inventory instance
   */
  public inventory(companyId: number) {
    return new Inventory(this.instance, companyId);
  }

  /**
   * CarGurusPricingRecord instance
   * @param {number} companyId - The ID of the company to get carGurusPricingRecords for.
   * @returns {CarGurusPricingRecord} CarGurusPricingRecord instance
   */
  public cargurusRecords(companyId: number) {
    return new CarGurusPricingRecord(this.instance, companyId);
  }

  /**
   * Utilities instance
   * @returns {Utilities} Utilities instance
   */
  public utilities() {
    return new (class {
      private instance: AxiosInstance;
      constructor(instance: AxiosInstance) {
        this.instance = instance;
      }
      public states() {
        return new States(this.instance);
      }
      public vehicleDetails() {
        return new VehicleDetails(this.instance);
      }
    })(this.instance);
  }

  /**
   * InventoryFootprintPreferences instance
   * @param {number} companyId - The ID of the company to get inventory footprint preferences for.
   * @returns {InventoryFootprintPreferences} InventoryFootprintPreferences instance
   */
  public inventoryFootprintPreferences(companyId: number) {
    return new InventoryFootprintPreferences(this.instance, companyId);
  }

  /**
   * MakerModelPreferences instance
   * @param {number} companyId - The ID of the company to get maker model preferences for.
   * @returns {MakerModelPreferences} MakerModelPreferences instance
   */
  public makerModelPreferences(companyId: number) {
    return new MakerModelPreferences(this.instance, companyId);
  }

  public vehicleSources(companyId: number) {
    return new VehicleSources(this.instance, companyId);
  }

  public automationSettings(companyId: number) {
    return new AutomationPreferences(this.instance, companyId);
  }

  public analytics(companyId: number) {
    return new Analytics(companyId);
  }
}
