import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function proper(
  inputString: string | undefined | null,
  delimitRegex?: RegExp,
  joinWith?: string
) {
  if (!inputString) return "";
  if (!delimitRegex)
    return (
      inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase()
    );
  return inputString
    .split(delimitRegex)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(joinWith ?? " ");
}
