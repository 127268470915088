import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { GridColDef } from "@mui/x-data-grid-pro";
import {cn} from "@/lib/utils.ts";

export function getDataGridColumns(
  targetMarginsMap: Map<number, number>,
  onOpenModalForSaleCallback: (saleVehicleId: number, companyId: number) => void
) {
  return [
    {
      field: "CompanyName",
      headerName: "Store",
      flex: 100,
    },
    {
      field: "StockNumber",
      headerName: "Stock Number",
      flex: 80,
      minWidth: 80,
    },
    {
      field: "FullVehicleName",
      headerName: "Vehicle",
      flex: 150,
      minWidth: 150,
      renderCell: (params) => (
        <span
          className={cn("cursor-pointer hover:underline text-blue-600", !params.value && 'italic text-blue-400')}
          onClick={() =>
            onOpenModalForSaleCallback(
              params.row.SaleVehicleId,
              params.row.CompanyId
            )
          }
        >
          {params.value ?? 'Unknown'}
        </span>
      ),
    },
    {
      field: "Vin",
      headerName: "Vin",
      flex: 150,
      minWidth: 120,
    },
    {
      field: "Gross",
      headerName: "Gross",
      flex: 100,
      minWidth: 80,
      type: "number",
      renderCell: (params) => (
        <Tooltip>
          <TooltipTrigger>
            ${params.value?.toLocaleString() ?? 0}
          </TooltipTrigger>
          <TooltipContent side="right">
            Target margin for {params.row.CompanyName}: $
            {(
              targetMarginsMap.get(params.row.CompanyId) ?? 1500
            ).toLocaleString()}
          </TooltipContent>
        </Tooltip>
      ),
    },
    { field: "DaysInInventory", headerName: "Age", flex: 50, type: "number" },
    { field: "SourceName", headerName: "Source", flex: 100 },
    { field: "Odometer", headerName: "Mileage", flex: 100, type: "number" },
    {
      field: "InitialACV",
      headerName: "Estimated Cost",
      flex: 100,
      type: "number",
      renderCell: (params) => `$${params.value?.toLocaleString() ?? 0}`,
    },
    {
      field: "FinalACV",
      headerName: "Cost",
      flex: 100,
      type: "number",
      renderCell: (params) => `$${params.value?.toLocaleString() ?? 0}`,
    },
    {
      field: "IsCertified",
      headerName: "Certified",
      flex: 50,
      renderCell: (params) => (params.value ? "Yes" : "No"),
    },
    {
      field: "TookATrade",
      headerName: "Trade?",
      flex: 50,
      renderCell: (params) => (params.value ? "Yes" : "No"),
    },
    {
      field: "InitialAskingPrice",
      headerName: "Start Price",
      flex: 100,
      type: "number",
      renderCell: (params) => `$${params.value?.toLocaleString() ?? 0}`,
    },
    {
      field: "SellPrice",
      headerName: "Sell Price",
      flex: 100,
      type: "number",
      renderCell: (params) => `$${params.value?.toLocaleString() ?? 0}`,
    },
    {
      field: "PercentOfMarket",
      headerName: "% of Market",
      flex: 100,
      type: "number",
      renderCell: (params) => `${params.value?.toLocaleString() ?? 0}%`,
    },
  ] as GridColDef[];
}
