import { useContext } from "react";
import { HookFormContext } from ".";
import { FieldValues, Path, PathValue } from "react-hook-form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils";

type HookFormSelectProps<T> = {
  label: string;
  name: Path<T extends FieldValues ? T : FieldValues>;
  options: string[] | number[];
  onValueChangeCallback: (
    value: PathValue<
      T extends FieldValues ? T : FieldValues,
      Path<T extends FieldValues ? T : FieldValues>
    >
  ) => void;
  currentValue: PathValue<
    T extends FieldValues ? T : FieldValues,
    Path<T extends FieldValues ? T : FieldValues>
  >;
  renderValue?: (value: string | number) => string;
};

export default function HookFormSelect<T>({
  label,
  name,
  options,
  onValueChangeCallback,
  currentValue,
  renderValue,
}: HookFormSelectProps<T>) {
  const ctx = useContext(HookFormContext);
  if (!ctx) {
    throw new Error("HookFormSelect must be used within a HookForm");
  }
  const error = ctx.errors[name];
  return (
    <div className={cn("w-full", !error && "mb-6")}>
      <label htmlFor={name}>{label}</label>
      <Select
        {...ctx.register(name)}
        value={currentValue}
        onValueChange={onValueChangeCallback}
      >
        <SelectTrigger>
          {renderValue ? (
            renderValue(currentValue)
          ) : (
            <SelectValue placeholder="Select an option" />
          )}
        </SelectTrigger>
        <SelectContent>
          {options?.map((option) => (
            <SelectItem key={option} value={String(option)}>
              {renderValue ? renderValue(option) : String(option)}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      {error && <Badge variant="destructive">{String(error.message)}</Badge>}
    </div>
  );
}
