import { useCallback } from "react";
import {SalesOverview, SalesPerDayData} from "@/lib/api/analytics/analytics";
import {
    ChartContainer,
    ChartLegend,
    ChartTooltip,
} from "@/components/ui/chart";
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell, LegendProps, TooltipProps,
    XAxis,
    YAxis,
} from "recharts";
import dayjs from "dayjs";
import { toast } from "sonner";
import { UndoIcon } from "lucide-react";
import {NavigateFunction} from "react-router";
import { AnimatePresence, motion } from "framer-motion";
import {BarRectangleItem} from "recharts/types/cartesian/Bar";
import useLocalAppPreferences from "@/hooks/use-local-app-preferences.tsx";
import {Card, CardContent, CardHeader, CardTitle} from "@/components/ui/card.tsx";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "@/components/ui/table.tsx";

type SalesPerDayChartProps = {
    data?: SalesOverview[];
    loading: boolean;
    preferences: ReturnType<typeof useLocalAppPreferences>
    navigate: NavigateFunction
};

export default function SalesPerDayChart({ data, loading,preferences,navigate }: SalesPerDayChartProps) {
     const handleCellClick = useCallback(
        (item: SalesOverview, dataItem: SalesPerDayData) => {
            const currentCompanyIds = preferences.preferences.global.activeCompanyIds;
            const currentDateRange = preferences.preferences.global.dateRange;
            const date = dayjs(dataItem.date).format("MM/DD/YYYY");

            preferences.setPreference("global.activeCompanyIds", [item.companyId]);
            preferences.setPreference("global.dateRange", {
                startDate: date,
                endDate: date,
            });
            navigate("/sales");

            toast.info(`Set active store to ${item.companyName}`, {
                action: {
                    label: (
                        <span className="flex items-center gap-2">
              Undo <UndoIcon size={16} />
            </span>
                    ),
                    onClick: () => {
                        preferences.setPreference("global.activeCompanyIds", currentCompanyIds);
                        preferences.setPreference("global.dateRange", currentDateRange);
                        navigate("/dashboard");
                    },
                },
            });
        },
        [preferences, navigate]
    );

    const renderLegend = useCallback(
        ({ payload }: LegendProps) => (
            <div className="flex items-center gap-4 ml-auto w-fit">
                {payload?.map(({ value, color }, index) => (
                    <div className="flex items-center gap-0.5" key={index}>
                        <div className="w-2 h-2 rounded-full" style={{ backgroundColor: color }} />
                        <span>{value}</span>
                    </div>
                ))}
            </div>
        ),
        []
    );

    return (
        <AnimatePresence mode="wait">
            {loading ? (
                <motion.div
                    key="loading"
                    initial={{ opacity: 0}}
                    animate={{ opacity: 1}}
                    exit={{ opacity: 0}}
                    transition={{ duration: 0.2, damping: 0, bounce: 0, stiffness: 100 }}
                    className="w-full h-64 mt-8 bg-gray-200 rounded-md animate-pulse"
                />
            ) : (
                <motion.div
                    key="stable"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="w-full h-64"
                >
                    <ChartContainer config={{}} className="mt-8 h-full w-full">
                        <BarChart
                            data={data?.[0]?.salesPerDayData ?? []}
                            margin={{ top: 0, right: 0, bottom: 30, left: 0 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" vertical={false} />
                            <XAxis
                                dataKey="date"
                                tickFormatter={(value) => dayjs(value).format("ddd, MMM D")}
                                angle={-45}
                                tickMargin={16}
                                style={{ fontSize: "0.75rem" }}
                                allowDuplicatedCategory={false}
                            />
                            <YAxis width={30} dataKey="sales" />
                            <ChartTooltip content={CustomTooltip} />
                            {data?.map((item, superIndex) => (
                                <Bar
                                    key={superIndex}
                                    dataKey="sales"
                                    name={item.companyName}
                                    data={item.salesPerDayData as BarRectangleItem[]}
                                    fill={`hsl(${239 - 40 * superIndex}, ${100 - 20 * superIndex}%, 65%)`}
                                    barSize={10}
                                    radius={[4, 4, 0, 0]}
                                >
                                    {item.salesPerDayData?.map((dataItem, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            className="cursor-pointer"
                                            onClick={() => handleCellClick(item, dataItem)}
                                        />
                                    ))}
                                </Bar>
                            ))}
                            <ChartLegend content={(props) => renderLegend({ payload: props.payload })} align="right" verticalAlign="top" height={36} />
                        </BarChart>
                    </ChartContainer>
                </motion.div>
            )}
        </AnimatePresence>
    );
}

function CustomTooltip(props: TooltipProps<keyof SalesPerDayData, keyof SalesPerDayData>) {
    if(!props.active) return null;
    return (
        <Card>
            <CardHeader>
                <CardTitle>
                    {props.label}
                </CardTitle>
            </CardHeader>
            <CardContent>
                <Table>
                    <TableHeader>
                        <TableRow>
                        <TableHead className='min-w-48'>
                            Field
                        </TableHead>
                        {props.payload?.map((item) => (
                            <TableHead className='w-32 overflow-x-hidden text-ellipsis whitespace-nowrap'>
                                <div className='flex items-center gap-1'>
                                    <div className='w-2 h-2 rounded-full' style={{ backgroundColor: item.color }} /> {item.name}
                                </div>
                            </TableHead>
                        ))}
                            </TableRow>
                    </TableHeader>
                    <TableBody className='text-xs '>
                        <TableRow>
                            <TableCell className='text-primary/80'>
                                Total sales
                            </TableCell>
                            {props.payload?.map((item) => (
                                <TableCell>
                                    {item.payload.sales} ({item.payload.dayPercentOfTotal}%)
                                </TableCell>
                            ))}
                        </TableRow>
                        <TableRow>
                            <TableCell className='text-primary/80'>
                                Total gross
                            </TableCell>
                            {props.payload?.map((item) => (
                                <TableCell>
                                    ${item.payload.totalGross.toLocaleString()}
                                </TableCell>
                            ))}
                        </TableRow>
                        <TableRow>
                            <TableCell className='text-primary/80'>
                                Average gross per sale
                            </TableCell>
                            {props.payload?.map((item) => (
                                <TableCell>
                                    ${item.payload.averageGrossPerSale.toLocaleString()}
                                </TableCell>
                            ))}
                        </TableRow>
                        <TableRow>
                            <TableCell className='text-primary/80'>
                                Adjustments
                            </TableCell>
                            {props.payload?.map((item) => (
                                <TableCell>
                                    {item.payload.adjustmentsCount} ({item.payload.adjustmentsValue})
                                </TableCell>
                            ))}
                        </TableRow>
                        <TableRow>
                            <TableCell className='text-primary/80'>
                                Rollbacks
                            </TableCell>
                            {props.payload?.map((item) => (
                                <TableCell>
                                    {item.payload.rollbacksCount} ({item.payload.rollbacksValue})
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
}