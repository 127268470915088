import { Input } from "@/components/ui/input";
import { SearchIcon } from "lucide-react";

type SearchBarProps = {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function SearchBar({ value, onChange }: SearchBarProps) {
  return (
    <div className="relative w-full max-w-xl">
      <SearchIcon className="absolute top-1/2 left-3 transform -translate-y-1/2 opacity-30 w-6" />
      <Input
        value={value}
        onChange={onChange}
        placeholder="Search for anything..."
        className="pl-12"
      />
    </div>
  );
}
