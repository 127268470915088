import { z } from "zod";

export const CreateAutomationRuleSchema = z.object({
  BackgroundColorRed: z.number().nullable().optional(),
  BackgroundColorGreen: z.number().nullable().optional(),
  BackgroundColorBlue: z.number().nullable().optional(),
  ValueEqualTo: z.string().nullable().optional(),
  ValueNotEqualTo: z.string().nullable().optional(),
  ValueGreaterThan: z.number().optional().nullable(),
  ValueLessThan: z.number().optional().nullable(),
  FromColumn: z.string().min(1),
});

export type CreateAutomationRuleDto = z.infer<
  typeof CreateAutomationRuleSchema
>;
