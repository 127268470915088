import { DataGridPro } from "@mui/x-data-grid-pro";
import { getDataGridColumns } from "./lib/columnDefinition";
import { useCallback, useMemo, useState } from "react";
import Header from "./components/table-header";
import SalesTableFooter from "./components/table-footer";
import { Dialog } from "@/components/ui/dialog";
import ActiveSaleModal from "./components/active-sale-modal";
import useSalesListContext from "./hooks/use-sales-list-context";
import useCellClassnameCallback from "./hooks/use-cell-classname-callback";
import useRowClassnameCallback from "./hooks/use-row-classname-callback";
import useFetchSalesData from "./hooks/use-fetch-sales-data";

export default function Sales() {
  const listContext = useSalesListContext();
  const { sales, loadingSales } = useFetchSalesData(listContext);

  const getCellClassName = useCellClassnameCallback(listContext);
  const getRowClassName = useRowClassnameCallback();

  const [detailsModalSaleInfo, setDetailsModalSaleInfo] = useState<{
    companyId: number;
    saleVehicleId: number;
  } | null>(null);

  const openModalForSale = useCallback(
    (SaleVehicleId: number, CompanyId: number) => {
      setDetailsModalSaleInfo({
        companyId: CompanyId,
        saleVehicleId: SaleVehicleId,
      });
    },
    []
  );

  const columns = useMemo(
    () => getDataGridColumns(listContext.targetMarginsMap, openModalForSale),
    [listContext.targetMarginsMap, openModalForSale]
  );

  return (
    <div className="absolute inset-0 rounded-xl overflow-hidden">
      <DataGridPro
        loading={loadingSales}
        rows={sales ?? []}
        getRowId={(row) => row.SaleVehicleId}
        columns={columns}
        style={{
          borderRadius: "0.75rem",
        }}
        slots={{
          toolbar: () => <Header sales={sales ?? []} />,
          footer: SalesTableFooter,
        }}
        disableRowSelectionOnClick
        getCellClassName={getCellClassName}
        getRowClassName={(params ) => getRowClassName(params, listContext.activeCompanyIds)}
        density="compact"
      />
      <Dialog
        open={detailsModalSaleInfo !== null}
        onOpenChange={(isOpen) => !isOpen && setDetailsModalSaleInfo(null)}
      >
        <ActiveSaleModal
          companyId={detailsModalSaleInfo?.companyId}
          saleVehicleId={detailsModalSaleInfo?.saleVehicleId}
          onClose={() => setDetailsModalSaleInfo(null)}
        />
      </Dialog>
    </div>
  );
}
