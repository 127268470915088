import React, { FormEventHandler } from "react";
import { FieldErrors, FieldValues, UseFormRegister } from "react-hook-form";

type HookFormProps<T> = {
  register: UseFormRegister<T extends FieldValues ? T : FieldValues>;
  onSubmit: FormEventHandler<HTMLFormElement>;
  errors: FieldErrors<T extends FieldValues ? T : FieldValues>;
  children: React.ReactElement[] | React.ReactElement;
};

export const HookFormContext = React.createContext<Omit<
  HookFormProps<any>,
  "children"
> | null>(null);

export default function HookForm<T>({
  register,
  errors,
  onSubmit,
  children,
}: HookFormProps<T>) {
  return (
    <form onSubmit={onSubmit} className="w-full">
      <HookFormContext.Provider value={{ register, errors, onSubmit }}>
        {children}
      </HookFormContext.Provider>
    </form>
  );
}
