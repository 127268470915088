import useDebounce from "@/hooks/use-debounce";
import useLocalAppPreferences from "@/hooks/use-local-app-preferences";
import {
  GridToolbarContainer,
  GridToolbarExport,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import SearchBar from "./search-bar";
import FilterBadge from "./filter-badge";
import FilterMenu from "./filter-menu";
import { useCallback } from "react";
import {Inventory} from "@/lib/api/inventory/inventory";
import {SidebarTrigger} from "@/components/ui/sidebar.tsx";

export default function Header({ inventory }: { inventory: Inventory[] }) {
  const apiRef = useGridApiContext();
  const debounceFn = useCallback(
    (newValue: string) =>
      apiRef.current.setQuickFilterValues(newValue.split(" ")),
    [apiRef]
  );
  const searchValue = useDebounce("", 250, debounceFn);
  const preferences = useLocalAppPreferences();
  const uniqueSources = new Map();
  inventory.forEach((item) => {
    uniqueSources.set(
      item.SourceName,
      (uniqueSources.get(item.SourceName) ?? 0) + 1
    );
  });

  const handleApplySourceFilter = (source: string) => {
    preferences.setPreference("lists.inventory.filters.includeSourceNames", [
      source,
    ]);
  };

  const handleApplyMakeFilter = (maker: string) => {
    preferences.setPreference("lists.inventory.filters.make", maker);
  };

  const handleApplyModelFilter = (model: string) => {
    preferences.setPreference("lists.inventory.filters.model", model);
  };

  const handleApplyYearFilter = (year: string) => {
    preferences.setPreference("lists.inventory.filters.year", year);
  };

    const handleApplyMinAgeFilter = (age: string) => {
      console.log(age)
        preferences.setPreference("lists.inventory.filters.minAge", age);
    };


    const handleApplyMaxAgeFilter = (age: string) => {
      preferences.setPreference("lists.inventory.filters.maxAge", age);
    }

  return (
    <GridToolbarContainer
      className="mb-2"
      style={{ padding: "8px", display: "block" }}
    >
      <div className="flex items-center mb-2">
        <div className='mr-auto'>
          <SidebarTrigger />
        </div>
        <SearchBar
          value={searchValue.value}
          onChange={(e) => searchValue.setValue(e.target.value)}
        />
        <div className="hidden lg:flex items-center gap-2">
          <GridToolbarExport
            slotProps={{
              button: {
                className:
                  "shadow-sm inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground h-9 p-2",
                style: {
                  color: "var(--text-primary)",
                  border: "1px solid rgb(226, 232, 240)",
                  borderRadius: "0.3rem",
                  padding: "0.34rem 0.5rem",
                  fontFamily: "inherit",
                  textTransform: "none",
                  marginLeft: "8px",
                },
              },
            }}
          />
        </div>
      </div>
      <div className="flex items-center mb-2 gap-4">
        {preferences.preferences.lists.inventory.filters.includeSourceNames?.map(
          (sourceName) => (
            <FilterBadge
              key={sourceName}
              label={`Source is ${sourceName}`}
              onClear={() =>
                preferences.setPreference(
                  "lists.inventory.filters.includeSourceNames",
                  []
                )
              }
            />
          )
        )}
        {preferences.preferences.lists.inventory.filters.make && (
          <FilterBadge
            label={`Make is ${preferences.preferences.lists.inventory.filters.make}`}
            onClear={() =>
              preferences.setPreference("lists.inventory.filters.make", "")
            }
          />
        )}
        {preferences.preferences.lists.inventory.filters.model && (
          <FilterBadge
            label={`Model is ${preferences.preferences.lists.inventory.filters.model}`}
            onClear={() =>
              preferences.setPreference("lists.inventory.filters.model", "")
            }
          />
        )}
        {preferences.preferences.lists.inventory.filters.year && (
            <FilterBadge
                label={`Year is ${preferences.preferences.lists.inventory.filters.year}`}
                onClear={() =>
                    preferences.setPreference("lists.inventory.filters.year", "")
                }
            />
        )}
        {preferences.preferences.lists.inventory.filters.minAge && (
            <FilterBadge
                label={`Days in inventory is more than ${preferences.preferences.lists.inventory.filters.minAge}`}
                onClear={() =>
                    preferences.setPreference("lists.inventory.filters.minAge", "")
                }
            />
        )}
        {preferences.preferences.lists.inventory.filters.maxAge && (
            <FilterBadge
                label={`Days in inventory is less than ${preferences.preferences.lists.inventory.filters.maxAge}`}
                onClear={() =>
                    preferences.setPreference("lists.inventory.filters.maxAge", "")
                }
            />
        )}
        <FilterMenu
          uniqueSources={uniqueSources}
          onApplySourceFilter={handleApplySourceFilter}
          onApplyMakeFilter={handleApplyMakeFilter}
          onApplyModelFilter={handleApplyModelFilter}
          onApplyYearFilter={handleApplyYearFilter}
            onApplyMinAgeFilter={handleApplyMinAgeFilter}
            onApplyMaxAgeFilter={handleApplyMaxAgeFilter}
        />
      </div>
    </GridToolbarContainer>
  );
}
