import LoginArt from "@/assets/images/login-art.jpg";
import LoginForm from "./components/login-form";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { TwoFactorOption } from "@/lib/api/auth/auth";
import TwoFactorOptionPicker from "./components/two-factor-option-picker";
import LoginWithOtp from "./components/login-with-otp";

enum AuthStep {
  GetOptions,
  PickMethod,
  EnterCode,
}

export function Login() {
  const [authStep, setAuthStep] = useState(AuthStep.GetOptions);
  const [twoFactorOptions, setTwoFactorOptions] = useState<TwoFactorOption[]>(
    []
  );
  const [selectedOption, setSelectedOption] = useState<TwoFactorOption | null>(
    null
  );
  const AuthStepContent = () => {
    if (authStep === AuthStep.GetOptions) {
      return (
        <LoginForm
          onSuccess={(options: TwoFactorOption[]) => {
            setTwoFactorOptions(options);
            setAuthStep(AuthStep.PickMethod);
          }}
          onCancel={() => {
            window.location.href = "/";
          }}
        />
      );
    }

    if (authStep === AuthStep.PickMethod) {
      return (
        <TwoFactorOptionPicker
          onSuccess={(option: TwoFactorOption) => {
            setSelectedOption(option);
            setAuthStep(AuthStep.EnterCode);
          }}
          onCancel={() => {
            setAuthStep(AuthStep.GetOptions);
          }}
          options={twoFactorOptions}
        />
      );
    }
    return (
      <LoginWithOtp
        selectedOption={selectedOption!}
        onSuccess={(token: string) => {
          localStorage.setItem("Authorization", token);
          window.location.href = "/dashboard";
        }}
        onCancel={() => {
          setAuthStep(AuthStep.PickMethod);
        }}
      />
    );
  };
  return (
    <div className="w-full lg:grid h-screen lg:grid-cols-2 ">
      <div className="flex items-center justify-center py-12 w-full lg:border-r">
        <div className="mx-auto grid w-[350px] gap-6">
          <motion.div className="grid gap-2 text-center" layout>
            <h1 className="text-3xl font-bold">Login</h1>
            <p className="text-balance text-muted-foreground">
              {selectedOption
                ? `Enter the code sent to your ${selectedOption.name.toLowerCase()}`
                : "Enter your email and password to login"}
            </p>
          </motion.div>
          <div className="grid gap-4">
            <AnimatePresence mode="wait">
              <AuthStepContent />
            </AnimatePresence>
          </div>
        </div>
      </div>
      <div className="hidden lg:flex bg-background items-center justify-center ">
        <img
          src={LoginArt}
          alt="Image"
          width="1920"
          height="1080"
          className="h-fit w-full object-cover"
        />
      </div>
    </div>
  );
}
