import useApi from "@/hooks/use-api";
import { createContext } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Company } from "../api/companies/companies";
import useLocalAppPreferences from "@/hooks/use-local-app-preferences";
import { useQuery } from "react-query";
import useUser from "@/hooks/use-user";
import {useLocation} from "react-router";
import routeDefinitions from "@/config/routing/route-definitions.ts";

export type CompaniesContextType = {
  loading: boolean;
  data: (Company & { isActive: boolean })[] | null;
};

export const CompaniesContext = createContext<CompaniesContextType>({
  loading: true,
  data: null,
});

type CompaniesContextProviderProps = {
  children: any;
};

export const CompaniesContextProvider = ({
  children,
}: CompaniesContextProviderProps) => {
  const api = useApi();
  const preferences = useLocalAppPreferences();
  const user = useUser();
  const activeCompanyIds = preferences.preferences.global.activeCompanyIds;
  const location = useLocation()
  const currentRoute = routeDefinitions.find(route => route.path === location.pathname)

  const { data, isLoading: loading } = useQuery({
    queryKey: ["companies", api.isAuthenticatedInstance, user],
    queryFn: async (): Promise<CompaniesContextType["data"] | null> => {
      const companies = await api.companies().getAllCompanies();
      return companies.map((company) => {
        return {
          ...company,
          isActive: activeCompanyIds.includes(company.CompanyId),
        };
      });
    },
    enabled: user.data !== null,
    refetchInterval: 1000 * 60 * 60,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  return (
    <CompaniesContext.Provider
      value={{
        loading,
        data: data ?? null,
      }}
    >
      <AnimatePresence mode="popLayout">
        {(loading && currentRoute?.properties.authentication !== 'public') ? (
          <motion.div
            key="loading"
            className="absolute inset-0 bg-secondary p-4 z-10"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1 }}
          >
            <div className="bg-background h-full w-full rounded-md flex items-center justify-center">
              <p>Loading your stores' data...</p>
            </div>
          </motion.div>
        ) : (
          <div key="stable">{children}</div>
        )}
      </AnimatePresence>
    </CompaniesContext.Provider>
  );
};
