import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { GridAddIcon } from "@mui/x-data-grid-pro";
import { FilterIcon } from "lucide-react";
import { useState } from "react";

type FilterMenuProps = {
  uniqueSources: Map<string, number>;
  onApplySourceFilter: (source: string) => void;
  onApplyMakeFilter: (maker: string) => void;
  onApplyModelFilter: (model: string) => void;
  onApplyYearFilter: (year: string) => void;
  onApplyMinAgeFilter: (year: string) => void;
  onApplyMaxAgeFilter: (year: string) => void;
};

export default function FilterMenu({
  uniqueSources,
  onApplySourceFilter,
  onApplyMakeFilter,
  onApplyModelFilter,
  onApplyYearFilter,
    onApplyMinAgeFilter,
    onApplyMaxAgeFilter,
}: FilterMenuProps) {
  const [selectedSource, setSelectedSource] = useState("");
  const [selectedMake, setSelectedMake] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
    const [selectedMinAge, setSelectedMinAge] = useState("");
    const [selectedMaxAge, setSelectedMaxAge] = useState("");

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Badge>
          <GridAddIcon className="w-4 fill-primary" />
          <FilterIcon className="w-4 fill-primary " />
          Add a filter
        </Badge>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuSub>
          <DropdownMenuSubTrigger>Source</DropdownMenuSubTrigger>
          <DropdownMenuSubContent className="min-w-64 p-4 ml-2">
            <DropdownMenuLabel>Source is</DropdownMenuLabel>
            <Select
              value={selectedSource}
              onValueChange={(newValue) => setSelectedSource(newValue)}
            >
              <SelectTrigger>
                <SelectValue placeholder="Source name..." />
              </SelectTrigger>
              <SelectContent>
                {Array.from(uniqueSources.keys())
                  .sort(
                    (a, b) =>
                      (uniqueSources.get(b) ?? 0) - (uniqueSources.get(a) ?? 0)
                  )
                  .map((sourceName) => (
                    <SelectItem key={sourceName} value={sourceName}>
                      {sourceName} ({uniqueSources.get(sourceName) ?? 0})
                    </SelectItem>
                  ))}
              </SelectContent>
            </Select>
            <div className="w-full flex items-center gap-2 mt-8">
              <Button variant="secondary" onClick={() => setSelectedSource("")}>
                Cancel
              </Button>
              <Button
                className="w-full"
                onClick={() => onApplySourceFilter(selectedSource)}
              >
                Apply filter
              </Button>
            </div>
          </DropdownMenuSubContent>
        </DropdownMenuSub>

        <DropdownMenuSub>
          <DropdownMenuSubTrigger>Make</DropdownMenuSubTrigger>
          <DropdownMenuSubContent className="min-w-64 p-4 ml-2">
            <DropdownMenuLabel>Maker is...</DropdownMenuLabel>
            <Input
              placeholder="Maker name..."
              value={selectedMake}
              onChange={(e) => setSelectedMake(e.target.value)}
            />
            <div className="w-full flex items-center gap-2 mt-8">
              <Button variant="secondary">Cancel</Button>
              <Button
                className="w-full"
                onClick={() => onApplyMakeFilter(selectedMake)}
              >
                Apply filter
              </Button>
            </div>
          </DropdownMenuSubContent>
        </DropdownMenuSub>

        <DropdownMenuSub>
          <DropdownMenuSubTrigger>Model</DropdownMenuSubTrigger>
          <DropdownMenuSubContent className="min-w-64 p-4 ml-2">
            <DropdownMenuLabel>Model is...</DropdownMenuLabel>
            <Input
              placeholder="Model name..."
              value={selectedModel}
              onChange={(e) => setSelectedModel(e.target.value)}
            />
            <div className="w-full flex items-center gap-2 mt-8">
              <Button variant="secondary">Cancel</Button>
              <Button
                className="w-full"
                onClick={() => onApplyModelFilter(selectedModel)}
              >
                Apply filter
              </Button>
            </div>
          </DropdownMenuSubContent>
        </DropdownMenuSub>

        <DropdownMenuSub>
          <DropdownMenuSubTrigger>Year</DropdownMenuSubTrigger>
          <DropdownMenuSubContent className="min-w-64 p-4 ml-2">
            <DropdownMenuLabel>Year of manufacture is...</DropdownMenuLabel>
            <Input
              placeholder="Year..."
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
            />
            <div className="w-full flex items-center gap-2 mt-8">
              <Button variant="secondary">Cancel</Button>
              <Button
                className="w-full"
                onClick={() => onApplyYearFilter(selectedYear)}
              >
                Apply filter
              </Button>
            </div>
          </DropdownMenuSubContent>
        </DropdownMenuSub>

        <DropdownMenuSub>
          <DropdownMenuSubTrigger>Minimum age</DropdownMenuSubTrigger>
          <DropdownMenuSubContent className="min-w-64 p-4 ml-2">
            <DropdownMenuLabel>Days in inventory is above...</DropdownMenuLabel>
            <Input
                placeholder="Age..."
                value={selectedMinAge}
                pattern={"[0-9]*"}
                onChange={(e) => setSelectedMinAge(e.target.value)}
            />
            <div className="w-full flex items-center gap-2 mt-8">
              <Button variant="secondary">Cancel</Button>
              <Button
                  className="w-full"
                  onClick={() => onApplyMinAgeFilter(selectedMinAge)}
              >
                Apply filter
              </Button>
            </div>
          </DropdownMenuSubContent>
        </DropdownMenuSub>

        <DropdownMenuSub>
          <DropdownMenuSubTrigger>Maximum age</DropdownMenuSubTrigger>
          <DropdownMenuSubContent className="min-w-64 p-4 ml-2">
            <DropdownMenuLabel>Days in inventory is below...</DropdownMenuLabel>
            <Input
                placeholder="Age..."
                value={selectedMaxAge}
                pattern={"[0-9]*"}
                onChange={(e) => setSelectedMaxAge(e.target.value)}
            />
            <div className="w-full flex items-center gap-2 mt-8">
              <Button variant="secondary">Cancel</Button>
              <Button
                  className="w-full"
                  onClick={() => onApplyMaxAgeFilter(selectedMaxAge)}
              >
                Apply filter
              </Button>
            </div>
          </DropdownMenuSubContent>
        </DropdownMenuSub>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
