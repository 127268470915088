export function getValidationRuleColors() {
  const colors = [
    {
      name: "black",
      color: "rgb(0, 0, 0)",
    },
    {
      name: "dark gray 4",
      color: "rgb(67, 67, 67)",
    },
    {
      name: "dark gray 3",
      color: "rgb(102, 102, 102)",
    },
    {
      name: "dark gray 2",
      color: "rgb(153, 153, 153)",
    },
    {
      name: "dark gray 1",
      color: "rgb(183, 183, 183)",
    },
    {
      name: "gray",
      color: "rgb(204, 204, 204)",
    },
    {
      name: "light gray 1",
      color: "rgb(217, 217, 217)",
    },
    {
      name: "light gray 2",
      color: "rgb(239, 239, 239)",
    },
    {
      name: "light gray 3",
      color: "rgb(243, 243, 243)",
    },
    {
      name: "white",
      color: "rgb(255, 255, 255)",
    },
    {
      name: "red berry",
      color: "rgb(152, 0, 0)",
    },
    {
      name: "red",
      color: "rgb(255, 0, 0)",
    },
    {
      name: "orange",
      color: "rgb(255, 153, 0)",
    },
    {
      name: "yellow",
      color: "rgb(255, 255, 0)",
    },
    {
      name: "green",
      color: "rgb(0, 255, 0)",
    },
    {
      name: "cyan",
      color: "rgb(0, 255, 255)",
    },
    {
      name: "cornflower blue",
      color: "rgb(74, 134, 232)",
    },
    {
      name: "blue",
      color: "rgb(0, 0, 255)",
    },
    {
      name: "purple",
      color: "rgb(153, 0, 255)",
    },
    {
      name: "magenta",
      color: "rgb(255, 0, 255)",
    },
    {
      name: "light red berry 3",
      color: "rgb(230, 184, 175)",
    },
    {
      name: "light red 3",
      color: "rgb(244, 204, 204)",
    },
    {
      name: "light orange 3",
      color: "rgb(252, 229, 205)",
    },
    {
      name: "light yellow 3",
      color: "rgb(255, 242, 204)",
    },
    {
      name: "light green 3",
      color: "rgb(217, 234, 211)",
    },
    {
      name: "light cyan 3",
      color: "rgb(208, 224, 227)",
    },
    {
      name: "light cornflower blue 3",
      color: "rgb(201, 218, 248)",
    },
    {
      name: "light blue 3",
      color: "rgb(207, 226, 243)",
    },
    {
      name: "light purple 3",
      color: "rgb(217, 210, 233)",
    },
    {
      name: "light magenta 3",
      color: "rgb(234, 209, 220)",
    },
    {
      name: "light red berry 2",
      color: "rgb(221, 126, 107)",
    },
    {
      name: "light red 2",
      color: "rgb(234, 153, 153)",
    },
    {
      name: "light orange 2",
      color: "rgb(249, 203, 156)",
    },
    {
      name: "light yellow 2",
      color: "rgb(255, 229, 153)",
    },
    {
      name: "light green 2",
      color: "rgb(182, 215, 168)",
    },
    {
      name: "light cyan 2",
      color: "rgb(162, 196, 201)",
    },
    {
      name: "light cornflower blue 2",
      color: "rgb(164, 194, 244)",
    },
    {
      name: "light blue 2",
      color: "rgb(159, 197, 232)",
    },
    {
      name: "light purple 2",
      color: "rgb(180, 167, 214)",
    },
    {
      name: "light magenta 2",
      color: "rgb(213, 166, 189)",
    },
    {
      name: "light red berry 1",
      color: "rgb(204, 65, 37)",
    },
    {
      name: "light red 1",
      color: "rgb(224, 102, 102)",
    },
    {
      name: "light orange 1",
      color: "rgb(246, 178, 107)",
    },
    {
      name: "light yellow 1",
      color: "rgb(255, 217, 102)",
    },
    {
      name: "light green 1",
      color: "rgb(147, 196, 125)",
    },
    {
      name: "light cyan 1",
      color: "rgb(118, 165, 175)",
    },
    {
      name: "light cornflower blue 1",
      color: "rgb(109, 158, 235)",
    },
    {
      name: "light blue 1",
      color: "rgb(111, 168, 220)",
    },
    {
      name: "light purple 1",
      color: "rgb(142, 124, 195)",
    },
    {
      name: "light magenta 1",
      color: "rgb(194, 123, 160)",
    },
    {
      name: "dark red berry 1",
      color: "rgb(166, 28, 0)",
    },
    {
      name: "dark red 1",
      color: "rgb(204, 0, 0)",
    },
    {
      name: "dark orange 1",
      color: "rgb(230, 145, 56)",
    },
    {
      name: "dark yellow 1",
      color: "rgb(241, 194, 50)",
    },
    {
      name: "dark green 1",
      color: "rgb(106, 168, 79)",
    },
    {
      name: "dark cyan 1",
      color: "rgb(69, 129, 142)",
    },
    {
      name: "dark cornflower blue 1",
      color: "rgb(60, 120, 216)",
    },
    {
      name: "dark blue 1",
      color: "rgb(61, 133, 198)",
    },
    {
      name: "dark purple 1",
      color: "rgb(103, 78, 167)",
    },
    {
      name: "dark magenta 1",
      color: "rgb(166, 77, 121)",
    },
    {
      name: "dark red berry 2",
      color: "rgb(133, 32, 12)",
    },
    {
      name: "dark red 2",
      color: "rgb(153, 0, 0)",
    },
    {
      name: "dark orange 2",
      color: "rgb(180, 95, 6)",
    },
    {
      name: "dark yellow 2",
      color: "rgb(191, 144, 0)",
    },
    {
      name: "dark green 2",
      color: "rgb(56, 118, 29)",
    },
    {
      name: "dark cyan 2",
      color: "rgb(19, 79, 92)",
    },
    {
      name: "dark cornflower blue 2",
      color: "rgb(17, 85, 204)",
    },
    {
      name: "dark blue 2",
      color: "rgb(11, 83, 148)",
    },
    {
      name: "dark purple 2",
      color: "rgb(53, 28, 117)",
    },
    {
      name: "dark magenta 2",
      color: "rgb(116, 27, 71)",
    },
    {
      name: "dark red berry 3",
      color: "rgb(91, 15, 0)",
    },
    {
      name: "dark red 3",
      color: "rgb(102, 0, 0)",
    },
    {
      name: "dark orange 3",
      color: "rgb(120, 63, 4)",
    },
    {
      name: "dark yellow 3",
      color: "rgb(127, 96, 0)",
    },
    {
      name: "dark green 3",
      color: "rgb(39, 78, 19)",
    },
    {
      name: "dark cyan 3",
      color: "rgb(12, 52, 61)",
    },
    {
      name: "dark cornflower blue 3",
      color: "rgb(28, 69, 135)",
    },
    {
      name: "dark blue 3",
      color: "rgb(7, 55, 99)",
    },
    {
      name: "dark purple 3",
      color: "rgb(32, 18, 77)",
    },
    {
      name: "dark magenta 3",
      color: "rgb(76, 17, 48)",
    },
    {
      name: "custom pink 1",
      color: "rgb(246, 174, 255)",
    },
  ];
  return colors;
}
