import { GridCellParams } from "@mui/x-data-grid-pro";
import { useCallback } from "react";
import useSalesListContext from "./use-sales-list-context";

export default function useCellClassnameCallback(
  listContext: ReturnType<typeof useSalesListContext>
) {
  const getCellClassName = useCallback(
    (params: GridCellParams) => {
      if (params.field === "Gross") {
        const targetMargin =
          listContext.targetMarginsMap.get(params.row.CompanyId) ?? 1500;
        let actualGross = params.row.Gross;
        if (actualGross > targetMargin) return "text-green-500";
        else if (actualGross > 0) return "text-yellow-500";
        else return "text-red-600";
      } else if (params.row.Gross === "N/A") return "text-black/50";
      return "";
    },
    [listContext.targetMarginsMap]
  );
  return getCellClassName;
}
