import React from "react";
import {Routes as RouterRoutes, Navigate, Route, useSearchParams} from "react-router-dom";
import routeDefinitions from "./route-definitions";
import useUser from "@/hooks/use-user";
import { RouteDefinition } from "./route-definitions.d";

export default function RoutedComponents() {
  document.title =
    "Max Autolytics | " +
      routeDefinitions.find((route) => route.path === window.location.pathname)
        ?.properties.navigation.title || "Main";

  return (
    <RouterRoutes>
      {routeDefinitions.map((route: RouteDefinition) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <RouteElement authType={route.properties.authentication}>
              <route.component />
            </RouteElement>
          }
        />
      ))}
    </RouterRoutes>
  );
}

type RouteElementProps = {
  authType: RouteDefinition["properties"]["authentication"];
  children: React.ReactNode;
};

function RouteElement({ authType, children }: RouteElementProps) {
  if (authType === "private")
    return <ProtectedRoute>{children}</ProtectedRoute>;
  if (authType === "unauthenticated-only")
    return <UnprotectedRouteStrict>{children}</UnprotectedRouteStrict>;
  return <>{children}</>;
}

function ProtectedRoute({ children }: Omit<RouteElementProps, "authType">) {
  const user = useUser();
  const isAuthenticated = user.data !== null;

  if (isAuthenticated) return children;
  const redirect = window.location.pathname;
  return <Navigate to={`/login?redirect=${redirect}`} />;
}

function UnprotectedRouteStrict({
  children,
}: Omit<RouteElementProps, "authType">) {
  const user = useUser();
  const urlParams = useSearchParams()
  const isAuthenticated = user.data !== null;

  if (!isAuthenticated) return children;
    const redirect = urlParams[0].get("redirect") || "/dashboard";
  return <Navigate to={redirect} />;
}
