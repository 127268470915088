import useApi from "@/hooks/use-api";
import useCompanies from "@/hooks/use-companies";
import useLocalAppPreferences from "@/hooks/use-local-app-preferences";
import { Company } from "@/lib/api/companies/companies";
import { CompaniesContextType } from "@/lib/providers/companies-provider";
import { useMemo } from "react";

export default function useInventoryListContext() {
  const api = useApi();
  const companies = useCompanies();
  const preferences = useLocalAppPreferences();
  const {
    global: { activeCompanyIds },
    lists: {
      inventory: { filters: inventoryFilters },
    },
  } = preferences.preferences;

  const { activeCompanies, targetMarginsMap } = useMemo(() => {
    const activeCompanies = getActiveCompaniesArray(
      companies.data,
      activeCompanyIds
    );
    const targetMarginsMap = getTargetMarginsMap(activeCompanies);
    return { activeCompanies, targetMarginsMap };
  }, [companies.data, activeCompanyIds]);

  return {
    api,
    companies,
    preferences,
    activeCompanyIds,
    inventoryFilters,
    activeCompanies,
    targetMarginsMap,
  };
}

function getActiveCompaniesArray(
  companies: CompaniesContextType["data"],
  activeCompanyIds: number[]
) {
  return (
    companies?.filter((company) =>
      activeCompanyIds.includes(company.CompanyId)
    ) ?? []
  );
}

function getTargetMarginsMap(activeCompanies: Company[]) {
  return new Map(
    activeCompanies?.map((company) => [
      company.CompanyId,
      company.TargetMargin,
    ]) ?? []
  );
}
