import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { AutomationMapping } from "@/lib/api/automation-preferences/automation-preferences";
import MappingTable from "./automation-mapping-table";
import AddMappingDialog from "./add-mapping-dialog";
import useAutomationCrud from "../../hooks/automation-settings/use-automation-crud";

type EditAutomationMappingsProps = {
  companyId: number;
  preferenceId: number;
  mappings: AutomationMapping[];
};

export default function EditAutomationMappings({
  companyId,
  preferenceId,
  mappings,
}: EditAutomationMappingsProps) {
  const { mapping } = useAutomationCrud(companyId);

  return (
    <Card>
      <CardHeader>
        <CardTitle>Edit column mappings</CardTitle>
      </CardHeader>
      <CardContent>
        <MappingTable
          mappings={mappings}
          deleteMapping={mapping.delete.mutate}
          preferenceId={preferenceId}
          loading={mapping.delete.loading}
        />
        <AddMappingDialog
          addMapping={mapping.add.mutate}
          loading={mapping.add.loading}
          preferenceId={preferenceId}
        />
      </CardContent>
    </Card>
  );
}
