import useInventoryDashboardData from "@/pages/dashboard/hooks/use-inventory-dashboard-data.tsx";
import {Card, CardContent, CardHeader, CardTitle} from "@/components/ui/card.tsx";
import useLocalAppPreferences from "@/hooks/use-local-app-preferences.tsx";
import {proper} from "@/lib/utils.ts";
import OverviewMetricsCards from "@/pages/dashboard/components/inventory-breakdown.tab/overview-metrics-cards.tsx";
import CargurusAtAGlanceChart from "@/pages/dashboard/components/inventory-breakdown.tab/cargurus-at-a-glance-chart.tsx";
import CargurusAtAGlanceTable from "@/pages/dashboard/components/inventory-breakdown.tab/cargurus-at-a-glance-table.tsx";
import InventoryByAgeChart from "@/pages/dashboard/components/inventory-breakdown.tab/inventory-by-age-chart.tsx";
import InventoryByAgeTable from "@/pages/dashboard/components/inventory-breakdown.tab/inventory-by-age-table.tsx";
import InventorySourceTable from "@/pages/dashboard/components/inventory-breakdown.tab/inventory-by-source-table.tsx";

export default function InventoryBreakdownTab() {
    const {data, loading} = useInventoryDashboardData()
    const preferences = useLocalAppPreferences();
    const dateRangeString =
        typeof preferences.preferences.global.dateRange === "string"
            ? proper(
                preferences.preferences.global.dateRange,
                /(?<=[a-z])(?=[A-Z])|(?<=[A-Za-z])(?=\d)|(?<=\d)(?=[A-Za-z])/g
            )
            : `${preferences.preferences.global.dateRange.startDate} thru ${preferences.preferences.global.dateRange.endDate}`;
    const companiesCount = preferences.preferences.global.activeCompanyIds.length

    return (
        <div className="container flex flex-col gap-4 max-w-[76rem] pb-32">
            <Card>
                <CardHeader>
                    <CardTitle>Sales Overview</CardTitle>
                    <h2 className="opacity-50">All sales data {dateRangeString}</h2>
                </CardHeader>
                <CardContent>
                    <OverviewMetricsCards data={data} loading={loading} storeCount={companiesCount} />
                </CardContent>
            </Card>
            <Card>
                <CardHeader>
                    <CardTitle>Cargurus at a glance</CardTitle>
                    <h2 className="opacity-50">How you&apos;re pricing in your market</h2>
                </CardHeader>
                <CardContent className='w-full flex items-center justify-center gap-4'>
                    <CargurusAtAGlanceChart data={data} loading={loading} />
                    <CargurusAtAGlanceTable data={data} loading={loading} storeCount={companiesCount} />
                </CardContent>
            </Card>
            <Card>
                <CardHeader>
                    <CardTitle>Inventory by age</CardTitle>
                    <h2 className="opacity-50">How old your inventory is</h2>
                </CardHeader>
                <CardContent className='w-full flex items-center gap-4 justify-center'>
                    <InventoryByAgeChart data={data} loading={loading} />
                    <InventoryByAgeTable data={data} loading={loading} storeCount={companiesCount} />
                </CardContent>
            </Card>
            <Card>
                <CardHeader>
                    <CardTitle>Inventory by source</CardTitle>
                    <h2 className="opacity-50">Where you are sourcing your vehicles from</h2>
                </CardHeader>
                <CardContent className='w-full flex items-center gap-4'>
                    <InventorySourceTable data={data} />
                </CardContent>
            </Card>
        </div>
    );
}
