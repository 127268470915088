import useApi from "@/hooks/use-api";
import { createContext } from "react";
import { User } from "../api/users/users";
import { AnimatePresence, motion } from "framer-motion";
import * as Sentry from "@sentry/react";
import { useQuery } from "react-query";
import {useLocation} from "react-router";
import routeDefinitions from "@/config/routing/route-definitions.ts";

export type UserContextType = {
  loading: boolean;
  data: User | null;
};

export const UserContext = createContext<UserContextType>({
  loading: true,
  data: null,
});

type UserContextProviderProps = {
  children: any;
};

export const UserContextProvider = ({ children }: UserContextProviderProps) => {
  const api = useApi();
  const location = useLocation()
  const currentRoute = routeDefinitions.find(route => route.path === location.pathname)

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["user", api.isAuthenticatedInstance],
    queryFn: async () => {
      const user = await api.auth().getCurrentUser();
      Sentry.setUser(user);
      return user;
    },
    initialData: null,
    refetchInterval: 1000 * 60 * 60,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const loading = isLoading || isFetching;
  return (
    <UserContext.Provider
      value={{
        loading,
        data: data ?? null,
      }}
    >
      <AnimatePresence mode="popLayout">
        {(loading && currentRoute?.properties.authentication !== 'public') ? (
          <motion.div
            key="loading"
            className="absolute inset-0 bg-secondary p-4 z-10"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1 }}
          >
            <div className="bg-background h-full w-full rounded-md flex items-center justify-center">
              <p>Loading your user data...</p>
            </div>
          </motion.div>
        ) : (
          <div key="stable">{children}</div>
        )}
      </AnimatePresence>
    </UserContext.Provider>
  );
};
