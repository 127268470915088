import { AxiosInstance } from "axios";
import { GetTwoFactorMethodsResponse } from "./auth";
import { User } from "../users/users";

export class Auth {
  private readonly instance: AxiosInstance;

  /**
   * Creates an instance of the Auth class.
   * @param {AxiosInstance} instance - The Axios instance to use for HTTP requests.
   */
  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  /**
   * Retrieves the two-factor authentication methods enabled for the user.
   * @param {string} emailAddress - The user's email address.
   * @param {string} password - The user's password.
   * @returns {Promise<GetTwoFactorMethodsResponse>} A promise that resolves to the available 2FA methods.
   */

  public async getTwoFactorMethods(
    emailAddress: string,
    password: string
  ): Promise<GetTwoFactorMethodsResponse> {
    const { data } = await this.instance.get("/auth", {
      params: {
        "email-address": emailAddress,
        password: password,
      },
    });
    return data;
  }

  /**
   * Sends an SMS OTP to the user's verified phone number.
   * @param {string} preAuthToken - The pre-authentication token obtained from the server.
   * @returns {Promise<any>} A promise that resolves when the OTP has been sent.
   */

  public async sendSmsOtp(preAuthToken: string) {
    const { data } = await this.instance.post("/auth/otp/sms", {
      PreAuthToken: preAuthToken,
    });
    return data;
  }

  /**
   * Sends an email OTP to the user's email address.
   * @param {string} preAuthToken - The pre-authentication token obtained from the server.
   * @returns {Promise<any>} A promise that resolves when the OTP has been sent.
   */

  public async sendEmailOtp(preAuthToken: string) {
    const { data } = await this.instance.post("/auth/otp/email", {
      PreAuthToken: preAuthToken,
    });
    return data;
  }

  /**
   * Logs in the user using a phone OTP.
   * @param {string} preAuthToken - The pre-authentication token obtained from the server.
   * @param {string} otp - The one-time passcode sent to the user's phone.
   * @returns {Promise<string>} A promise that resolves to the authentication data.
   */

  public async loginWithPhoneOtp(preAuthToken: string, otp: string) {
    const response = await this.instance.post(`/auth/otp/sms/${otp}`, {
      PreAuthToken: preAuthToken,
    });
    let token = null;
    if (response.headers.authorization) {
      token = response.headers.authorization;
    }
    return token;
  }

  /**
   * Logs in the user using an email OTP.
   * @param {string} preAuthToken - The pre-authentication token obtained from the server.
   * @param {string} otp - The one-time passcode sent to the user's email.
   * @returns {Promise<string>} A promise that resolves to the authentication data.
   */

  public async loginWithEmailOtp(preAuthToken: string, otp: string) {
    const response = await this.instance.post(`/auth/otp/email/${otp}`, {
      PreAuthToken: preAuthToken,
    });
    let token = null;
    if (response.headers.authorization) {
      token = response.headers.authorization;
    }
    return token;
  }

  /**
   *
   * @returns {Promise<User>} A promise that resolves when the OTP has been sent.
   */
  public async getCurrentUser() {
    const token = localStorage.getItem("Authorization");
    if (!token) {
      return null;
    }
    const { data: user } = await this.instance.get("/auth/from-token", {
      headers: {
        Authorization: token,
      },
    });
    return user as User;
  }

  /**
   * Initiates verification of a new phone number by sending an OTP.
   * @param {string} phoneNumber - The new phone number to verify.
   * @returns {Promise<any>} A promise that resolves when the OTP has been sent.
   */

  public async verifyNewPhoneNumber(phoneNumber: string) {
    const { data } = await this.instance.post(
      `/auth/verify-phone/${phoneNumber}`
    );
    return data;
  }

  /**
   * Verifies the new phone number using the received OTP.
   * @param {string} phoneNumber - The new phone number to verify.
   * @param {string} otp - The one-time passcode sent to the new phone number.
   * @returns {Promise<any>} A promise that resolves when the phone number has been verified.
   */

  public async verifyNewPhoneNumberOTP(phoneNumber: string, otp: string) {
    const { data } = await this.instance.post(
      `/auth/verify-phone/${phoneNumber}/${otp}`
    );
    return data;
  }

  public getSchemas() {
    return {};
  }
}
