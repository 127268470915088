import {SalesOverview} from "@/lib/api/analytics/analytics";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "@/components/ui/table.tsx";
import {cn} from "@/lib/utils.ts";
import {TrendingDown, TrendingUp, UndoIcon} from "lucide-react";
import LinkWithQuery from "@/components/link-with-query.tsx";
import useLocalAppPreferences from "@/hooks/use-local-app-preferences.tsx";
import {toast} from "sonner";
import {useNavigate} from "react-router";
import {useMemo} from "react";

type SalesBySourceTableProps = {
    data: SalesOverview[] | undefined
}

export default function SalesBySourceTable({ data }: SalesBySourceTableProps) {
    const VOLUME_INDICATOR_THRESHOLD = 0.7
    const GROSS_INDICATOR_THRESHOLD = 0.8
    const DAYS_TO_SELL_INDICATOR_THRESHOLD = 1

    const Indicator = ({ value, indicatorType }: { value: number, indicatorType: 'volume' | 'gross' | 'daysToSell' }) => {
        const threshold = indicatorType === 'volume' ? VOLUME_INDICATOR_THRESHOLD : indicatorType === 'gross' ? GROSS_INDICATOR_THRESHOLD : DAYS_TO_SELL_INDICATOR_THRESHOLD
        if (Math.abs(value) < threshold) return <></>
        let color = 'text-green-600'
        if(indicatorType === 'daysToSell'){
            if(value > 0) color = 'text-red-600'
        } else {
            if(value < 0) color = 'text-red-600'
        }
        return (
            <div className={cn(color
            )}>{
                value > 0 ? <TrendingUp className={'w-4 h-4'} /> : <TrendingDown className={'w-4 h-4'} />
            }</div>
        )
    }

    const preferences = useLocalAppPreferences()
    const navigate = useNavigate()

    const combinedSourceNames = useMemo(() => {
        if(!data) return []
        const sourceNames = new Set<string>()
        data.forEach((store) => {
            store.salesBySourceData?.forEach((source) => {
                sourceNames.add(source.sourceName)
            })
        })
        return Array.from(sourceNames)
    }, [data])

    return (
        <Table className="w-full whitespace-nowrap">
            <TableHeader className={'border-b'}>
                <TableHead>Source</TableHead>
                <TableHead>Store</TableHead>
                <TableHead>Total units</TableHead>
                <TableHead>Avg PVR</TableHead>
                <TableHead>Avg age</TableHead>
            </TableHeader>
            <TableBody>
                {combinedSourceNames.map((item, parentIndex) => {
                    return (
                        <TableRow key={parentIndex}>
                            <TableCell>
                                <LinkWithQuery to={'/sales'} onClick={() => {
                                    const currentFilters = preferences.preferences.lists.sales.filters.includeSourceNames ?? []
                                    preferences.setPreference('lists.sales.filters.includeSourceNames', [item])
                                    toast.info('Filtering by source ' + item, {
                                        action: {
                                            label: <span className="flex items-center gap-2">
                                                      Undo <UndoIcon size={16} />
                                                    </span>,
                                            onClick: () => {
                                                preferences.setPreference('lists.sales.filters.includeSourceNames', currentFilters)
                                                navigate('/dashboard')
                                            }
                                        }
                                    })
                                }} className={'hover:underline'}>
                                    <div
                                        className="w-2 h-2 inline-block rounded-full mr-1 -translate-y-0.5"
                                    ></div>
                                    {item}
                                </LinkWithQuery>
                            </TableCell>
                            <TableCell>
                                <Table>
                                    <TableBody>
                                        {data?.map((storeData, index) => (
                                            <TableRow key={index}>
                                                <TableCell className={'flex items-center gap-2'}>
                                                    <LinkWithQuery to={'/sales'} onClick={() => {
                                                        const currentFilters = preferences.preferences.lists.sales.filters.includeSourceNames ?? []
                                                        const currentCompanyIds = preferences.preferences.global.activeCompanyIds ?? []
                                                        preferences.setPreference('lists.sales.filters.includeSourceNames', [item])
                                                        preferences.setPreference('global.activeCompanyIds', [storeData.companyId])
                                                        toast.info('Changed active store to ' + storeData.companyName, {
                                                            action: {
                                                                label: <span className="flex items-center gap-2">
                                                      Undo <UndoIcon size={16} />
                                                    </span>,
                                                                onClick: () => {
                                                                    preferences.setPreference('lists.sales.filters.includeSourceNames', currentFilters)
                                                                    preferences.setPreference('global.activeCompanyIds', currentCompanyIds)
                                                                    navigate('/dashboard')
                                                                }
                                                            }
                                                        })
                                                    }} className={'hover:underline'}>
                                                    <div className='w-full max-w-24 whitespace-nowrap overflow-hidden text-ellipsis'> {storeData.companyName}</div>
                                                    </LinkWithQuery>
                                                    </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableCell>
                            <TableCell>
                                <Table>
                                    <TableBody>
                                        {data?.map((storeData, index) => (
                                            <TableRow key={index}>
                                                <TableCell className={'flex items-center gap-2'}>
                                                    {storeData?.salesBySourceData?.find(e => e.sourceName === item)?.totalSales ?? 0} (
                                                    {storeData?.salesBySourceData?.find(e => e.sourceName === item)?.sourcePercentOfTotal ?? 0}%)
                                                    {storeData?.salesBySourceData?.find(e => e.sourceName === item)?.volumeIndicator && (
                                                        <Indicator indicatorType={'volume'} value={storeData?.salesBySourceData?.find(e => e.sourceName === item)?.volumeIndicator ?? 0} />
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableCell>
                            <TableCell>
                                <Table>
                                    <TableBody>
                                        {data?.map((storeData, index) => (
                                            <TableRow key={index}>
                                                <TableCell className={'flex items-center gap-2'}>
                                                    $
                                                    {storeData?.salesBySourceData?.find(e => e.sourceName === item)?.averageGrossPerSale?.toLocaleString() ?? 0}
                                                    {storeData?.salesBySourceData?.find(e => e.sourceName === item)?.grossIndicator && (
                                                        <Indicator indicatorType={'gross'} value={storeData?.salesBySourceData?.find(e => e.sourceName === item)?.grossIndicator ?? 0} />
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableCell>
                            <TableCell>
                                <Table>
                                    <TableBody>
                                        {data?.map((storeData, index) => (
                                            <TableRow key={index}>
                                                <TableCell className={'flex items-center gap-2'}>
                                                    {storeData?.salesBySourceData?.find(e => e.sourceName === item)?.averageDaysToSell?.toLocaleString() ?? 0} days
                                                    {storeData?.salesBySourceData?.find(e => e.sourceName === item)?.daysToSellIndicator && (
                                                        <Indicator indicatorType={'daysToSell'} value={storeData?.salesBySourceData?.find(e => e.sourceName === item)?.daysToSellIndicator ?? 0} />
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    )
}