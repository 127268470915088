import { z } from "zod";

export const CreateSaleSchema = z
  .object({
    IsDraft: z.literal(true).or(z.literal(false)),
    IsRollback: z.literal(true).or(z.literal(false)),
    Notes: z.string().max(128).nullable().optional(),
    InitialACV: z.number().int().positive().optional(),
    DaysInInventory: z.number().int().nonnegative().optional(),
    FinalACV: z.number().int().positive().optional(),
    FinalCarGurusChoice: z
      .union([
        z.literal(0),
        z.literal(1),
        z.literal(2),
        z.literal(3),
        z.literal(4),
      ])
      .optional(),
    FinalCarGurusGreatPrice: z.number().int().nonnegative().optional(),
    FinalCarGurusGoodPrice: z.number().int().nonnegative().optional(),
    FinalCarGurusFairPrice: z.number().int().nonnegative().optional(),
    FinalCarGurusHighPrice: z.number().int().nonnegative().optional(),
    FinalCarGurusOverPrice: z.number().int().nonnegative().optional(),
    FinalManheimWholesale: z.number().int().positive().optional(),
    FinalInstantMarketValue: z.number().int().positive().optional(),
    InitialCarGurusChoice: z
      .union([
        z.literal(0),
        z.literal(1),
        z.literal(2),
        z.literal(3),
        z.literal(4),
      ])
      .optional(),
    InitialCarGurusGreatPrice: z.number().int().nonnegative().optional(),
    InitialCarGurusGoodPrice: z.number().int().nonnegative().optional(),
    InitialCarGurusFairPrice: z.number().int().nonnegative().optional(),
    InitialCarGurusHighPrice: z.number().int().nonnegative().optional(),
    InitialCarGurusOverPrice: z.number().int().nonnegative().optional(),
    IsCertified: z.literal(true).or(z.literal(false)),
    Gross: z.number().int().nonnegative().optional(),
    PercentOfMarket: z.number().int().min(0).max(100).optional(),
    Odometer: z.number().int().nonnegative().optional(),
    MSRP: z.number().int().positive().optional(),
    SellPrice: z.number().int().positive().optional(),
    VehicleSourceId: z.number().int().positive(),
    InitialAskingPrice: z.number().int().positive().optional(),
    StockNumber: z.string().min(1).max(16).optional(),
    Make: z.string().min(1).max(32).optional(),
    Model: z.string().min(1).max(32).optional(),
    ModelYear: z
      .number()
      .int()
      .min(1960)
      .max(new Date().getFullYear())
      .optional(),
    Vin: z.string().length(17).optional(),
    VehicleTrimLevel: z.string().min(1).max(32).optional(),
    InitialManheimWholesale: z.number().int().positive().optional(),
    SoldAt: z.string().datetime(),
    TookATrade: z.literal(true).or(z.literal(false)),
    RollbackDate: z.string().datetime().optional(),
    CostToMarket: z.number().int().nonnegative().optional(),
    AppraisedValue: z.number().int().nonnegative().optional(),
    Type: z.string().min(1).max(64).optional(),
  })
  .refine(
    (data) => data.IsRollback === false || data.RollbackDate !== undefined,
    {
      message: "RollbackDate is required when the sale is rolled back",
      path: ["RollbackDate", "IsRollback"],
    }
  );
