import { Alert } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import useApi from "@/hooks/use-api";
import { motion } from "framer-motion";
import { FormEvent, useState } from "react";
import { Link } from "react-router-dom";

type LoginFormProps = {
  onSuccess: (options: any[]) => void;
  onCancel: () => void;
};

export default function LoginForm({ onSuccess, onCancel }: LoginFormProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const api = useApi();

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    setLoading(true);
    setError("");
    try {
      event.preventDefault();
      const email = (event.target as any).email.value;
      const password = (event.target as any).password.value;
      const twoFactorOptions = await api
        .auth()
        .getTwoFactorMethods(email, password);
      onSuccess(twoFactorOptions);
      setError("");
    } catch (e: any) {
      console.error(e);
      setError(e.response?.data?.message ?? e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <motion.form
      key="login"
      className="grid gap-4"
      onSubmit={onSubmit}
      initial={{
        opacity: 0,
        translateY: 100,
      }}
      animate={{
        opacity: 100,
        translateY: 0,
      }}
      exit={{
        opacity: 0,
        translateY: -100,
      }}
    >
      {error && <Alert variant="destructive">{error}</Alert>}
      <div className="grid gap-2">
        <Label htmlFor="email">Email</Label>
        <Input id="email" type="email" placeholder="m@example.com" required />
      </div>
      <div className="grid gap-2">
        <div className="flex items-center">
          <Label htmlFor="password">Password</Label>
          <Link
            to="/forgot-password"
            className="ml-auto inline-block text-sm underline"
          >
            Forgot your password?
          </Link>
        </div>
        <Input
          id="password"
          type="password"
          placeholder="**************"
          required
        />
      </div>
      <Button name="submit" loading={loading} type="submit" className="w-full">
        Login
      </Button>
      <Button onClick={onCancel} className="w-full" variant="outline">
        Go back
      </Button>
    </motion.form>
  );
}
