import { Separator } from "@radix-ui/react-separator";
import { SidebarTrigger } from "@/components/ui/sidebar";
import DateRangeSelector from "@/features/date-range-selector";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import useLocalAppPreferences from "@/hooks/use-local-app-preferences";
import { useCallback } from "react";
import SalesBreakdownTab from "./components/sales-breakdown.tab";
import InventoryBreakdownTab from "@/pages/dashboard/components/inventory-breakdown.tab";
export default function Dashboard() {
  const preferences = useLocalAppPreferences();
  const tab = preferences.preferences.dashboard.activeTab;
  const setTab = useCallback(
    (value: typeof tab) => {
      preferences.setPreference("dashboard.activeTab", value);
    },
    [preferences]
  );
  return (
    <div className="w-full">
      <Tabs
        value={tab}
        onValueChange={(newTab) => setTab(newTab as typeof tab)}
      >
        <header className="border-b bg-background flex h-16 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12 sticky top-0 z-10 rounded-t-xl">
          <div className="flex items-center gap-2 px-4 w-full">
            <SidebarTrigger className="-ml-1" />
            <Separator
              orientation="vertical"
              className="mr-2 h-4 w-px bg-border"
            />
            <DateRangeSelector />
            <TabsList className="ml-auto  rounded-b-none p-0 h-fit -mb-9 bg-transparent group-has-[[data-collapsible=icon]]/sidebar-wrapper:-mb-5 transition-all duration-300">
              <TabsTrigger
                value="general-overview"
                className="rounded-b-none border shadow-none data-[state=active]:shadow-none data-[state=inactive]:bg-muted"
              >
                General overview
              </TabsTrigger>
              <TabsTrigger
                value="sales-breakdown"
                className="rounded-b-none border shadow-none data-[state=active]:shadow-none data-[state=inactive]:bg-muted"
              >
                Sales breakdown
              </TabsTrigger>
              <TabsTrigger
                value="inventory-breakdown"
                className="rounded-b-none border shadow-none data-[state=active]:shadow-none data-[state=inactive]:bg-muted"
              >
                Inventory breakdown
              </TabsTrigger>
              <TabsTrigger
                value="inventory-footprint"
                className="rounded-b-none border shadow-none data-[state=active]:shadow-none data-[state=inactive]:bg-muted"
              >
                Inventory footprint
              </TabsTrigger>
            </TabsList>
          </div>
        </header>
        <TabsContent value="sales-breakdown">
          <SalesBreakdownTab />
        </TabsContent>
        <TabsContent value="inventory-breakdown">
          <InventoryBreakdownTab />
        </TabsContent>
      </Tabs>
    </div>
  );
}
