import {AppSidebar} from "./layouts/sidebar/app-sidebar";
import {MaxAutolyticsApiProvider} from "./lib/providers/api-provider";
import {UserContextProvider} from "./lib/providers/user-provider";
import RoutedComponents from "./config/routing/routed-components";
import LocalAppPreferencesContextProvider from "./lib/providers/local-app-preferences-provider";
import {CompaniesContextProvider} from "./lib/providers/companies-provider";
import {LicenseInfo} from "@mui/x-license";
import {QueryClient, QueryClientProvider} from "react-query";
import {TooltipProvider} from "./components/ui/tooltip";
import * as Sentry from "@sentry/react";
import {Toaster} from "./components/ui/sonner";

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    maxBreadcrumbs: 50,
    debug: false,
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
        "localhost",
        /^https:\/\/api\.maxautolytics\.com\/api\/v3/,
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    profilesSampleRate: 1.0,
    release: "1.0",
    environment: import.meta.env.VITE_ENV,
});
LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_X_LICENSE_KEY);
const queryClient = new QueryClient();

export default function App() {
    return (
        <MaxAutolyticsApiProvider>
            <LocalAppPreferencesContextProvider>
                <QueryClientProvider client={queryClient}>
                    <UserContextProvider>
                        <CompaniesContextProvider>
                            <TooltipProvider>
                                <Toaster/>
                                <AppSidebar>
                                    <RoutedComponents/>
                                </AppSidebar>
                            </TooltipProvider>
                        </CompaniesContextProvider>
                    </UserContextProvider>
                </QueryClientProvider>
            </LocalAppPreferencesContextProvider>
        </MaxAutolyticsApiProvider>
    );
}
