import { createContext, useEffect, useState } from "react";
import { AppPreferences } from "./providers";

declare interface LocalAppPreferencesContextType {
  preferences: AppPreferences;
  setPreference: <T extends AllValidKeys>(key: T, value: any) => void;
}

type NestedKeys<T> = {
  [K in keyof T & (string | number)]: T[K] extends object
    ? K | `${K}.${NestedKeys<T[K]>}`
    : K;
}[keyof T & (string | number)];

type AllValidKeys = NestedKeys<AppPreferences>;
type LocalAppPreferencesContextProviderProps = {
  children: React.ReactNode | React.ReactNode[];
};
export const LocalAppPreferencesContext = createContext(
  {} as LocalAppPreferencesContextType
);

export default function LocalAppPreferencesContextProvider({
  children,
}: LocalAppPreferencesContextProviderProps) {
  const defaultPreferences: AppPreferences = {
    global: {
      activeCompanyIds: [],
      dateRange: "monthToDate",
    },
    dashboard: {
      activeTab: "general-overview",
      layout: {
        cargurus: {
          tab: 0,
          "age-bucket": 0,
        },
      },
      useAdjustments: false,
      excludeSourceIds: [],
      inventoryFootprint: {
        displayMode: "byModel",
        timeSpan: "sixMonths",
      },
    },
    lists: {
      sales: {
        filters: {},
      },
      inventory: {
        filters: {},
      },
      adjustments: {
        filters: [],
      },
    },
  };
  const [preferences, setPreferences] =
    useState<AppPreferences>(defaultPreferences);

  const assertPreferencesObject = () => {
    const preferencesRef = localStorage.getItem("app-preferences")
      ? JSON.parse(localStorage.getItem("app-preferences") as string)
      : defaultPreferences;
    if (!preferencesRef) {
      localStorage.setItem(
        "app-preferences",
        JSON.stringify(defaultPreferences)
      );
    }
    const addMissingKeys = (ref: any, def: any) => {
      for (const key in def) {
        if (ref[key] === undefined) {
          ref[key] = def[key];
        }
        if (typeof def[key] === "object") {
          addMissingKeys(ref[key], def[key]);
        }
      }
    };
    addMissingKeys(preferencesRef, defaultPreferences);
    localStorage.setItem("app-preferences", JSON.stringify(preferencesRef));
  };

  const getPreferences = (): AppPreferences => {
    const preferencesRef = localStorage.getItem("app-preferences") as string;
    const preferencesObject: AppPreferences = JSON.parse(preferencesRef);
    return preferencesObject;
  };

  function setPreference<T extends AllValidKeys>(key: T, value: any): void {
    const keys = key.split(".") as (keyof any)[];
    const newPreferences = { ...preferences };
    let result: any = newPreferences;

    for (let i = 0; i < keys.length - 1; i++) {
      const k = keys[i];
      if (!result[k]) {
        result[k] = {};
      }
      result = result[k];
    }

    result[keys[keys.length - 1]] = value;
    localStorage.setItem("app-preferences", JSON.stringify(newPreferences));
    setPreferences(newPreferences);
  }

  useEffect(() => {
    assertPreferencesObject();
    setPreferences(getPreferences());
  }, []);

  return (
    <LocalAppPreferencesContext.Provider value={{ preferences, setPreference }}>
      {children}
    </LocalAppPreferencesContext.Provider>
  );
}
