import useApi from "@/hooks/use-api";
import useCompanies from "@/hooks/use-companies";
import useLocalAppPreferences from "@/hooks/use-local-app-preferences";
import { Company } from "@/lib/api/companies/companies";
import { DateRangePresets } from "@/lib/date-range-presets";
import { CompaniesContextType } from "@/lib/providers/companies-provider";
import { useMemo } from "react";

export default function useSalesListContext() {
  const api = useApi();
  const companies = useCompanies();
  const preferences = useLocalAppPreferences();
  const {
    global: { dateRange: dateRangeDef, activeCompanyIds },
    lists: {
      sales: { filters: salesFilters },
    },
  } = preferences.preferences;

  const dateRange = useMemo(
    () => getDateRangeObject(dateRangeDef),
    [dateRangeDef]
  );

  const { activeCompanies, targetMarginsMap } = useMemo(() => {
    const activeCompanies = getActiveCompaniesArray(
      companies.data,
      activeCompanyIds
    );
    const targetMarginsMap = getTargetMarginsMap(activeCompanies);
    return { activeCompanies, targetMarginsMap };
  }, [companies.data, activeCompanyIds]);

  return {
    api,
    companies,
    preferences,
    dateRange,
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
    activeCompanyIds,
    salesFilters,
    activeCompanies,
    targetMarginsMap,
  };
}

function getDateRangeObject(
  dateRangeDef: string | { startDate: string; endDate: string }
) {
  if (typeof dateRangeDef === "string") {
    const dateRange =
      DateRangePresets[dateRangeDef as keyof typeof DateRangePresets]();
    return {
      startDate: dateRange.startDate.format("MM/DD/YYYY"),
      endDate: dateRange.endDate.format("MM/DD/YYYY"),
    };
  }
  return dateRangeDef;
}

function getActiveCompaniesArray(
  companies: CompaniesContextType["data"],
  activeCompanyIds: number[]
) {
  return (
    companies?.filter((company) =>
      activeCompanyIds.includes(company.CompanyId)
    ) ?? []
  );
}

function getTargetMarginsMap(activeCompanies: Company[]) {
  return new Map(
    activeCompanies?.map((company) => [
      company.CompanyId,
      company.TargetMargin,
    ]) ?? []
  );
}
