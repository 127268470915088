import * as React from "react";
import { ChevronsUpDown, MinusSquareIcon, Plus } from "lucide-react";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from "@/components/ui/sidebar";
import { CompaniesContextType } from "@/lib/providers/companies-provider";
import useLocalAppPreferences from "@/hooks/use-local-app-preferences";
import { StackIcon } from "@radix-ui/react-icons";
import { Button } from "@/components/ui/button";
import { AnimatePresence, motion } from "framer-motion";
import { CardTitle } from "@/components/ui/card";

type CompanySwitcherProps = {
  companies: CompaniesContextType["data"];
};
export function CompanySwitcher({ companies }: CompanySwitcherProps) {
  const { isMobile } = useSidebar();
  const preferences = useLocalAppPreferences();
  const activeCompanyIds = preferences.preferences.global.activeCompanyIds;
  const [newStores, setNewStores] = React.useState(activeCompanyIds);
  const [isSelectorOpen, setIsSelectorOpen] = React.useState(false);
  const activeCompanies = companies?.filter((company) =>
    activeCompanyIds.includes(company.CompanyId)
  );
  const groupedCompanies = React.useMemo(() => {
    if (!companies) return [];
    return companies.reduce(
      (acc: { [group: string]: CompaniesContextType["data"] }, company) => {
        const group = company.GroupName ?? "";
        if (!acc[group]) acc[group] = [];
        acc[group].push(company);
        return acc;
      },
      {}
    );
  }, [companies, activeCompanyIds]) as {
    [group: string]: CompaniesContextType["data"];
  };
  const descriptiveText =
    activeCompanies && activeCompanies.length > 1
      ? `And ${activeCompanies.length - 1} more...`
      : "And that's it!";

  const toggleCompany = (companyId: number) => {
    const isActive = newStores.includes(companyId);
    const newActiveCompanyIds = isActive
      ? newStores.filter((id) => id !== companyId)
      : [...newStores, companyId];
    setNewStores(newActiveCompanyIds);
  };

  const setCompanies = (companyIds: number[]) => {
    preferences.setPreference("global.activeCompanyIds", companyIds);
  };

  return (
    <div>
      <SidebarMenu>
        <SidebarMenuItem>
          <DropdownMenu
            open={isSelectorOpen}
            onOpenChange={(isOpen) => {
              setNewStores(activeCompanyIds);
              setIsSelectorOpen(isOpen);
            }}
          >
            <DropdownMenuTrigger asChild>
              <SidebarMenuButton
                size="lg"
                className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
              >
                <div className="flex aspect-square size-8 items-center justify-center rounded-lg bg-sidebar-primary text-sidebar-primary-foreground">
                  <StackIcon />
                </div>
                <div className="grid flex-1 text-left text-sm leading-tight">
                  <span className="truncate font-semibold">
                    {activeCompanies?.[0]?.Name ?? "No active store"}
                  </span>
                  <span className="truncate text-xs">{descriptiveText}</span>
                </div>
                <ChevronsUpDown className="ml-auto" />
              </SidebarMenuButton>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              className="min-w-72 rounded-lg flex flex-col gap-4"
              align="start"
              side={isMobile ? "bottom" : "right"}
              sideOffset={4}
            >
              <CardTitle className="px-4 pt-4">Change Stores</CardTitle>
              <p className="pl-4 pr-12 py-0 w-72 text-sm opacity-60">
                Select which stores you want to view in your analytics
              </p>
              <DropdownMenuSeparator />
              {Object.keys(groupedCompanies).map(
                (group: keyof typeof groupedCompanies) => (
                  <div key={group}>
                    <DropdownMenuLabel
                      className="text-xs text-muted-foreground cursor-pointer"
                      onClick={() =>
                        groupedCompanies[group] &&
                        setCompanies(
                          groupedCompanies[group].map((c) => c.CompanyId)
                        )
                      }
                    >
                      {group}
                    </DropdownMenuLabel>
                    {groupedCompanies[group]?.map((company) => (
                      <DropdownMenuItem
                        key={company.CompanyId}
                        onClick={(e) => {
                          e.preventDefault();
                          toggleCompany(company.CompanyId);
                        }}
                        className="gap-2 p-2 w-full justify-between"
                      >
                        <h1>{company.Name}</h1>
                        <DropdownMenuShortcut>
                          <AnimatePresence mode="popLayout">
                            {newStores.includes(company.CompanyId) ? (
                              <motion.div
                                className="flex items-center gap-2"
                                key="active"
                                initial={{ opacity: 0, translateX: -4 }}
                                animate={{ opacity: 1, translateX: 0 }}
                                exit={{ opacity: 0, translateX: -4 }}
                              >
                                <span>Active </span>
                                <MinusSquareIcon className="w-4" />
                              </motion.div>
                            ) : (
                              <motion.div
                                key="inactive"
                                initial={{ opacity: 0, translateX: 4 }}
                                animate={{ opacity: 1, translateX: 0 }}
                                exit={{ opacity: 0, translateX: 4 }}
                              >
                                <Plus className="w-4 opacity-50" />
                              </motion.div>
                            )}
                          </AnimatePresence>
                        </DropdownMenuShortcut>
                      </DropdownMenuItem>
                    ))}
                    <DropdownMenuSeparator />
                  </div>
                )
              )}
              <div className="flex justify-center gap-2 px-2 pb-2 -mt-2">
                <Button
                  className="w-full"
                  variant="secondary"
                  onClick={() => {
                    setIsSelectorOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="w-full"
                  onClick={() => {
                    preferences.setPreference(
                      "global.activeCompanyIds",
                      newStores
                    );
                    setIsSelectorOpen(false);
                  }}
                >
                  Apply stores
                </Button>
              </div>
            </DropdownMenuContent>
          </DropdownMenu>
        </SidebarMenuItem>
      </SidebarMenu>
    </div>
  );
}
