import { GraphQLClient, gql } from 'graphql-request';
import { AppAnalyticsModel } from "@/lib/api/analytics/analytics";

export class Analytics {
    private companyId: number;
    private client: GraphQLClient;

    /**
     * Creates an instance of the Analytics class.
     * @param {number} companyId - The ID of the company to get analytics for.
     */
    constructor(companyId: number) {
        this.companyId = companyId;
        const authToken = localStorage.getItem('Authorization');
        this.client = new GraphQLClient(import.meta.env.VITE_API_URL?.replace('api/v3', 'graphql'), {
            headers: {
                Authorization: authToken!,
            },
        });
    }

    /**
     * Get all app analytics data
     * @param {string} requestedFields - The requested fields in the GraphQL query.
     * @param {GetAppAnalyticsOptions} options - The options to filter the analytics data.
     * @returns {Promise<AppAnalyticsModel>} The app analytics data.
     */
    public async getAll(requestedFields: string, options?: GetAppAnalyticsOptions): Promise<AppAnalyticsModel> {
        const query = gql`
            query GetAppAnalytics(
                $companyId: Int!, 
                $startDate: String, 
                $endDate: String, 
                $includeAdjustments: Boolean
            ) {
                appAnalytics(
                    companyId: $companyId,
                    startDate: $startDate,
                    endDate: $endDate,
                    includeAdjustments: $includeAdjustments
                ) {
                    ${requestedFields}
                }
            }
        `;

        const variables = {
            companyId: this.companyId,
            startDate: options?.startDate,
            endDate: options?.endDate,
            includeAdjustments: options?.includeAdjustments,
        };

        const data = await this.client.request(query, variables) as { appAnalytics: AppAnalyticsModel };
        return data.appAnalytics;
    }
}

export type GetAppAnalyticsOptions = {
    startDate?: string;
    endDate?: string;
    includeAdjustments?: boolean;
};
