import { GridRowClassNameParams } from "@mui/x-data-grid-pro";
import { useCallback } from "react";

export default function useRowClassnameCallback() {

  const colorsMap = {
    0: "border-x border-[hsl(240,80%,65%)] bg-[hsl(240,80%,65%)]",
    1: "border-x border-[hsl(180,80%,65%)] bg-[hsl(180,80%,65%)]",
    2: "border-x border-[hsl(120,80%,65%)] bg-[hsl(120,80%,65%)]",
    3: "border-x border-[hsl(60,40%,65%)] bg-[hsl(60,40%,65%)]",
    4: "border-x border-[hsl(0,20%,65%)] bg-[hsl(0,20%,65%)]",
  }

  const getRowClassName = useCallback((params: GridRowClassNameParams, activeCompanyIds: number[]) => {
    const index = activeCompanyIds.indexOf(params.row.CompanyId);
    const hsl = `${colorsMap[index as keyof typeof colorsMap]}`;
    return `${hsl} bg-opacity-10 text-xs`;
  }, []);
  return getRowClassName;
}
