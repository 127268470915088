import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "@/components/ui/table.tsx";
import {cn} from "@/lib/utils.ts";
import {AnimatePresence, motion} from "framer-motion";
import {toast} from "sonner";
import {UndoIcon} from "lucide-react";
import LinkWithQuery from "@/components/link-with-query.tsx";
import useLocalAppPreferences from "@/hooks/use-local-app-preferences.tsx";
import {useNavigate} from "react-router";
import {InventoryOverview} from "@/lib/api/analytics/inventory-analytics";

type InventoryByAgeTableProps = {
    data: InventoryOverview[] | undefined
    loading: boolean
    storeCount: number
}

export default function InventoryByAgeTable({ data, loading, storeCount }: InventoryByAgeTableProps) {
    const colors = ['bg-cargurus-1', 'bg-cargurus-2', 'bg-cargurus-3', 'bg-cargurus-35', 'bg-cargurus-4', 'bg-cargurus-45', 'bg-cargurus-5']
    const preferences = useLocalAppPreferences()
    const navigate = useNavigate()
    return (
        <AnimatePresence mode={'wait'}>
            {loading ? (
                <motion.div
                    key="loading"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{ duration: 0.2, damping: 0, bounce: 0, stiffness: 100 }}
                    className="w-full bg-gray-200 rounded-md animate-pulse"
                    style={{
                        height: `${8 + 14 * storeCount}rem`
                    }}
                />
            ) : (
                <motion.div
                    key="stable"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{duration: 0.2}}
                    className="w-full"
                >
                    <Table className="w-full whitespace-nowrap">
                        <TableHeader className={'border-b'}>
                            <TableHead>Age bucket</TableHead>
                            <TableHead>Store</TableHead>
                            <TableHead>Total units</TableHead>
                            <TableHead>Avg investment</TableHead>
                        </TableHeader>
                        <TableBody>
                            {data?.[0]?.inventoryByAgeData?.map((item, parentIndex) => {
                                return (
                                    <TableRow key={parentIndex}>
                                        <TableCell>
                                            <LinkWithQuery to={'/inventory'} onClick={() => {
                                                const currentMinAge = preferences.preferences.lists.inventory.filters.minAge ?? ''
                                                const currentMaxAge = preferences.preferences.lists.inventory.filters.maxAge ?? ''
                                                const currentCompanyIds = preferences.preferences.global.activeCompanyIds ?? []
                                                let newMaxAge, newMinAge: number | undefined
                                                if (item.ageBucket.includes('-')) {
                                                    const [min, max] = item.ageBucket.split('-')
                                                    newMinAge = parseInt(min)
                                                    newMaxAge = parseInt(max)
                                                } else {
                                                    if(item.ageBucket === '<10') {
                                                        newMaxAge = 10
                                                    }
                                                    if(item.ageBucket === '61+') {
                                                        newMinAge = 61
                                                    }
                                                }
                                                preferences.setPreference('lists.inventory.filters.minAge', newMinAge)
                                                preferences.setPreference('lists.inventory.filters.maxAge', newMaxAge)
                                                toast.info('Filtering for ' + item.ageBucket + ' days to sell', {
                                                    action: {
                                                        label: <span className="flex items-center gap-2">
                                                                              Undo <UndoIcon size={16} />
                                                                            </span>,
                                                        onClick: () => {
                                                            preferences.setPreference('lists.inventory.filters.minAge', currentMinAge)
                                                            preferences.setPreference('lists.inventory.filters.maxAge', currentMaxAge)
                                                            preferences.setPreference('global.activeCompanyIds', currentCompanyIds)
                                                            navigate('/dashboard')
                                                        }
                                                    }
                                                })
                                            }} className={'hover:underline'}>
                                            <div
                                                className={cn("w-2 h-2 inline-block rounded-full mr-1 -translate-y-0.5", colors[parentIndex])}
                                            ></div>
                                            {item.ageBucket} days
                                            </LinkWithQuery>
                                        </TableCell>
                                        <TableCell>
                                            <Table>
                                                <TableBody>
                                                    {data?.map((storeData, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell>
                                                            <LinkWithQuery to={'/inventory'} onClick={() => {
                                                                const currentMinAge = preferences.preferences.lists.inventory.filters.minAge ?? ''
                                                                const currentMaxAge = preferences.preferences.lists.inventory.filters.maxAge ?? ''
                                                                const currentCompanyIds = preferences.preferences.global.activeCompanyIds ?? []
                                                                let newMaxAge, newMinAge: number | undefined
                                                                if (item.ageBucket.includes('-')) {
                                                                    const [min, max] = item.ageBucket.split('-')
                                                                    newMinAge = parseInt(min)
                                                                    newMaxAge = parseInt(max)
                                                                } else {
                                                                    if(item.ageBucket === '<10') {
                                                                        newMaxAge = 10
                                                                    }
                                                                    if(item.ageBucket === '61+') {
                                                                        newMinAge = 61
                                                                    }
                                                                }
                                                                preferences.setPreference('lists.inventory.filters.minAge', newMinAge)
                                                                preferences.setPreference('lists.inventory.filters.maxAge', newMaxAge)
                                                                preferences.setPreference('global.activeCompanyIds', [storeData.companyId])
                                                                toast.info('Changed active store to ' + storeData.companyName, {
                                                                    action: {
                                                                        label: <span className="flex items-center gap-2">
                                                                              Undo <UndoIcon size={16} />
                                                                            </span>,
                                                                        onClick: () => {
                                                                            preferences.setPreference('lists.inventory.filters.minAge', currentMinAge)
                                                                            preferences.setPreference('lists.inventory.filters.maxAge', currentMaxAge)
                                                                            preferences.setPreference('global.activeCompanyIds', currentCompanyIds)
                                                                            navigate('/dashboard')
                                                                        }
                                                                    }
                                                                })
                                                            }} className={'hover:underline'}>
                                                                <div className='w-full max-w-24 whitespace-nowrap overflow-hidden text-ellipsis'> {storeData.companyName}</div>
                                                            </LinkWithQuery>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableCell>
                                        <TableCell>
                                            <Table>
                                                <TableBody>
                                                    {data?.map((storeData, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell>
                                                                {storeData?.inventoryByAgeData?.[parentIndex]?.totalInventory} (
                                                                {storeData?.inventoryByAgeData?.[parentIndex]?.bucketPercentOfTotal}%)
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableCell>
                                        <TableCell>
                                            <Table>
                                                <TableBody>
                                                    {data?.map((storeData, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell>
                                                                $
                                                                {storeData?.inventoryByAgeData?.[parentIndex]?.averageInvestment.toLocaleString()}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </motion.div>
            )}
        </AnimatePresence>
    )
}