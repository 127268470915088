import { z } from "zod";

export const UpdateCompanySchema = z.object({
  AmbiguousAlias: z.string().max(64),
  DefaultCarGurusPreference: z.union([
    z.literal(0),
    z.literal(1),
    z.literal(2),
    z.literal(3),
    z.literal(4),
  ]),
  City: z.string().max(64),
  LogicalId: z.string().max(32),
  Name: z.string().max(64),
  StreetAddress1: z.string().max(128),
  StreetAddress2: z.string().max(128).optional(),
  TargetMargin: z.number(),
  ZipCode: z.string().max(16),
  StateId: z.number().int().min(1),
  CompanyGroupId: z.number().int().min(1).optional(),
  FranchiseMake: z.string().max(16),
});
