import {
  gridFilteredSortedRowEntriesSelector,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import { Separator } from "@radix-ui/react-separator";

export default function SalesTableFooter() {
  const apiRef = useGridApiContext();
  const visibleRows = gridFilteredSortedRowEntriesSelector(apiRef);
  const totalRows = apiRef.current.getRowsCount();

  const totalInvestment = visibleRows.reduce(
    (acc, row) => acc + Number(row.model.FinalACV),
    0
  );
  const avgInvestment = totalInvestment / visibleRows.length;
  const avgDaysInInventory =
    visibleRows
      .filter((row) => typeof row.model.DaysInInventory === "number")
      .reduce((acc, row) => {
        return acc + row.model.DaysInInventory;
      }, 0) /
    visibleRows.filter((row) => typeof row.model.DaysInInventory === "number")
      .length;
  return (
    <div className="flex justify-end gap-2 items-center p-2 border-t">
      <div>
        Avg days in inventory:{" "}
        <span className="font-bold">
          {Math.round(avgDaysInInventory).toLocaleString()}
        </span>{" "}
        days
      </div>
      <Separator orientation="vertical" className="bg-black/50" />
      <div>
        Total investment:{" "}
        <span className="font-bold">
          ${Math.round(totalInvestment).toLocaleString()}
        </span>
      </div>
      <Separator orientation="vertical" className="bg-black/50" />
      <div>
        Avg investment:{" "}
        <span className="font-bold">
          ${Math.round(avgInvestment).toLocaleString()}
        </span>
      </div>
      <Separator orientation="vertical" className="bg-black/50" />
      <div>
        Showing{" "}
        <span className="font-bold">
          {visibleRows.length} / {totalRows}
        </span>{" "}
        results
      </div>
    </div>
  );
}
