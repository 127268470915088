import DataCard from "@/pages/dashboard/components/data-card.tsx";
import {InventoryOverview} from "@/lib/api/analytics/inventory-analytics";

type OverviewMetricsCardsProps = {
    data: InventoryOverview[] | undefined
    loading: boolean
    storeCount: number
}

export default function OverviewMetricsCards({ data, loading, storeCount }: OverviewMetricsCardsProps) {
    return (
        <div className="w-full grid grid-cols-3 xl:grid-cols-5 items-center gap-4">
            <DataCard<InventoryOverview>
                title='Total inventory'
                data={data}
                dataKey='totalInventory'
                loading={loading}
                loadingSkeletonCount={storeCount}
            />
            <DataCard<InventoryOverview>
                title='Total investment'
                data={data}
                dataKey='totalInvestment'
                loading={loading}
                loadingSkeletonCount={storeCount}
                format='currency'
            />
            <DataCard<InventoryOverview>
                title='Avg investment'
                data={data}
                dataKey='averageInvestment'
                loading={loading}
                loadingSkeletonCount={storeCount}
                format='currency'
            />
            <DataCard<InventoryOverview>
                title='Days supply'
                data={data}
                dataKey='daysSupply'
                loading={loading}
                loadingSkeletonCount={storeCount}
            />
            <DataCard<InventoryOverview>
                title='Avg age'
                data={data}
                dataKey='averageDaysInInventory'
                loading={loading}
                loadingSkeletonCount={storeCount}
            />
        </div>
    )
}