// components/AddMappingDialog.tsx

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import HookForm from "@/features/hook-form";
import HookFormSelect from "@/features/hook-form/hook-form-select";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  CreateAutomationMappingDto,
  CreateAutomationMappingSchema,
} from "@/lib/api/automation-preferences/schemas/create-automation-mapping.schema";
import { Plus } from "lucide-react";
import {
  COLUMN_LETTER_OPTIONS,
  CONVERSION_FN_MAP,
  SALE_VEHICLE_TO_KEYS,
} from "../../lib/constants";

type AddMappingDialogProps = {
  addMapping: ({
    preferenceId,
    data,
  }: {
    preferenceId: number;
    data: CreateAutomationMappingDto;
  }) => void;
  loading: boolean;
  preferenceId: number;
};

export default function AddMappingDialog({
  addMapping,
  loading,
  preferenceId,
}: AddMappingDialogProps) {
  const form = useForm<CreateAutomationMappingDto>({
    resolver: zodResolver(CreateAutomationMappingSchema),
    defaultValues: {
      FromColumn: "",
      ConvertionFn: "none",
      ToKey: "",
    },
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = form;

  return (
    <Dialog>
      <DialogTrigger className="ml-auto w-full" asChild>
        <Button variant="outline" className="mt-4 w-full">
          <Plus className="inline-block -mt-1" /> Add New Mapping
        </Button>
      </DialogTrigger>
      <DialogContent className="w-screen max-w-5xl">
        <DialogHeader>
          <DialogTitle>Add New Mapping</DialogTitle>
        </DialogHeader>
        <HookForm<CreateAutomationMappingDto>
          errors={errors}
          onSubmit={handleSubmit((values) =>
            addMapping({
              preferenceId,
              data: values,
            })
          )}
          register={register}
        >
          <div className="flex items-center gap-2 w-full mb-4">
            <HookFormSelect
              label="From Column"
              name="FromColumn"
              options={COLUMN_LETTER_OPTIONS}
              currentValue={watch("FromColumn")}
              onValueChangeCallback={(newValue) =>
                setValue("FromColumn", newValue)
              }
            />
            <HookFormSelect
              label="Formatter"
              name="ConvertionFn"
              options={Object.keys(CONVERSION_FN_MAP)}
              renderValue={(value) =>
                CONVERSION_FN_MAP[value as keyof typeof CONVERSION_FN_MAP]
              }
              currentValue={watch("ConvertionFn")}
              onValueChangeCallback={(newValue) =>
                setValue("ConvertionFn", newValue)
              }
            />
            <HookFormSelect
              label="To Data Key"
              name="ToKey"
              options={Object.keys(SALE_VEHICLE_TO_KEYS)}
              renderValue={(value) =>
                SALE_VEHICLE_TO_KEYS[value as keyof typeof SALE_VEHICLE_TO_KEYS]
              }
              currentValue={watch("ToKey")}
              onValueChangeCallback={(newValue) => setValue("ToKey", newValue)}
            />
          </div>
          <Button type="submit" className="w-full" loading={loading}>
            Add Mapping
          </Button>
        </HookForm>
      </DialogContent>
    </Dialog>
  );
}
