import {ChartContainer} from "@/components/ui/chart.tsx";
import {Bar, BarChart, CartesianGrid, XAxis, YAxis} from "recharts";
import {AnimatePresence, motion} from "framer-motion";
import {InventoryOverview} from "@/lib/api/analytics/inventory-analytics";

type CargurusAtAGlanceChartProps = {
    data: InventoryOverview[] | undefined
    loading: boolean
}

export default function CargurusAtAGlanceChart({ data, loading }: CargurusAtAGlanceChartProps) {
    return(
        <AnimatePresence mode={'wait'}>
            {loading ? (
                <motion.div
                    key="loading"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{ duration: 0.2, damping: 0, bounce: 0, stiffness: 100 }}
                    className="w-full h-96 bg-gray-200 rounded-md animate-pulse"
                />
            ) : (
                <motion.div
                    key="stable"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="w-1/2 h-96"
                >
                    <ChartContainer config={{}} className="w-full h-full">
                        <BarChart
                            layout='vertical'
                            data={data?.map((item) => {
                                const formattedObj: {
                                    name: string
                                    [key: string]: number | string
                                } = {
                                    name: item.companyName
                                }
                                item.cargurusGroupingData?.forEach((item) => {
                                    formattedObj[item.rating] = item.ratingPercentOfTotal
                                })
                                return formattedObj
                            }) ?? []}
                        >
                            <CartesianGrid strokeDasharray="3 3" vertical={false} />
                            <XAxis type='number' domain={[0, 100]} hide />
                            <YAxis type='category' dataKey='name' allowDuplicatedCategory={false} axisLine={false} />
                            {data?.[0]?.cargurusGroupingData?.map((entry, index) => {
                                const color = index < 5 ? `var(--cargurus-${index + 1})` : 'gray'
                                const radius = [0,0,0,0] as [number, number, number, number]
                                if(index === 0){
                                    radius[0] = 4
                                    radius[3] = 4
                                }
                                if(index === data[0]!.cargurusGroupingData!.length - 1){
                                    radius[1] = 4
                                    radius[2] = 4
                                }
                                return <Bar barSize={28} key={index} dataKey={entry.rating} stackId='a' fill={color} radius={radius}/>
                            })}
                        </BarChart>
                    </ChartContainer>
                </motion.div>
            )}
        </AnimatePresence>
    )
}