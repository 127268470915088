import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHead,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { AutomationMapping } from "@/lib/api/automation-preferences/automation-preferences";
import { EllipsisVertical, Trash } from "lucide-react";
import { CONVERSION_FN_MAP, SALE_VEHICLE_TO_KEYS } from "../../lib/constants";

type MappingTableProps = {
  mappings: AutomationMapping[];
  deleteMapping: ({
    preferenceId,
    mappingId,
  }: {
    preferenceId: number;
    mappingId: number;
  }) => void;
  loading: boolean;
  preferenceId: number;
};

export default function MappingTable({
  mappings,
  deleteMapping,
  loading,
  preferenceId,
}: MappingTableProps) {
  return (
    <Table>
      <TableHeader>
        <TableHead>Column</TableHead>
        <TableHead>Formatter</TableHead>
        <TableHead>Mapped Field</TableHead>
        <TableHead></TableHead>
      </TableHeader>
      <TableBody>
        {mappings.map((mapping) => (
          <TableRow key={mapping.AutomationMappingId}>
            <TableCell>{mapping.FromColumn}</TableCell>
            <TableCell>{CONVERSION_FN_MAP[mapping.ConvertionFn]}</TableCell>
            <TableCell>{SALE_VEHICLE_TO_KEYS[mapping.ToKey]}</TableCell>
            <TableCell>
              <DropdownMenu>
                <DropdownMenuTrigger>
                  <EllipsisVertical />
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem
                    className="text-red-600 cursor-pointer"
                    onClick={() =>
                      deleteMapping({
                        preferenceId,
                        mappingId: mapping.AutomationMappingId,
                      })
                    }
                    asChild
                  >
                    <Button variant="ghost" loading={loading}>
                      <Trash className="inline-block -mt-1" /> Delete Mapping
                    </Button>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
