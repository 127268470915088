import { AxiosInstance } from "axios";
import { InventoryFootprintPreference } from "./inventory-footprint-preference";

import { CreateInventoryFootprintPreferenceDto } from "./dto/create-inventory-footprint-preference.dto";
import { CreateInventoryFootprintPreferenceSchema } from "./schemas/create-inventory-footprint-preference.schema copy";
import { UpdateInventoryFootprintPreferenceSchema } from "./schemas/update-inventory-footprint-preference.schema";

export class InventoryFootprintPreferences {
  private readonly instance: AxiosInstance;
  private companyId: number;

  /**
   * Creates an instance of the Auth class.
   * @param {AxiosInstance} instance - The Axios instance to use for HTTP requests.
   */
  constructor(instance: AxiosInstance, companyId: number) {
    this.instance = instance;
    this.companyId = companyId;
  }

  /**
   * Create a new preference
   * @param {CreateInventoryFootprintPreferenceDto} preferenceObject - The preference object to create.
   * @returns {Promise<InventoryFootprintPreference>} The created preference.
   */

  public async create(preferenceObject: CreateInventoryFootprintPreferenceDto) {
    const { data } = await this.instance.post(
      `/companies/${this.companyId}/inventory-footprint-preferences`,
      preferenceObject
    );
    return data as InventoryFootprintPreference;
  }

  /**
   * get all preferences
   * @returns {Promise<InventoryFootprintPreference[]>} The preferences.
   */

  public async getAll() {
    const { data } = await this.instance.get(
      `/companies/${this.companyId}/inventory-footprint-preferences`
    );
    return data as InventoryFootprintPreference[];
  }

  /**
   * get a specific preference
   * @param {number} preferenceId - The preference ID.
   * @returns {Promise<InventoryFootprintPreference>} The preference.
   */

  public async getOne(preferenceId: number) {
    const { data } = await this.instance.get(
      `/companies/${this.companyId}/inventory-footprint-preferences/${preferenceId}`
    );
    return data as InventoryFootprintPreference;
  }

  /**
   * Update a preference
   * @param {number} preferenceId - The preference ID.
   * @param {CreateInventoryFootprintPreferenceDto} preferenceObject - The preference object to update.
   * @returns {Promise<InventoryFootprintPreference>} The updated preference.
   */

  public async update(
    preferenceId: number,
    preferenceObject: CreateInventoryFootprintPreferenceDto
  ) {
    const { data } = await this.instance.patch(
      `/companies/${this.companyId}/inventory-footprint-preferences/${preferenceId}`,
      preferenceObject
    );
    return data as InventoryFootprintPreference;
  }

  /**
   * Delete a preference
   * @param {number} preferenceId - The preference ID.
   * @returns {Promise<void>} The promise.
   */

  public async delete(preferenceId: number) {
    await this.instance.delete(
      `/companies/${this.companyId}/inventory-footprint-preferences/${preferenceId}`
    );
  }

  public getSchemas() {
    return {
      create: CreateInventoryFootprintPreferenceSchema,
      update: UpdateInventoryFootprintPreferenceSchema,
    };
  }
}
