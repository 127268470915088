import Main from "@/pages/main";
import { RouteDefinition } from "./route-definitions.d";
import { Login } from "@/pages/login";
import Dashboard from "@/pages/dashboard";
import { ArchiveIcon, DesktopIcon } from "@radix-ui/react-icons";
import { FileLineChartIcon, HomeIcon } from "lucide-react";
import Sales from "@/pages/sales";
import SettingsStores from "@/pages/settings-stores";
import useLocalAppPreferences from "@/hooks/use-local-app-preferences.tsx";
import Inventory from "@/pages/inventory";

const routeDefinitions: RouteDefinition[] = [
  {
    path: "/",
    component: Main,
    properties: {
      layout: {
        sidebar: "hidden",
      },
      authentication: "public",
      navigation: {
        title: "Welcome",
      },
    },
  },
  {
    path: "/login",
    component: Login,
    properties: {
      layout: {
        sidebar: "hidden",
      },
      authentication: "unauthenticated-only",
      navigation: {
        title: "Login",
      },
    },
  },
  {
    path: "/dashboard",
    component: Dashboard,
    properties: {
      layout: {
        sidebar: "full",
      },
      showInSidebar: true,
      authentication: "private",
      navigation: {
        title: "Dashboard",
        icon: HomeIcon,
        subRoutes: [
          {
            title: "General overview",
            onClick: (setPreference: ReturnType<typeof useLocalAppPreferences>['setPreference'])=> setPreference('dashboard.activeTab', 'general-overview'),
            getIsActive: (preferences: ReturnType<typeof useLocalAppPreferences>['preferences']) => preferences.dashboard.activeTab === 'general-overview',
          },
          {
            title: "Sales overview",
            onClick: (setPreference: ReturnType<typeof useLocalAppPreferences>['setPreference'])=> setPreference('dashboard.activeTab', 'sales-breakdown'),
            getIsActive: (preferences: ReturnType<typeof useLocalAppPreferences>['preferences']) => preferences.dashboard.activeTab === 'sales-breakdown',
          },
          {
            title: "Inventory overview",
            onClick: (setPreference: ReturnType<typeof useLocalAppPreferences>['setPreference'])=> setPreference('dashboard.activeTab', 'inventory-breakdown'),
            getIsActive: (preferences: ReturnType<typeof useLocalAppPreferences>['preferences']) => preferences.dashboard.activeTab === 'inventory-breakdown',
          },
          {
            title: "Inventory footprint",
            onClick: (setPreference: ReturnType<typeof useLocalAppPreferences>['setPreference'])=> setPreference('dashboard.activeTab', 'inventory-footprint'),
            getIsActive: (preferences: ReturnType<typeof useLocalAppPreferences>['preferences']) => preferences.dashboard.activeTab === 'inventory-footprint',
          },
        ],
      },
    },
  },
  {
    path: "/sales",
    component: Sales,
    properties: {
      layout: {
        sidebar: "full",
      },
      showInSidebar: true,
      authentication: "private",
      navigation: {
        title: "Sales",
        icon: DesktopIcon,
      },
    },
  },
  {
    path: "/inventory",
    component: Inventory,
    properties: {
      layout: {
        sidebar: "full",
      },
      showInSidebar: true,
      authentication: "private",
      navigation: {
        title: "Inventory",
        icon: ArchiveIcon,
      },
    },
  },
  {
    path: "/adjustments",
    component: Dashboard,
    properties: {
      layout: {
        sidebar: "full",
      },
      showInSidebar: true,
      authentication: "private",
      navigation: {
        title: "Adjustments",
        icon: FileLineChartIcon,
      },
    },
  },
  {
    path: "/settings/stores",
    component: SettingsStores,
    properties: {
      layout: {
        sidebar: "full",
      },
      authentication: "private",
      navigation: {
        title: "Store settings",
      },
    },
  },
];

export default routeDefinitions;
