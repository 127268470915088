import { z } from "zod";
import * as dayjs from "dayjs";

export const CreateCarGurusPricingRecordSchema = z.object({
  Great: z
    .number()
    .min(0, { message: "Great must be greater than or equal to 0" }),
  Good: z
    .number()
    .min(0, { message: "Good must be greater than or equal to 0" }),
  Fair: z
    .number()
    .min(0, { message: "Fair must be greater than or equal to 0" }),
  High: z
    .number()
    .min(0, { message: "High must be greater than or equal to 0" }),
  Over: z
    .number()
    .min(0, { message: "Over must be greater than or equal to 0" }),
  Date: z.string().refine((value) => dayjs(value).isValid(), {
    message: "Date must be a valid date",
  }),
  SaleVehicleId: z
    .number()
    .min(0, { message: "SaleVehicleId must be greater than or equal to 0" }),
  VehiclePrice: z
    .number()
    .min(0, { message: "VehiclePrice must be greater than or equal to 0" }),
});
