import { AxiosInstance } from "axios";
import { CreateCarGurusPricingRecordDto } from "./dto/create-car-gurus-pricing-record.dto";
import { type CarGurusPricingRecord as CarGurusPricingRecordType } from "./cargurus-pricing-record";

import { CreateCarGurusPricingRecordSchema } from "./schemas/create-cargurus-pricing-record.schema";
import { UpdateCarGurusPricingRecordSchema } from "./schemas/update-cargurus-pricing-record.schema";

export class CarGurusPricingRecord {
  private readonly instance: AxiosInstance;
  private companyId: number;

  /**
   * Creates an instance of the ClosureDates class.
   * @param {AxiosInstance} instance - The Axios instance to use for HTTP requests.
   * @param {number} companyId - The ID of the company to get closure dates for.
   */
  constructor(instance: AxiosInstance, companyId: number) {
    this.instance = instance;
    this.companyId = companyId;
  }

  /**
   * Create a new carGurusPricingRecord
   * @param {CreateCarGurusPricingRecordDto} carGurusPricingRecord - The carGurusPricingRecord to create
   * @returns {Promise<CreateCarGurusPricingRecordDto>}
   */

  public async create(carGurusPricingRecord: CreateCarGurusPricingRecordDto) {
    const response = await this.instance.post(
      `companies/${this.companyId}/car-gurus-pricing-record`,
      carGurusPricingRecord
    );
    return response.data as CarGurusPricingRecordType;
  }

  /**
   * Find all carGurusPricingRecords for a sale vehicle
   * @param {number} saleVehicleId - The sale vehicle ID
   * @returns {Promise<CreateCarGurusPricingRecordDto[]>}
   */

  public async find(saleVehicleId: number) {
    const response = await this.instance.get(
      `companies/${this.companyId}/car-gurus-pricing-record`,
      {
        params: {
          saleVehicleId,
        },
      }
    );
    return response.data as CarGurusPricingRecordType[];
  }

  /**
   * Find a carGurusPricingRecord by ID
   * @param {number} id - The ID of the carGurusPricingRecord
   * @returns {Promise<CreateCarGurusPricingRecordDto>}
   */

  public async findById(id: number) {
    const response = await this.instance.get(
      `companies/${this.companyId}/car-gurus-pricing-record/${id}`
    );
    return response.data as CarGurusPricingRecordType;
  }

  /**
   * Update a carGurusPricingRecord
   * @param {number} id - The ID of the carGurusPricingRecord
   * @param {CreateCarGurusPricingRecordDto} carGurusPricingRecord - The carGurusPricingRecord to update
   * @returns {Promise<void>}
   */

  public async update(
    id: number,
    carGurusPricingRecord: CreateCarGurusPricingRecordDto
  ) {
    await this.instance.patch(
      `companies/${this.companyId}/car-gurus-pricing-record/${id}`,
      carGurusPricingRecord
    );
  }

  /**
   * Delete a carGurusPricingRecord
   * @param {number} id - The ID of the carGurusPricingRecord
   * @returns {Promise<void>}
   */

  public async delete(id: number) {
    await this.instance.delete(
      `companies/${this.companyId}/car-gurus-pricing-record/${id}`
    );
  }

  public getSchemas() {
    return {
      create: CreateCarGurusPricingRecordSchema,
      update: UpdateCarGurusPricingRecordSchema,
    };
  }
}
