import useApi from "@/hooks/use-api.tsx";
import useLocalAppPreferences from "@/hooks/use-local-app-preferences.tsx";
import {useQuery} from "react-query";
import {InventoryOverview} from "@/lib/api/analytics/inventory-analytics";
import dayjs from "dayjs";

export default function useInventoryDashboardData() {
    const api = useApi()
    const preferences = useLocalAppPreferences()
    const companyIds = preferences.preferences.global.activeCompanyIds
    const startDate = dayjs().subtract(1, 'month').format('YYYY-MM-DD')
    const endDate = dayjs().subtract(1, 'day').format('YYYY-MM-DD')

    const getInventoryDashboardData = async (id: number) => {
        const data = await api.analytics(id).getAll(
            `
            inventoryOverview {
                companyId
                companyName
                totalInventory
                totalInvestment
                averageInvestment
                daysSupply
                averageDaysInInventory
                cargurusGroupingData {
                    rating
                    totalUnits
                    ratingPercentOfTotal
                    averageDaysToSell
                    averageGrossPerSale
                }
                inventoryByAgeData {
                    ageBucket
                    totalInventory
                    bucketPercentOfTotal
                    averageInvestment
                }
                inventoryBySourceData {
                    sourceName
                    totalInventory
                    sourcePercentOfTotal
                    averageInvestment
                    averageDaysInStock
                    volumeIndicator
                    investmentIndicator
                    daysInStockIndicator
                }
            }
           `, {
                startDate: startDate,
                endDate: endDate,
            }
        )
        return data.inventoryOverview
    }

    const { data, isLoading: loading } = useQuery({
        queryKey: ['inventory-dashboard-data', companyIds],
        queryFn: async () => {
            if (companyIds.length === 0) return [] as InventoryOverview[]
            const data: Promise<InventoryOverview | null | undefined>[] = []
            for(const id of companyIds){
                data.push(getInventoryDashboardData(id))
            }
            const items: (InventoryOverview | null | undefined)[] = await Promise.all(data)
            return items.filter(item => item !== null && item !== undefined) as (InventoryOverview)[]
        },
        enabled: !!companyIds.length || companyIds.length > 0
    })

    return {
        data,
        loading
    }
}